import { customElement, html, TemplateResult, cache } from '@adobe/edex/ui/edex-lit';

import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { $t, getCurrentLocale } from '@adobe/edex/ui/shared/config/i18n';
import { EdexOneTrust } from '@adobe/edex/ui/shared/config/onetrust';
import { EdexTagName, Locale } from '@adobe/edex/ui/shared/constants';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';

import '@adobe/edex/ui/shared/components/languageSelector/languageSelector';

import(
  /* webpackChunkName: "component/footer-element-styles" */
  /* webpackPreload: true */
  /* webpackMode: "lazy" */
  './footer.scss'
);

@customElement(EdexTagName.footer)
export class Footer extends BaseElement {
  get useLightDom() {
    return true;
  }

  static get properties() {
    return {
      hidden: { type: Boolean },
    };
  }

  hidden = false;

  get showFooter(): boolean {
    return !this.hidden;
  }

  connectedCallback() {
    super.connectedCallback();
    EdexOneTrust.bootstrap(); // init after component is connected to prevent race condition: onetrust depends on footerLinkSelector
  }

  renderFooterLink(key: string): TemplateResult {
    return html`
      <sp-link quiet href="${$t(`i18n.footer.links.${key}`)}" rel="external" target="_top">
        ${$t(`i18n.footer.${key}`)}
      </sp-link>
    `;
  }

  get hasSitemap(): boolean {
    return !!{
      [Locale.en]: true,
    }[getCurrentLocale()];
  }

  render(): TemplateResult {
    const { showFooter, hasSitemap } = this;
    return html`
      ${cache(
        showFooter
          ? html`
              <footer>
                <nav class="footer-top" aria-label="${/* TODO: $t(I18nKey...) */ 'additional resources'}">
                  <edex-language-selector>
                    <span slot="label">${$t(I18nKey.changeRegion)}</span>
                  </edex-language-selector>
                  ${this.renderFooterLink('Terms_of_Use')} ${this.renderFooterLink('Code_of_Conduct')}
                  ${this.renderFooterLink('Help_Center')} ${this.renderFooterLink('adobe_dot_com')}
                </nav>
                <div class="footer-bottom">
                  <div class="footer-copyright">
                    <p>${$t(I18nKey.copyright)}</p>
                  </div>
                  <nav aria-label="${/* TODO: $t(I18nKey...) */ 'site policies'}">
                    ${this.renderFooterLink('Privacy')}
                    <sp-link quiet href="javascript:void(0)" class="onetrust-notice-link"></sp-link>
                    ${hasSitemap ? this.renderFooterLink('Sitemap') : null}
                  </nav>
                </div>
              </footer>
            `
          : ''
      )}
    `;
  }
}
