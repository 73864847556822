import { html } from '@adobe/edex/ui/edex-lit';

export const edexLogo = html`
  <?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 24.1.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg xmlns="http://www.w3.org/2000/svg" width="252" height="25.541" viewBox="0 0 283.001 25.541">
    <g id="Layer_1_2_" transform="translate(-2.3 -16.3)">
      <g id="Group_23" data-name="Group 23" transform="translate(2.3 16.3)">
        <g id="Group_21" data-name="Group 21">
          <g id="Group_18" data-name="Group 18" transform="translate(18.23)">
            <path
              id="Path_82630"
              data-name="Path 82630"
              d="M29.8,16.3H40.539V41.841Z"
              transform="translate(-29.8 -16.3)"
              fill="#fa0f00"
            />
          </g>
          <g id="Group_19" data-name="Group 19">
            <path
              id="Path_82631"
              data-name="Path 82631"
              d="M13.039,16.3H2.3V41.841Z"
              transform="translate(-2.3 -16.3)"
              fill="#fa0f00"
            />
          </g>
          <g id="Group_20" data-name="Group 20" transform="translate(9.811 9.365)">
            <path
              id="Path_82632"
              data-name="Path 82632"
              d="M21.74,30.5l6.894,16.175H24.127l-2.055-5.1H17.1Z"
              transform="translate(-17.1 -30.5)"
              fill="#fa0f00"
            />
          </g>
        </g>
        <g id="Group_22" data-name="Group 22" transform="translate(38.449 4.791)">
          <path
            id="Path_82633"
            data-name="Path 82633"
            d="M64.41,36.5l-1.061,3.248c-.066.133-.133.2-.265.2H60.5a.176.176,0,0,1-.2-.2l4.442-12.794a3.841,3.841,0,0,0,.2-1.326.143.143,0,0,1,.133-.133h3.513c.133,0,.133,0,.2.133l5.1,14.12c.066.133,0,.2-.133.2H70.84a.344.344,0,0,1-.265-.133L69.448,36.5Zm4.309-2.784c-.464-1.458-1.326-4.11-1.79-5.7h0c-.331,1.458-1.127,3.911-1.724,5.7Z"
            transform="translate(-60.3 -24.24)"
            fill="#fa0f00"
          />
          <path
            id="Path_82634"
            data-name="Path 82634"
            d="M80.7,33.975c0-3.182,2.387-5.834,6.43-5.834a3.253,3.253,0,0,1,.729.066V23.833c0-.133.066-.133.133-.133h2.784c.133,0,.133.066.133.133v13.06a13.312,13.312,0,0,0,.066,1.657c0,.133,0,.133-.133.2a10.707,10.707,0,0,1-4.375.862C83.219,39.61,80.7,37.688,80.7,33.975Zm7.16-3.182a2.849,2.849,0,0,0-.8-.133,2.972,2.972,0,0,0-3.182,3.116c0,2.188,1.26,3.116,2.917,3.116a2.748,2.748,0,0,0,1.061-.133Z"
            transform="translate(-67.176 -23.634)"
            fill="#fa0f00"
          />
          <path
            id="Path_82635"
            data-name="Path 82635"
            d="M109.072,36.134c0,3.447-2.254,5.767-5.436,5.767-3.779,0-5.436-2.851-5.436-5.7a5.414,5.414,0,0,1,5.5-5.7C107.282,30.433,109.072,33.085,109.072,36.134Zm-7.756,0c0,1.922.928,3.182,2.387,3.182,1.26,0,2.254-1.127,2.254-3.116,0-1.724-.729-3.182-2.387-3.182C102.31,33.018,101.316,34.145,101.316,36.134Z"
            transform="translate(-73.075 -25.925)"
            fill="#fa0f00"
          />
          <path
            id="Path_82636"
            data-name="Path 82636"
            d="M119.651,23.6c.2,0,.2,0,.2.2v4.508a8.357,8.357,0,0,1,1.856-.265,5.081,5.081,0,0,1,5.3,5.3c0,4.11-3.248,6.165-6.629,6.165a10.132,10.132,0,0,1-3.447-.53c-.066,0-.133-.133-.133-.2V23.8c0-.133.066-.133.133-.133h2.718Zm1.525,7.093a4.614,4.614,0,0,0-1.326.2v6.033a3.5,3.5,0,0,0,.8.066,2.994,2.994,0,0,0,3.182-3.315C123.893,31.621,122.7,30.693,121.175,30.693Z"
            transform="translate(-79.345 -23.6)"
            fill="#fa0f00"
          />
          <path
            id="Path_82637"
            data-name="Path 82637"
            d="M136.616,36.93c.133,1.26.994,2.32,3.182,2.32a6.225,6.225,0,0,0,2.718-.53c.066-.066.133,0,.133.133v2.121a.345.345,0,0,1-.133.265,7.621,7.621,0,0,1-3.381.663c-4.176,0-5.635-2.851-5.635-5.569,0-3.049,1.856-5.834,5.37-5.834a4.5,4.5,0,0,1,4.64,4.773,6.278,6.278,0,0,1-.133,1.458c0,.133-.066.133-.2.2a17.01,17.01,0,0,1-2.254.066h-4.309Zm2.851-2.121a3.575,3.575,0,0,0,1.127-.066V34.61a1.807,1.807,0,0,0-1.856-1.79,2.107,2.107,0,0,0-2.121,1.922C136.549,34.809,139.466,34.809,139.466,34.809Z"
            transform="translate(-84.974 -25.926)"
            fill="#fa0f00"
          />
          <path
            id="Path_82638"
            data-name="Path 82638"
            d="M168.313,39.753c0,.2-.066.2-.2.2H159.1a.176.176,0,0,1-.2-.2V25.7a.176.176,0,0,1,.2-.2h8.751c.2,0,.2,0,.265.2l.265,2.387c0,.133,0,.2-.133.2h-6.165v2.983h5.635a.176.176,0,0,1,.2.2v2.32c0,.133-.066.133-.2.133h-5.635v3.248h6.5c.2,0,.2.066.2.2Z"
            transform="translate(-93.536 -24.24)"
            fill="#fa0f00"
          />
          <path
            id="Path_82639"
            data-name="Path 82639"
            d="M174.6,33.975c0-3.182,2.387-5.834,6.43-5.834a3.253,3.253,0,0,1,.729.066V23.833c0-.133.066-.133.133-.133h2.784c.133,0,.133.066.133.133v13.06a13.312,13.312,0,0,0,.066,1.657c0,.133,0,.133-.133.2a10.707,10.707,0,0,1-4.375.862C177.119,39.61,174.6,37.688,174.6,33.975Zm7.16-3.182a2.849,2.849,0,0,0-.8-.133,2.972,2.972,0,0,0-3.182,3.116c0,2.188,1.26,3.116,2.917,3.116a2.748,2.748,0,0,0,1.061-.133Z"
            transform="translate(-98.828 -23.634)"
            fill="#fa0f00"
          />
          <path
            id="Path_82640"
            data-name="Path 82640"
            d="M202.612,39.252a15.413,15.413,0,0,0,.133,2.254.176.176,0,0,1-.2.2h-2.32c-.2,0-.2-.066-.265-.133a2.356,2.356,0,0,1-.133-.663,4.4,4.4,0,0,1-3.049,1.061c-2.188,0-3.779-1.193-3.779-4.243V30.9a.176.176,0,0,1,.2-.2h2.718c.133,0,.133.066.133.2v6.43c0,1.193.53,1.989,1.724,1.989a2.249,2.249,0,0,0,1.724-.663V30.9a.176.176,0,0,1,.2-.2h2.718a.176.176,0,0,1,.2.2v8.353Z"
            transform="translate(-105.03 -25.993)"
            fill="#fa0f00"
          />
          <path
            id="Path_82641"
            data-name="Path 82641"
            d="M218.019,41.272c0,.133,0,.133-.133.2a7.567,7.567,0,0,1-2.453.331,5.448,5.448,0,0,1-5.834-5.635c0-3.315,2.519-5.767,6.3-5.767a6,6,0,0,1,2.055.265c.133.066.133.133.133.265v2.254c0,.133-.066.2-.2.133a6.146,6.146,0,0,0-1.856-.265c-1.856,0-3.182.994-3.182,2.983,0,2.32,1.724,3.049,3.248,3.049a6.546,6.546,0,0,0,1.856-.2c.133-.066.2,0,.2.066l-.133,2.32Z"
            transform="translate(-110.626 -25.892)"
            fill="#fa0f00"
          />
          <path
            id="Path_82642"
            data-name="Path 82642"
            d="M232.681,39.184a12.265,12.265,0,0,0,.066,1.591c0,.066,0,.066-.066.133a12.622,12.622,0,0,1-4.839.994c-2.453,0-4.442-1.127-4.442-3.712,0-2.32,2.055-3.58,5.038-3.58a6.5,6.5,0,0,1,1.326.066v-.133c0-.4-.265-1.392-2.121-1.392a6.742,6.742,0,0,0-2.917.6c-.066.066-.2,0-.2-.133V31.362c0-.133,0-.2.133-.2a8.416,8.416,0,0,1,3.447-.663c3.182,0,4.574,1.989,4.574,4.508v4.176ZM229.7,36.93a5.715,5.715,0,0,0-1.127-.066c-1.591,0-2.188.53-2.188,1.326,0,.729.6,1.326,1.922,1.326a4.711,4.711,0,0,0,1.392-.2Z"
            transform="translate(-115.278 -25.926)"
            fill="#fa0f00"
          />
          <path
            id="Path_82643"
            data-name="Path 82643"
            d="M245.1,39.655c0,.133,0,.265-.133.265a5.942,5.942,0,0,1-1.989.331c-1.922,0-3.049-.928-3.049-3.182V31.634H238.6a.176.176,0,0,1-.2-.2V29.247a.176.176,0,0,1,.2-.2h1.326c0-.729.133-2.055.2-2.718a.176.176,0,0,1,.2-.2l2.585-.331c.133,0,.2,0,.2.133-.066.663-.133,1.989-.133,3.049h2.055a.176.176,0,0,1,.2.2v2.254c0,.133-.066.133-.133.133H243.04v4.707c0,.994.265,1.392,1.193,1.392.265,0,.464-.066.729-.066.066,0,.133,0,.133.133v1.922Z"
            transform="translate(-120.334 -24.342)"
            fill="#fa0f00"
          />
          <path
            id="Path_82644"
            data-name="Path 82644"
            d="M250.4,25.524a1.672,1.672,0,0,1,1.724-1.724,1.635,1.635,0,0,1,1.724,1.724,1.6,1.6,0,0,1-1.724,1.724A1.635,1.635,0,0,1,250.4,25.524Zm.133,3.049a.176.176,0,0,1,.2-.2h2.718a.176.176,0,0,1,.2.2V39.18c0,.133,0,.2-.2.2H250.8a.176.176,0,0,1-.2-.2l-.066-10.607Z"
            transform="translate(-124.379 -23.667)"
            fill="#fa0f00"
          />
          <path
            id="Path_82645"
            data-name="Path 82645"
            d="M268.172,36.134c0,3.447-2.254,5.767-5.436,5.767-3.779,0-5.436-2.851-5.436-5.7a5.414,5.414,0,0,1,5.5-5.7C266.316,30.433,268.172,33.085,268.172,36.134Zm-7.822,0c0,1.922.928,3.182,2.386,3.182,1.26,0,2.254-1.127,2.254-3.116,0-1.724-.729-3.182-2.387-3.182C261.344,33.018,260.349,34.145,260.349,36.134Z"
            transform="translate(-126.705 -25.925)"
            fill="#fa0f00"
          />
          <path
            id="Path_82646"
            data-name="Path 82646"
            d="M275.766,33.184c0-.729-.066-1.922-.066-2.387,0-.133,0-.2.133-.2h2.32c.2,0,.265.066.265.2a5.373,5.373,0,0,1,.2.729,5,5,0,0,1,3.116-1.127c1.724,0,3.712.862,3.712,4.442V41.4a.176.176,0,0,1-.2.2h-2.652a.176.176,0,0,1-.2-.2v-6.3c0-1.326-.6-2.055-1.724-2.055a2.874,2.874,0,0,0-1.856.663V41.4a.176.176,0,0,1-.2.2H275.9a.176.176,0,0,1-.2-.2l.066-8.22Z"
            transform="translate(-132.907 -25.892)"
            fill="#fa0f00"
          />
          <path
            id="Path_82647"
            data-name="Path 82647"
            d="M311.18,39.753c0,.2-.066.2-.2.2H301.9a.176.176,0,0,1-.2-.2V25.7a.176.176,0,0,1,.2-.2h8.751c.2,0,.2,0,.265.2l.265,2.387c0,.133,0,.2-.133.2h-6.165v2.983h5.635a.176.176,0,0,1,.2.2v2.32c0,.133-.066.133-.2.133h-5.635v3.248h6.5c.2,0,.2.066.2.2Z"
            transform="translate(-141.671 -24.24)"
            fill="#fa0f00"
          />
          <path
            id="Path_82648"
            data-name="Path 82648"
            d="M316.919,31c-.066-.066-.066-.2.133-.2H320.1c.2,0,.2.066.265.2l1.856,3.646h.066l1.989-3.712c.066-.133.066-.133.2-.133h2.718c.133,0,.2.066.133.2-.729,1.193-2.453,4.044-3.248,5.237.994,1.591,2.784,4.243,3.514,5.37.066.133,0,.2-.133.2h-3.182c-.133,0-.265-.066-.265-.2l-1.989-3.712h0l-2.055,3.779a.345.345,0,0,1-.265.133h-2.784c-.133,0-.2-.133-.133-.2.862-1.326,2.387-3.845,3.315-5.237Z"
            transform="translate(-146.748 -26.027)"
            fill="#fa0f00"
          />
          <path
            id="Path_82649"
            data-name="Path 82649"
            d="M341.119,41.272c0,.133,0,.133-.133.2a7.567,7.567,0,0,1-2.453.331,5.448,5.448,0,0,1-5.834-5.635c0-3.315,2.519-5.767,6.3-5.767a6,6,0,0,1,2.055.265c.133.066.133.133.133.265v2.254c0,.133-.066.2-.2.133a6.146,6.146,0,0,0-1.856-.265c-1.856,0-3.182.994-3.182,2.983,0,2.32,1.724,3.049,3.248,3.049a6.547,6.547,0,0,0,1.856-.2c.133-.066.2,0,.2.066l-.133,2.32Z"
            transform="translate(-152.12 -25.892)"
            fill="#fa0f00"
          />
          <path
            id="Path_82650"
            data-name="Path 82650"
            d="M350.716,39.212a.176.176,0,0,1-.2.2H347.8a.176.176,0,0,1-.2-.2V23.833c0-.066,0-.133.2-.133h2.718c.133,0,.2.066.2.133V29.07a4.933,4.933,0,0,1,2.917-.928c1.724,0,3.779.862,3.779,4.442v6.563a.176.176,0,0,1-.2.2h-2.652a.176.176,0,0,1-.2-.2v-6.3c0-1.392-.6-2.055-1.79-2.055a2.908,2.908,0,0,0-1.79.6l-.066,7.822Z"
            transform="translate(-157.143 -23.634)"
            fill="#fa0f00"
          />
          <path
            id="Path_82651"
            data-name="Path 82651"
            d="M373.581,39.184a12.254,12.254,0,0,0,.066,1.591c0,.066,0,.066-.066.133a12.622,12.622,0,0,1-4.839.994c-2.453,0-4.442-1.127-4.442-3.712,0-2.32,2.055-3.58,5.038-3.58a6.5,6.5,0,0,1,1.326.066v-.133c0-.4-.265-1.392-2.121-1.392a6.742,6.742,0,0,0-2.917.6c-.066.066-.2,0-.2-.133V31.362c0-.133,0-.2.133-.2a8.416,8.416,0,0,1,3.447-.663c3.182,0,4.574,1.989,4.574,4.508v4.176ZM370.6,36.93a5.716,5.716,0,0,0-1.127-.066c-1.591,0-2.188.53-2.188,1.326,0,.729.6,1.326,1.922,1.326a4.712,4.712,0,0,0,1.392-.2V36.93Z"
            transform="translate(-162.772 -25.926)"
            fill="#fa0f00"
          />
          <path
            id="Path_82652"
            data-name="Path 82652"
            d="M381.166,33.184c0-.729-.066-1.922-.066-2.387,0-.133,0-.2.133-.2h2.32c.2,0,.265.066.265.2a5.378,5.378,0,0,1,.2.729,5,5,0,0,1,3.116-1.127c1.724,0,3.712.862,3.712,4.442V41.4a.176.176,0,0,1-.2.2h-2.652a.176.176,0,0,1-.2-.2v-6.3c0-1.326-.6-2.055-1.724-2.055a2.874,2.874,0,0,0-1.856.663V41.4a.176.176,0,0,1-.2.2H381.3a.176.176,0,0,1-.2-.2l.066-8.22Z"
            transform="translate(-168.435 -25.892)"
            fill="#fa0f00"
          />
          <path
            id="Path_82653"
            data-name="Path 82653"
            d="M404.128,38.984c3.182.133,4.309,1.458,4.309,3.182,0,2.32-2.254,4.176-5.834,4.176-3.712,0-5.3-1.856-5.3-3.447,0-.729.6-1.591,2.055-2.851a3.035,3.035,0,0,1-.066-3.116,3.133,3.133,0,0,1-1.525-2.851c0-2.121,1.657-3.779,4.508-3.779a9.607,9.607,0,0,1,2.121.2,1.648,1.648,0,0,0,.53.066h3.049c.133,0,.133.066.133.133v2.055c0,.133-.066.133-.133.133a14.255,14.255,0,0,1-1.525-.133,2.786,2.786,0,0,1,.331,1.458c0,2.32-1.922,3.513-4.375,3.513a2.486,2.486,0,0,1-.729-.066,1.832,1.832,0,0,0-.066.6,1.192,1.192,0,0,0,.265.6ZM401.012,41.5a2.149,2.149,0,0,0-.862,1.193c0,.663.994,1.458,2.718,1.458,1.989,0,2.851-.663,2.851-1.392,0-.8-.994-1.061-2.188-1.127Zm-.265-7.425a1.48,1.48,0,0,0,1.591,1.525,1.347,1.347,0,0,0,1.525-1.458,1.413,1.413,0,0,0-1.525-1.591A1.446,1.446,0,0,0,400.747,34.079Z"
            transform="translate(-173.896 -25.858)"
            fill="#fa0f00"
          />
          <path
            id="Path_82654"
            data-name="Path 82654"
            d="M417.216,36.93c.133,1.26.994,2.32,3.182,2.32a6.225,6.225,0,0,0,2.718-.53c.066-.066.133,0,.133.133v2.121a.344.344,0,0,1-.133.265,7.621,7.621,0,0,1-3.381.663c-4.176,0-5.635-2.851-5.635-5.569,0-3.049,1.856-5.834,5.37-5.834a4.5,4.5,0,0,1,4.641,4.773,6.278,6.278,0,0,1-.133,1.458c0,.133-.066.133-.2.2a17.01,17.01,0,0,1-2.254.066h-4.309Zm2.917-2.121a3.575,3.575,0,0,0,1.127-.066V34.61a1.807,1.807,0,0,0-1.856-1.79,2.107,2.107,0,0,0-2.121,1.922C417.216,34.809,420.133,34.809,420.133,34.809Z"
            transform="translate(-179.559 -25.926)"
            fill="#fa0f00"
          />
        </g>
      </g>
    </g>
  </svg>
`;

export const adobeLogo = html`
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453.75 118.11">
    <title>Adobe</title>
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }
      </style>
    </defs>
    <path
      class="cls-1"
      d="M202,85.26l-4.89,15.08a1.1,1.1,0,0,1-1.12.82H184.12c-.71,0-.92-.41-.81-1L203.7,41.31a18.89,18.89,0,0,0,1-6.22.68.68,0,0,1,.61-.71h16.31c.51,0,.71.1.82.61l23.14,65.25c.2.51.1.92-.51.92H231.84a1,1,0,0,1-1.13-.71l-5.2-15.19Zm19.78-12.75c-2-6.73-6.12-19.06-8.05-26.3h-.1c-1.64,6.83-5.31,18-8,26.3Z"
      transform="translate(-6.07 -6.51)"
    />
    <path
      class="cls-1"
      d="M247.21,76.28c0-14.58,10.91-26.81,29.57-26.81.81,0,1.83.1,3.36.2V29.59a.64.64,0,0,1,.71-.71H293.7c.51,0,.61.2.61.61V89.74a56.68,56.68,0,0,0,.41,7.44c0,.51-.1.72-.71.92a51.21,51.21,0,0,1-20.09,4.08C258.83,102.18,247.21,93.62,247.21,76.28Zm32.93-14.47a10.53,10.53,0,0,0-3.77-.51c-7.85,0-14.58,4.79-14.58,14.27,0,10.09,5.81,14.48,13.56,14.48a14.38,14.38,0,0,0,4.79-.61Z"
      transform="translate(-6.07 -6.51)"
    />
    <path
      class="cls-1"
      d="M352.7,75.57c0,16-10.4,26.61-25.08,26.61-17.44,0-25.18-13.15-25.18-26.3,0-14.48,9.68-26.41,25.38-26.41C344.24,49.47,352.7,61.81,352.7,75.57Zm-35.89.1c0,9,4.18,14.68,11,14.68,5.92,0,10.4-5.09,10.4-14.47,0-8-3.26-14.58-11-14.58C321.3,61.3,316.81,66.6,316.81,75.67Z"
      transform="translate(-6.07 -6.51)"
    />
    <path
      class="cls-1"
      d="M374.13,28.88c.81,0,1,.1,1,.81v20.9a32.93,32.93,0,0,1,8.56-1.12c15,0,24.47,10.6,24.47,24.26,0,19.07-15.09,28.45-30.58,28.45a53.11,53.11,0,0,1-16-2.35,1.18,1.18,0,0,1-.61-1V29.59c0-.51.2-.71.71-.71Zm7,32.62a20.49,20.49,0,0,0-6,.82V90a16.11,16.11,0,0,0,3.77.4c7.75,0,14.78-4.69,14.78-15.29C393.7,65.78,388.3,61.5,381.16,61.5Z"
      transform="translate(-6.07 -6.51)"
    />
    <path
      class="cls-1"
      d="M428.11,79.55c.51,5.81,4.59,10.6,14.58,10.6a31.41,31.41,0,0,0,12.54-2.35c.31-.2.61-.1.61.51V98c0,.71-.2,1-.71,1.22-4.08,1.94-8.56,3-15.6,3-19.17,0-25.89-13.15-25.89-25.79,0-14.07,8.66-26.92,24.67-26.92,15.39,0,21.51,11.93,21.51,22a32.37,32.37,0,0,1-.51,6.83.88.88,0,0,1-.82.82,95.25,95.25,0,0,1-10.5.41Zm13.26-9.89a32.77,32.77,0,0,0,5.09-.21v-.71a8.29,8.29,0,0,0-8.66-8.06c-6.42,0-9.18,4.8-9.79,9Z"
      transform="translate(-6.07 -6.51)"
    />
    <polygon class="cls-1" points="84.13 0 133.46 0 133.46 118.11 84.13 0" />
    <polygon class="cls-1" points="49.37 0 0 0 0 118.11 49.37 0" />
    <polygon class="cls-1" points="66.75 43.53 98.18 118.11 77.58 118.11 68.18 94.36 45.18 94.36 66.75 43.53" />
  </svg>
`;
