import { cloneDeep, isEmpty, isEqual, isUndefined, mapValues, omitBy, pickBy, sortBy } from 'lodash-es';
import {
  createResource,
  initResourceForm,
  resetResourceForm,
  updateResource,
  updateResourceForm,
} from '@adobe/edex/ui/store/routines';
import {
  EntityStatus,
  FileSchema,
  MarshalledResource,
  ResourceFormData,
  ResourceFormState,
  RoutineStage,
} from '@adobe/edex/ui/types';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { InitResourceFormActionPayload, UpdateResourceFormActionPayload } from '@adobe/edex/ui/types/payload';
import { MetadataState } from '@adobe/edex/ui/store/reducers/api/metadata';
import { extractPrimarySecondaryIds, getById, getByIds, marshalPrimarySecondary } from '@adobe/edex/ui/utils/marshal';
import { generateUUID } from '@adobe/edex/ui/shared/utils';
import { mergeResourceLinks } from '@adobe/edex/ui/utils/resource-links';

export const indexByID = <T extends { id?: string }>(items: T[]): Record<string, T> =>
  items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

const assignId = <T extends { id?: string }>(item: T): T => {
  item.id = item.id || generateUUID();
  return item;
};

const resource2formData = ({
  id,
  type,
  title,
  description,
  secondaryAuthor,
  shortDescription,
  academicLevels,
  products,
  subscribed,
  timing,
  heroImage,
  links,
  files,
  copyLicenses,
  tags,
  subjects,
  standards,
  status,
  lessonSteps,
}: MarshalledResource): ResourceFormData => {
  const isDraft = status !== EntityStatus.active;
  return {
    id,
    title,
    secondaryAuthor,
    description,
    shortDescription,
    subscribed,
    tags,
    type: type?.id,
    academicLevels: extractPrimarySecondaryIds(academicLevels, !isDraft),
    products: extractPrimarySecondaryIds(products, !isDraft),
    timing: isEmpty(timing) ? undefined : timing, // api returns empty timing object (TODO: fix resource timing schema???),
    heroImage: isEmpty(heroImage) ? undefined : heroImage,
    copyLicenses: copyLicenses.map(({ id }) => id),
    subjects: extractPrimarySecondaryIds(subjects, !isDraft),
    standards: {
      istenets: standards.istenets.map(({ id }) => id),
      general: standards.general.map(({ id }) => id),
      custom: standards.custom || '',
    },
    linksById: indexByID(mergeResourceLinks(links).map(assignId)),
    filesById: indexByID(files),
    lessonSteps: lessonSteps.map(({ id }) => id),
  };
};

export const formData2resource = (
  {
    id,
    type,
    title,
    secondaryAuthor,
    description,
    shortDescription,
    academicLevels,
    products,
    subscribed,
    timing,
    heroImage,
    copyLicenses,
    tags,
    subjects,
    standards,
    linksById,
    filesById,
    status,
    lessonSteps,
  }: ResourceFormData,
  metadata: MetadataState['byType']
): Partial<MarshalledResource> => ({
  id,
  title,
  description,
  secondaryAuthor,
  shortDescription,
  subscribed,
  tags,
  timing,
  heroImage,
  type: getById(type, metadata.resourceTypes),
  academicLevels: marshalPrimarySecondary(academicLevels, metadata.academicLevels),
  products: marshalPrimarySecondary(products, metadata.products),
  copyLicenses: getByIds(copyLicenses, metadata.copyLicenses),
  subjects: marshalPrimarySecondary(subjects, metadata.subjects),
  standards: {
    general: getByIds([...standards.general, ...standards.istenets], metadata.contentStandards),
    istenets: [],
    custom: standards.custom,
  },
  links: {
    web: Object.values(linksById),
    video: [],
  },
  files: Object.values(filesById),
  status,
  lessonSteps: getByIds(lessonSteps, metadata.lessonSteps),
});

const resource2state = (state: ResourceFormState, { resource }: InitResourceFormActionPayload): ResourceFormState => {
  const data = resource2formData(resource);
  return {
    ...state,
    prev: cloneDeep(data),
    data,
  };
};

function getResourcePayload(
  prev: ResourceFormState['prev'],
  data: ResourceFormState['data']
): ResourceFormState['resourcePayload'] {
  const changed: Partial<ResourceFormState['data']> = pickBy(data, (value, key) => !isEqual(value, prev[key]));
  const { tags, linksById, filesById, ...rest } = changed;
  const payload = {
    ...rest,
    tags: tags?.map(({ id }) => id),
    links: linksById && {
      web: sortBy(Object.values(linksById), 'sortOrder'),
      video: [],
    },
    filesById: getFilesPayload(prev, data),
  };
  return omitBy(payload, isUndefined);
}

const FILE_PAYLOAD_PROPS = ['label', 'sortOrder', 'components'] as const;
type FilePayload = Partial<Pick<FileSchema, typeof FILE_PAYLOAD_PROPS[number]>>;

const getFilePayload = (prev: FileSchema, data: FileSchema): FilePayload => {
  const payload = pickBy(data, (value, key) => FILE_PAYLOAD_PROPS.includes(key as any) && !isEqual(prev?.[key], value));
  return isEmpty(payload) ? null : payload;
};

function getFilesPayload(
  prev: ResourceFormState['prev'],
  data: ResourceFormState['data']
): ResourceFormState['resourcePayload']['filesById'] {
  if (!data.filesById) {
    return undefined;
  }
  const payload = omitBy(
    mapValues(data.filesById, (data, id) => getFilePayload(prev.filesById[id], data)),
    isEmpty
  );
  return isEmpty(payload) ? undefined : payload;
}

const formPayload2state = (state: ResourceFormState, payload: UpdateResourceFormActionPayload): ResourceFormState => {
  const data = {
    ...state.data,
    ...payload.data,
  };
  return {
    ...state,
    ...payload,
    data,
    resourcePayload: getResourcePayload(state.prev, data),
    savingStage: null,
  };
};

export const DEFAULT_RESOURCE_FORM_DATA: ResourceFormData = Object.freeze({
  subscribed: true,
  academicLevels: {
    primary: null,
    secondary: [],
  },
  products: {
    primary: null,
    secondary: [],
  },
  subjects: {
    primary: null,
    secondary: [],
  },
  standards: {
    custom: '',
    general: [],
    istenets: [],
  },
  copyLicenses: [],
  linksById: {},
  filesById: {},
  lessonSteps: [],
});

const DEFAULT_RESOURCE_FORM_STATE: ResourceFormState = Object.freeze({
  prev: DEFAULT_RESOURCE_FORM_DATA,
  data: DEFAULT_RESOURCE_FORM_DATA,
  resourcePayload: {},
  filesPayload: {},
  savingStage: null,
  guideFocus: null,
  showSectionErrors: false,
});

export const DEFAULT_RESOURCE_SECONDARY_AUTHOR = Object.freeze({
  name: '',
  profileURL: '',
});

export function resource(state = DEFAULT_RESOURCE_FORM_STATE, action: EdexAction<any>): ResourceFormState {
  switch (action.type) {
    case createResource.TRIGGER:
    case updateResource.TRIGGER:
      return {
        ...state,
        prev: cloneDeep(state.data),
        resourcePayload: {},
        savingStage: RoutineStage.TRIGGER,
      };
    case createResource.FAILURE:
    case updateResource.FAILURE:
      return {
        ...state,
        savingStage: RoutineStage.FAILURE,
      };
    case createResource.SUCCESS:
    case updateResource.SUCCESS:
      return {
        ...state,
        savingStage: RoutineStage.SUCCESS,
      };
    case initResourceForm.SUCCESS:
      return resource2state(state, action.payload);
    case updateResourceForm.SUCCESS:
      return formPayload2state(state, action.payload);
    case resetResourceForm.SUCCESS:
      return DEFAULT_RESOURCE_FORM_STATE;
    default:
      return state;
  }
}
