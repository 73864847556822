import { customElement, html } from '@adobe/edex/ui/edex-lit';
import { EdexTagName, RoutePath } from '@adobe/edex/ui/shared/constants';
import styles from '@adobe/edex/ui/components/notification/notification.lit.scss';
import { PubSub } from '@adobe/edex/ui/shared/utils/pubsub';
import { LocalEventType } from '@adobe/edex/ui/constants';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import {
  CourseCopyNotificationParams,
  CustomNotificationVariant,
  NotificationParams,
  NotificationPlacement,
} from '@adobe/edex/ui/types';
import { classMap } from '@adobe/edex/ui/edex-lit';
import { BaseElement } from '@adobe/edex/ui/components/base.element';
import { buildLink } from '@adobe/edex/ui/shared/utils/routes';
import '../playlists/playlist-saved-dialog';
import { isEmpty } from 'lodash-es';

const ANIMATION_CLS = {
  [NotificationPlacement.topRight]: 'slide-in-right',
  [NotificationPlacement.bottomCenter]: 'slide-in-bottom',
};

const NOTIFICATION_TIMEOUT_PERIOD = 5000;
@customElement(EdexTagName.notification)
export class Notification extends BaseElement {
  static styles = styles;

  static get properties() {
    return {
      params: { type: Object },
      collapse: { type: Boolean },
      openDialog: { type: Boolean },
    };
  }
  params: NotificationParams;
  collapse = true;

  connectedCallback() {
    super.connectedCallback();
    PubSub.getInstance().addEventListener(LocalEventType.notification, this.handleNotification);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    PubSub.getInstance().removeEventListener(LocalEventType.notification, this.handleNotification);
  }

  handleNotification = ({ detail: params }: CustomEvent<NotificationParams>) => {
    this.params = null;
    this.collapse = true;
    setTimeout(() => (this.params = params));
  };

  expandCollapse() {
    this.collapse = false;
  }

  renderCourseNames(courses: CourseCopyNotificationParams[]) {
    return html`
      ${courses.map((course, index) => {
        const showComma = courses.length - 1 !== index;
        return this.renderCourseUrlWithComma(course, showComma);
      })}
    `;
  }

  renderPlaylistNames(playlistNames: string[]) {
    if (playlistNames.length === 1) {
      return this.renderPlaylistLink(playlistNames[0]);
    } else if (playlistNames.length === 2) {
      return html`
        ${this.renderPlaylistLink(playlistNames[0])} and&nbsp; ${this.renderPlaylistLink(playlistNames[1])}
      `;
    } else if (playlistNames.length > 0) {
      if (this.collapse) {
        return html`
          ${this.renderPlaylistLink(playlistNames[0])} and
          <sp-link class="collapse-btn" @click=${this.expandCollapse}>${playlistNames.length - 1} more</sp-link>
        `;
      } else {
        return html`
          ${playlistNames.map((name, index) => {
            const showComma = playlistNames.length - 1 !== index;
            return this.renderPlaylistUrlWithComma(name, showComma);
          })}
        `;
      }
    }
    return '';
  }

  renderCourseUrlWithComma(course: CourseCopyNotificationParams, showComma: boolean) {
    return html`
      ${this.renderCourseLink(course)}${this.renderComma(showComma)}
    `;
  }

  renderPlaylistUrlWithComma(playlistName: string, showComma: boolean) {
    return html`
      ${this.renderPlaylistLink(playlistName)}${this.renderComma(showComma)}
    `;
  }

  renderCourseLink(course: CourseCopyNotificationParams) {
    const redirectUrl = buildLink(`${RoutePath.professionalLearning}/${course.type?.vanityURL}/${course.vanityURL}`);
    return html`
      <sp-link href=${redirectUrl} target="_blank" variant="secondary">${course.name.slice(0, 20)}...</sp-link>
    `;
  }

  renderComma(showComma: boolean) {
    return showComma
      ? html`
          ,&nbsp;
        `
      : '';
  }

  renderPlaylistLink(playlistName: string) {
    if (playlistName === 'My Saved') {
      return html`
        <sp-link href=${buildLink(`${RoutePath.teaching}/my-saved`)} variant="secondary">${playlistName}</sp-link>
      `;
    } else {
      const [playlist, vanityURL] = JSON.parse(playlistName);
      const redirectUrl = `${RoutePath.playlists}/${vanityURL}`;
      return html`
        <sp-link href=${redirectUrl} variant="secondary">${playlist}</sp-link>
      `;
    }
  }

  render() {
    const { params } = this;
    if (!params) {
      return '';
    }
    const { message, variant, placement, custom, playlistNames, courses } = params;
    return html`
      <sp-toast
        open
        .timeout=${NOTIFICATION_TIMEOUT_PERIOD}
        variant="${variant}"
        class="${classMap({
          [placement]: true,
          [ANIMATION_CLS[placement]]: true,
          'bookmark-toast': custom === CustomNotificationVariant.bookmark,
        })}"
      >
        <div class="toast-body">
          ${this.renderMessage(message)} ${this.renderCustom(custom, playlistNames, courses)}
        </div>
      </sp-toast>
    `;
  }

  renderMessage(message) {
    return html`
      <div>${$t(message)}</div>
    `;
  }

  renderCustom(custom, playlistNames, courses) {
    if (custom === CustomNotificationVariant.bookmark) {
      if (!isEmpty(courses)) {
        return html`
          <div class="toast-message-body">
            <div class="playlist-labels">${this.renderCourseNames(courses)}</div>
          </div>
        `;
      }
    }
    return custom === CustomNotificationVariant.bookmark
      ? html`
          <div class="toast-message-body">
            <div class="playlist-labels">${this.renderPlaylistNames(playlistNames)}</div>
            <sp-link class="manage-button" href="${buildLink('/dashboard/playlists')}">Manage</sp-link>
          </div>
        `
      : null;
  }
}
