import { MarshalledComment } from '@adobe/edex/ui/types';
import { CommentsState } from '@adobe/edex/ui/store/reducers/api/comments';

const COMMENTS_MIN_NESTING_LEVEL = 1;
const COMMENTS_MAX_NESTING_LEVEL = 5;

export const insertComment = (data: MarshalledComment[], comment: MarshalledComment) => {
  const copy = [...data];
  const parentIndex = data.findIndex((c) => c.id === comment.parentCommentID);
  const parentComment = data[parentIndex];
  comment.nestingLevel = Math.min(
    parentComment ? parentComment.nestingLevel + 1 : COMMENTS_MIN_NESTING_LEVEL,
    COMMENTS_MAX_NESTING_LEVEL
  );
  copy.splice(parentComment ? parentIndex + 1 : 0, 0, comment);
  return copy;
};

export const handleReport = (data: MarshalledComment[], id: string) => {
  return data.map((c) => (c.id === id ? { ...c, flagged: !c.flagged } : c));
};

export const replaceComment = (data: MarshalledComment[], comment: MarshalledComment) => {
  return data.map((c) => (c.id === comment.id ? { ...c, ...comment } : c));
};

export const removeComment = (state: CommentsState, id: string): CommentsState => {
  const comment = state.data.find((c) => c.id === id);
  let removeStrike = false;
  const filtered = state.data.filter((c) => {
    if (removeStrike && c.nestingLevel <= comment.nestingLevel) {
      removeStrike = false;
    } else if (c.id === comment.id || removeStrike) {
      removeStrike = true;
      return false;
    }
    return true;
  });
  return {
    ...state,
    data: filtered,
    meta: {
      ...state.meta,
      totalRecords: state.meta.totalRecords - (state.data.length - filtered.length),
    },
  };
};
