import * as FullStory from '@fullstory/browser';
import { NX_FULLSTORY_ORGID } from '@env/environment';

import { FullStoryIdentifyPayload } from './fullstory.types';
import { sha1 } from 'object-hash';
import { MemberProfile } from '@adobe/edex/ui/types';

export const FullStoryOptions: FullStory.SnippetOptions = {
  orgId: NX_FULLSTORY_ORGID, // Default to Sandbox ID if non prod
  debug: false,
  namespace: 'FS',
  recordCrossDomainIFrames: false,
  recordOnlyThisIFrame: false,
  devMode: false,
};

/**
 *  The Vue app loads before we get the user authenticated. FS.identify() requires a user payload to be sent, but
 *  the FS script can actually be added ahead of time, and the identify call can be invoked anywhere.
 */
export class FullStoryUtils {
  private SHORT_ID_SIZE = 8;
  private consent = false;
  private fsUserID: string;
  private userID: string;

  private static instance: FullStoryUtils;
  private fsUserVarsPayload: Partial<FullStoryIdentifyPayload> = {
    // placeholder for all static initialization. Add any static properties to be sent to FS setUserVar() here.
    profileAdobeId_str: 'N/A',
  };
  private fsIdentifyPayload: Partial<FullStoryIdentifyPayload> = {
    // placeholder for all static initialization. Add any static properties to be sent to FS identify() here.
  };
  static getInstance(): FullStoryUtils {
    if (!FullStoryUtils.instance) {
      FullStoryUtils.instance = new FullStoryUtils();
    }
    return FullStoryUtils.instance;
  }

  public consentGiven() {
    this.consent = true;
  }

  public identifyUser(member: MemberProfile) {
    // if already initialized, return
    // member.id === this.userID because user may log out and log in with different account
    try {
      if (!member || (this.fsUserID && member.id === this.userID)) {
        return;
      }

      this.userID = member.id;
      this.fsUserID = sha1(member.id);
      const encodedUserID = btoa(this.fsUserID);
      this.fsIdentifyPayload = {
        ...this.fsIdentifyPayload,
        displayName: `${encodedUserID?.substring(0, this.SHORT_ID_SIZE)} ${encodedUserID?.substring(
          this.SHORT_ID_SIZE,
          2 * this.SHORT_ID_SIZE
        )}`,
        email: `${encodedUserID.substring(0, encodedUserID.length - 2)}@notavailable.com`,
      };
      this.fsUserVarsPayload = {
        ...this.fsUserVarsPayload,
        profileAdobeId_str: 'N/A',
        profileJobTitle_str: member?.jobTitle,
        addressCountry_str: member.country?.iso2Code,
        addressCity_str: member.city || 'N/A',
        addressState_str: member.region?.code || 'N/A',
        reputationPoints_int: member.reputationPoints,
        skills_str: member.expertise?.skills.join(','),
        schoolNames_str: member.experience?.map((e) => e.institution).join(','),
      };
      this.identify();
    } catch (e) {
      //to avoid breaking existing flow due to FS identify
    }
  }

  private get enabled() {
    // full story will add its object to the window[FullStoryOptions.namespace] property so checking if it exists
    return this.consent && FullStoryOptions.namespace in window;
  }

  public identify() {
    // No checks for isProd here, it just doesn't belong in this code.
    if (this.enabled) {
      FullStory.identify(this.fsUserID, this.fsIdentifyPayload);
      FullStory.setUserVars(this.fsUserVarsPayload);
    }
  }

  public event(eventName: string, eventProps: { [key: string]: any }) {
    try {
      if (this.enabled) {
        FullStory.event(eventName, {
          ...eventProps,
        });
      }
    } catch (err) {
      //to avoid breaking existing flow due to FS event
    }
  }
}

/*

Check userSessionTracking.cfm file in legacy code for reference of implementation.

This is what's been sent in prod on each page load, keeping it here since we are turning legacy off very soon, and there
is no sensitive info in the payload:

FS.identify('93964de0-600e-466c-8f15-0e7b82f7dfa9',
{
  "products_str": "",
  "lastLogin_date": new Date("2020-07-09T04:18:04Z"),
  "profileAdobeId_str": "N/A",
  "currentSilo_str": "en",
  "addressCountry_str": "US",
  "workshopsStarted_int": 0,
  "workshopsCompleted_int": 0,
  "joinedDate_date": "new Date('2019-08-21T16:59:58Z')",
  "ratingCount_int": 0,
  "email": "93964de0-600e-466c-8f15-0e7b82f7dfa9@notavailable.com",
  "coursesCompleted_int": 0,
  "addressState_str": "N/A",
  "skills_str": "javascript,typescript,html,css,react,angular",
  "profileFirstName_str": "Javier",
  "reputationPoints_int": 5,
  "schoolSegments_str": "",
  "discussionsStarted_int": 0,
  "referredById_str": "",
  "subjects_str": "",
  "coursesEnrolled_int": 1,
  "displayName": "Javier Lerones gallego",
  "addressCity_str": "N/A",
  "resourceCount_int": 0,
  "profileCompletion_real": 0.6,
  "profileJobTitle_str": "Senior Computer Scientist, Adobe",
  "ageLevels_str": "",
  "currentLocale_str": "en_WORLD",
  "joinedToday_bool": false,
  "referred_bool": false,
  "badges_str": "Participant,Newbie,Enthusiast,Member",
  "profileViewCount_int": 7,
  "referredBy_str": "",
  "profileUrl_str": "https://edex.adobe.com/member/vcce26be5",
  "reputationStatusLevel_str": "Participant",
  "schoolNames_str": "",
  "profileLastName_str": "Lerones gallego"
}
);

*/
