import { html, customElement } from '@adobe/edex/ui/edex-lit';

import {
  ADOBE_ACCOUNT_URL,
  AdobeAnalyticsEvent,
  EdexDomID,
  EdexTagName,
  RoutePath,
} from '@adobe/edex/ui/shared/constants';
import { MemberProfile } from '@adobe/edex/ui/types';

import styles from './member-menu.lit.scss';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { EdexAuth } from '@adobe/edex/ui/shared/config/auth';
import { delay, getAvatarURL, showExternalCourseTAB } from '@adobe/edex/ui/shared/utils';
import { Analytics } from '@adobe/edex/ui/shared/config/analytics';
import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import {
  getMemberUrl,
  getMyEdexUrl,
  getHelpCentreUrl,
  getAccountSettingsUrl,
} from '@adobe/edex/ui/shared/utils/routes';

import(
  /* webpackChunkName: "component/member-menu-styles" */
  /* webpackPreload: true */
  /* webpackMode: "lazy" */
  './member-menu.scss'
);

@customElement(EdexTagName.memberMenu)
export class MemberMenu extends BaseElement {
  static styles = styles;

  static get properties() {
    return {
      member: { type: Object, attribute: false },
    };
  }
  member: MemberProfile;

  async handleClickSignOut(event: MouseEvent) {
    event.preventDefault();
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signout, 'Main nav sign out');
    await EdexAuth.signOut();
  }

  async updated(changedProperties) {
    if (changedProperties.has('member') && this.member) {
      await delay();
      const actionMenu = this.shadowRoot.querySelector('sp-action-menu');
      const popover = actionMenu.shadowRoot?.querySelector('sp-popover');
      popover?.classList.add('member-menu-popover', 'desktop-only');
    }
  }

  render() {
    if (!this.member) {
      return null;
    }
    const { isAdmin, isShellCourseAdmin, isShellCourseAssignmentReviewer } = this.member;
    return html`
      <sp-action-menu label="${this.member.fullName}" size="s" placement="bottom-end">
        <sp-avatar slot="icon" label="${this.member.fullName}" src="${getAvatarURL(this.member.avatarURL)}"></sp-avatar>
        <sp-menu-item href="${ADOBE_ACCOUNT_URL}" rel="external" target="_top">
          <div class="member-menu__details">
            <sp-avatar label="${this.member.fullName}" src="${getAvatarURL(this.member.avatarURL)}"></sp-avatar>
            <div>
              <span class="member-menu__name">${this.member.fullName}</span>
              <span class="member-menu__email">${this.member.email}</span>
              <span class="member-menu__account">${$t(I18nKey.adobeAccount)}</span>
            </div>
          </div>
        </sp-menu-item>
        <sp-menu-divider></sp-menu-divider>
        <sp-menu-item href="${getMemberUrl(this.member.vanityURL)}" rel="external" target="_top" value=${NaN}>
          ${$t(I18nKey.myProfile)}
        </sp-menu-item>
        <sp-menu-item href="${RoutePath.teaching}" rel="external" target="_top" value=${NaN}>
          ${$t(I18nKey.mainNavTRDashboard)}
        </sp-menu-item>
        <sp-menu-item href="${RoutePath.learning}" rel="external" target="_top" value=${NaN}>
          ${$t(I18nKey.mainNavPLDashboard)}
        </sp-menu-item>
        <sp-menu-item href="${RoutePath.playlists}" rel="external" target="_top" value=${NaN}>
          ${$t(I18nKey.mainNavPlaylistDashboard)}
        </sp-menu-item>
        ${showExternalCourseTAB(isAdmin, isShellCourseAdmin, isShellCourseAssignmentReviewer)
          ? html`
              <sp-menu-item href="${RoutePath.externalCourses}" rel="external" target="_top" value=${NaN}>
                ${$t(I18nKey.externalCourse)}
              </sp-menu-item>
            `
          : null}
        <sp-menu-item href="${getHelpCentreUrl()}" rel="external" target="_top" value=${NaN}>
          ${$t(I18nKey.help)}
        </sp-menu-item>
        <sp-menu-item href="${getAccountSettingsUrl()}" rel="external" target="_top" value=${NaN}>
          ${$t(I18nKey.settings)}
        </sp-menu-item>
        <sp-menu-item
          id=${EdexDomID.signOut}
          href="javascript:void(0)"
          @click=${this.handleClickSignOut}
          rel="external"
          target="_top"
          value=${NaN}
        >
          ${$t(I18nKey.signOut)}
        </sp-menu-item>
      </sp-action-menu>
    `;
  }
}
