import { BaseAuthElement } from '@adobe/edex/ui/components/base.element';
import { customElement, html } from '@adobe/edex/ui/edex-lit';
import { UpdateItemToPlaylistActionPayload } from '../../types/payload';
import { EntityType } from '@adobe/edex/ui/shared/types/schemas';
import '@adobe/edex/ui/shared/components/dialog-next/dialog';
import './playlist-saved-dialog.scss';
import { fetchPlaylistLists, updateItemToPlaylist } from '@adobe/edex/ui/store/routines/index';
import { EdexTagName } from '@adobe/edex/ui/shared/constants/index';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { selectPlaylist } from '../../store/selectors';
import { unSaveResource, saveResource, createPlaylist } from '../../store/routines/index';
import { isEmpty, pick } from 'lodash';
import { containsURL } from '../../utils';
import { errorToast } from '../../utils/notification';

@customElement(EdexTagName.playlistSaveItemDialog)
export class PlaylistSavedDialog extends BaseAuthElement {
  get useLightDom() {
    return true;
  }

  static get properties() {
    return {
      ...super.properties,
      loading: { type: Boolean },
      openDialog: { type: Boolean },
      itemID: { type: String },
      itemExistedPlaylist: { type: Array },
      itemNotExistedPlaylist: { type: Array },
      createdPlaylists: { type: Array },
      savedChecked: { type: Boolean },
      showLabel: { type: Boolean },
      title: { type: String },
    };
  }
  loading = false;
  itemNotExistedPlaylist = [];
  itemExistedPlaylist = [];
  createdPlaylists = [];
  playListsCheckSet: Set<string>;
  playListsUncheckSet: Set<string>;
  payload: UpdateItemToPlaylistActionPayload;
  itemID: string;
  savedChecked: boolean;
  toggleMySavedChecked: boolean;
  playlistNames: Set<string>;
  showLabel = true;
  title = '';

  actions = {
    fetchPlaylistLists: fetchPlaylistLists.trigger,
    updateItemToPlaylist: updateItemToPlaylist.trigger,
    unSaveResource: unSaveResource.trigger,
    saveResource: saveResource.trigger,
    createPlaylist: createPlaylist.trigger,
  };

  stateChanged(state) {
    super.stateChanged(state);
    const {
      loading,
      playlistLists,
      playlist,
      loading: { isCreating },
    } = selectPlaylist(state);
    if (!isEmpty(playlist.id) && isCreating) {
      this.createdPlaylists.push(pick(playlist, ['id', 'title', 'vanityURL']));
      this.playListsCheckSet.add(playlist.id);
      this.playlistNames.add(JSON.stringify([playlist.title, playlist.vanityURL]));
      this.requestUpdate();
    }
    this.loading = loading.all;
    this.itemExistedPlaylist = playlistLists.itemExistedPlaylist;
    this.itemNotExistedPlaylist = playlistLists.itemNotExistedPlaylist;
  }

  async connectedCallback() {
    super.connectedCallback();
    try {
      this.actions.fetchPlaylistLists({ id: this.itemID });
    } finally {
      await this.updateComplete;
    }
    this.playListsCheckSet = new Set();
    this.playListsUncheckSet = new Set();
    this.playlistNames = new Set();
    this.toggleMySavedChecked = true;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  handleExistCheck(event) {
    const playlistData = JSON.parse(event.target.dataset.id);
    if (!event.target.checked) {
      this.playListsUncheckSet.add(playlistData[0]);
      this.playlistNames.delete(JSON.stringify([event.target.innerText, playlistData[1]]));
    } else {
      this.playListsUncheckSet.delete(playlistData[0]);
      this.playlistNames.add(JSON.stringify([event.target.innerText, playlistData[1]]));
    }
  }

  handleNotExistCheck(event) {
    const playlistData = JSON.parse(event.target.dataset.id);
    if (event.target.checked) {
      this.playListsCheckSet.add(playlistData[0]);
      this.playlistNames.add(JSON.stringify([event.target.innerText, playlistData[1]]));
    } else {
      this.playListsCheckSet.delete(playlistData[0]);
      this.playlistNames.delete(JSON.stringify([event.target.innerText, playlistData[1]]));
    }
  }

  handleCloseDialog() {
    this.emitEvent('close');
  }

  handleMySavedCheck(event) {
    this.toggleMySavedChecked = event.target.checked;
  }

  isInvalidTitle(title: string) {
    return containsURL(title);
  }

  handleCreatePlaylist() {
    if (this.isInvalidTitle(this.title)) {
      errorToast({
        message: $t(I18nKey.playlisTitleNotContainURL),
      });
      return;
    }
    if (!isEmpty(this.title)) {
      this.showLabel = false;
      this.actions.createPlaylist({ data: { title: this.title, description: '' } });
      this.showLabel = true;
    }
    this.title = '';
  }

  async handleAddToPlaylist() {
    let playlistNames = [];
    if (this.savedChecked !== this.toggleMySavedChecked) {
      if (this.toggleMySavedChecked && !this.savedChecked) {
        playlistNames = ['My Saved', ...this.playlistNames];
        this.savedChecked = true;
        this.emitEvent('save', {});
      } else {
        playlistNames = [...this.playlistNames];
        this.emitEvent('unsave', {});
        this.savedChecked = false;
      }
      this.emitEvent('saveToggled');
    } else {
      playlistNames = [...this.playlistNames];
    }
    if (this.playListsCheckSet.size !== 0 || this.playListsUncheckSet.size !== 0 || playlistNames.length !== 0) {
      this.payload = {
        itemID: this.itemID,
        type: EntityType.Resource,
        toDeleteFrom: [...this.playListsUncheckSet],
        addTo: [...this.playListsCheckSet],
        playlistNames,
      };
      this.actions.updateItemToPlaylist(this.payload);
    }
    this.handleCloseDialog();
  }

  renderCreatedPlaylistCheckbox(createdPlaylist) {
    return createdPlaylist.map(
      (item) =>
        html`
          <div>
            <sp-checkbox
              data-id=${JSON.stringify([item.id, item.vanityURL])}
              checked
              emphasized
              @change=${this.handleNotExistCheck}
            >
              ${item.title}
            </sp-checkbox>
          </div>
        `
    );
  }

  renderCheckboxExists(itemExistedPlaylist) {
    return itemExistedPlaylist.map(
      (item) =>
        html`
          <div>
            <sp-checkbox
              data-id=${JSON.stringify([item.id, item.vanityURL])}
              checked
              emphasized
              @change=${this.handleExistCheck}
            >
              ${item.title}
            </sp-checkbox>
          </div>
        `
    );
  }

  renderCheckboxNotExists(itemNotExistedPlaylist) {
    return itemNotExistedPlaylist.map(
      (item) =>
        html`
          <div>
            <sp-checkbox
              data-id=${JSON.stringify([item.id, item.vanityURL])}
              emphasized
              @change=${this.handleNotExistCheck}
            >
              ${item.title}
            </sp-checkbox>
          </div>
        `
    );
  }

  renderPlaylistCheckbox(createdPlaylist, itemExistedPlaylist, itemNotExistedPlaylist) {
    return html`
      <div>
        <sp-checkbox
          data-id="mySaved"
          .checked=${this.toggleMySavedChecked}
          @change=${this.handleMySavedCheck}
          emphasized
        >
          ${$t(I18nKey.playlistAddDialogMySaved)}
        </sp-checkbox>
        ${this.renderCreatedPlaylistCheckbox(createdPlaylist)}${this.renderCheckboxExists(itemExistedPlaylist)}
        ${this.renderCheckboxNotExists(itemNotExistedPlaylist)}
      </div>
    `;
  }

  renderCreateForm() {
    const { showLabel } = this;
    return html`
      <div id="playlist-add-form">
        <div>
          <sp-field-label for="create-playlist" size="s">Create Playlist</sp-field-label>
          <sp-textfield
            id="create-playlist"
            placeholder="Playlist name"
            size="s"
            tabindex="-1"
            .value=${this.title}
            @change=${(event) => {
              this.title = event.target.value.trim();
            }}
          ></sp-textfield>
        </div>
        <div>
          <sp-button size="s" treatment="fill" variant="primary" @click=${() => this.handleCreatePlaylist()}>
            ${showLabel
              ? html`
                  ${$t(I18nKey.createPlaylist)}
                `
              : html`
                  <div>
                    <sp-progress-circle
                      label="A small representation of an unclear amount of work"
                      indeterminate
                      size="small"
                      static="white"
                    ></sp-progress-circle>
                  </div>
                `}
          </sp-button>
        </div>
      </div>
    `;
  }

  render() {
    const { itemExistedPlaylist, itemNotExistedPlaylist, loading, createdPlaylists } = this;

    return html`
      <edex-dialog-next
        .header=${$t(I18nKey.playlistAddDialogMainHeader)}
        .dismissable=${false}
        .divider=${true}
        @close=${this.handleCloseDialog}
      >
        <div slot="content">
          <p>${$t(I18nKey.playlistAddDialogDescription)}</p>
          ${this.renderCreateForm()}
          <div class="playlist-wrapper" id="playlist-wrapper">
            ${loading
              ? html`
                  <div>
                    <edex-loader />
                  </div>
                `
              : this.renderPlaylistCheckbox(createdPlaylists, itemExistedPlaylist, itemNotExistedPlaylist)}
          </div>
          <div class="save-cta" slot="footer">
            <sp-button variant="secondary" @click=${this.handleCloseDialog}>${$t(I18nKey.cancel)}</sp-button>
            <sp-button variant="cta" @click=${this.handleAddToPlaylist}>${$t(I18nKey.save)}</sp-button>
          </div>
        </div>
      </edex-dialog-next>
    `;
  }
}
