import { customElement, html } from '@adobe/edex/ui/edex-lit';
import { ifDefined } from '@adobe/edex/ui/edex-lit';
import { classMap } from '@adobe/edex/ui/edex-lit';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-rail';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-close';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-chevron-down';
import { OverlayTrigger } from '@spectrum-web-components/overlay';
import { SideNav } from '@spectrum-web-components/sidenav';

import {
  AdobeAnalyticsEvent,
  ADOBE_ACCOUNT_URL,
  EdexDomID,
  EdexTagName,
  RoutePath,
} from '@adobe/edex/ui/shared/constants';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { EdexAuth } from '@adobe/edex/ui/shared/config/auth';
import { MemberProfile } from '@adobe/edex/ui/shared/types';
import { Analytics } from '@adobe/edex/ui/shared/config/analytics';

import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { getAvatarURL, showExternalCourseTAB } from '@adobe/edex/ui/shared/utils';
import { buildLink, getMemberUrl } from '@adobe/edex/ui/shared/utils/routes';

const css = async () => {
  await import(
    /* webpackChunkName: "component/mobile-sidenav-element-styles" */
    /* webpackMode: "lazy" */
    './mobile-sidenav.scss'
  );
};

@customElement(EdexTagName.mobileSidenav)
export class MobileSidenav extends BaseElement {
  static get properties() {
    return {
      member: { attribute: false },
      open: { type: Boolean },
      loading: { type: Boolean },
      memberExpanded: { type: Boolean },
      showProfessionalLearning: { type: Boolean, attribute: false },
      locationPathname: { type: String },
    };
  }
  member: MemberProfile;
  open = false;
  loading: boolean;
  showProfessionalLearning: boolean;
  locationPathname: string;
  memberExpanded = false;

  handleClickSignin(event: MouseEvent) {
    this.handleClose();
    event.preventDefault();
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signin, 'Mobile nav sign in');
    EdexAuth.signIn();
  }

  handleClickSignOut(event: MouseEvent) {
    this.handleClose();
    event.preventDefault();
    EdexAuth.signOut();
  }

  handleClickSignUp(event: MouseEvent) {
    this.handleClose();
    event.preventDefault();
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signup, 'Mobile nav sign up');
    this.dispatchEvent(new Event('signup'));
  }

  async handleOpen() {
    await css();
    this.open = true;
    await this.updateComplete;
    this.sidenav.focus();
  }

  handleClose() {
    this.open = false;
  }

  handleToggleMemberExpanded() {
    this.memberExpanded = !this.memberExpanded;
  }

  isNavLinkActive(path: RoutePath): boolean {
    const localelessPathname = this.locationPathname?.replace(/^(\/(en|de|jp))?/, '');
    if (path === RoutePath.landing) {
      return localelessPathname === path;
    }
    return !!localelessPathname?.startsWith(path);
  }

  get overlayTrigger(): OverlayTrigger {
    return this.renderRoot.querySelector('overlay-trigger');
  }

  get sidenav(): SideNav {
    return this.renderRoot.querySelector('sp-sidenav') || document.querySelector('active-overlay sp-sidenav');
  }

  render() {
    return html`
      <sp-underlay ?open=${this.open} @click=${this.handleClose}></sp-underlay>
      <overlay-trigger
        type="modal"
        open=${ifDefined(this.open ? 'click' : undefined)}
        @sp-opened=${this.handleOpen}
        @sp-closed=${this.handleClose}
        placement="none"
      >
        <sp-action-button slot="trigger" quiet label=${'Open Navigation' /* TODO: l10n */} size="s">
          <sp-icon size="xl" slot="icon"><sp-icon-rail></sp-icon-rail></sp-icon>
        </sp-action-button>
        <sp-sidenav slot="click-content" class="mobile-only">
          ${this.open
            ? html`
                <sp-action-button
                  class="sidenav__close"
                  @click=${this.handleClose}
                  quiet
                  size="s"
                  label=${'Close Navigation' /* TODO: l10n */}
                >
                  <sp-icon slot="icon" size="s">
                    <sp-icon-close></sp-icon-close>
                  </sp-icon>
                </sp-action-button>
                <sp-sidenav-item
                  label=${$t(I18nKey.navTeachingResources)}
                  class="${classMap({
                    active:
                      this.isNavLinkActive(RoutePath.teachingResources) ||
                      this.isNavLinkActive(RoutePath.teachingResourceNav),
                  })}"
                  href="${buildLink(RoutePath.teachingResourceNav)}"
                  @click=${this.handleClose}
                  rel="external"
                  target="_top"
                ></sp-sidenav-item>
                ${this.showProfessionalLearning
                  ? html`
                      <sp-sidenav-item
                        label=${$t(I18nKey.navProfessionalLearning)}
                        href="${buildLink(RoutePath.professionalLearningNav)}"
                        class="${classMap({
                          active:
                            this.isNavLinkActive(RoutePath.professionalLearning) ||
                            this.isNavLinkActive(RoutePath.professionalLearningNav),
                        })}"
                        @click=${this.handleClose}
                        rel="external"
                        target="_top"
                      ></sp-sidenav-item>
                    `
                  : null}
                <sp-sidenav-item
                  label=${$t(I18nKey.navCommunity)}
                  class="${classMap({
                    active: this.isNavLinkActive(RoutePath.community),
                  })}"
                  rel="external"
                  target="_top"
                  href="${buildLink(RoutePath.community)}"
                  @click=${this.handleClose}
                ></sp-sidenav-item>
                ${this.loading || this.member
                  ? null
                  : html`
                      <sp-sidenav-item
                        id=${EdexDomID.signInMobile}
                        label=${$t(I18nKey.navSignIn)}
                        @click=${this.handleClickSignin}
                      ></sp-sidenav-item>
                      <sp-sidenav-item id=${EdexDomID.signUpMobile}
                      @click=${this.handleClickSignUp}> <sp-button variant="cta"> ${$t(
                      I18nKey.navSignUp
                    )} </sp-sidenav-item>
                    `}
                ${this.member
                  ? html`
                      <sp-sidenav-item
                        id=${EdexDomID.sidenavMemberMenu}
                        @click=${this.handleToggleMemberExpanded}
                        .label=${this.member.fullName}
                      >
                        ${html`
                          <style>
                            sp-avatar {
                              height: 20px;
                              width: 20px;
                              margin-right: 8px;
                            }
                            .indicator {
                              transition: transform 0.4s ease;
                              margin-left: 5px;
                            }
                            .indicator.open {
                              transform: rotate(180deg);
                            }
                          </style>
                          <sp-avatar
                            slot="icon"
                            label="${this.member.fullName}"
                            src="${getAvatarURL(this.member.avatarURL)}"
                          ></sp-avatar>
                          <sp-icon size="s" class="indicator ${this.memberExpanded ? 'open' : 'closed'}">
                            <sp-icon-chevron-down></sp-icon-chevron-down>
                          </sp-icon>
                        ` as any}
                      </sp-sidenav-item>
                      ${this.memberExpanded
                        ? html`
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.adobeAccount)}
                              href="${ADOBE_ACCOUNT_URL}"
                              rel="external"
                              target="_top"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.myProfile)}
                              rel="external"
                              target="_top"
                              href="${getMemberUrl(this.member.vanityURL)}"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.mainNavTRDashboard)}
                              href="${buildLink(RoutePath.teaching)}"
                              rel="external"
                              target="_top"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.mainNavPLDashboard)}
                              href="${buildLink(RoutePath.learning)}"
                              rel="external"
                              target="_top"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.mainNavPlaylistDashboard)}
                              href="${buildLink(RoutePath.playlists)}"
                              rel="external"
                              target="_top"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            ${showExternalCourseTAB(
                              this.member.isAdmin,
                              this.member.isShellCourseAdmin,
                              this.member.isShellCourseAssignmentReviewer
                            )
                              ? html`
                                  <sp-sidenav-item
                                    class="sidenav__member-item"
                                    label=${$t(I18nKey.externalCourse)}
                                    href="${buildLink(RoutePath.externalCourses)}"
                                    rel="external"
                                    target="_top"
                                    @click=${this.handleClose}
                                  ></sp-sidenav-item>
                                `
                              : null}
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.help)}
                              rel="external"
                              target="_top"
                              href="${buildLink('/help-center')}"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.settings)}
                              rel="external"
                              target="_top"
                              href="${buildLink('/account/settings')}"
                              @click=${this.handleClose}
                            ></sp-sidenav-item>
                            <sp-sidenav-item
                              class="sidenav__member-item"
                              label=${$t(I18nKey.signOut)}
                              id=${EdexDomID.signOut}
                              rel="external"
                              target="_top"
                              @click=${this.handleClickSignOut}
                            ></sp-sidenav-item>
                          `
                        : null}
                    `
                  : null}
              `
            : null}
        </sp-sidenav>
      </overlay-trigger>
    `;
  }
}
