import { AxiosError } from 'axios';
import {
  createResource,
  deleteResource,
  fetchRelatedResources,
  fetchResource,
  rateResource,
  reportResource,
  resetResourceForm,
  saveResource,
  unReportResource,
  unSaveResource,
  updateResource,
} from '@adobe/edex/ui/store/routines';
import { MarshalledResource, ResourceApiState } from '@adobe/edex/ui/types';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { RateResourceActionPayload } from '@adobe/edex/ui/types/payload';

const DEFAULT_RESOURCE_API_STATE: ResourceApiState = Object.freeze({
  data: null as MarshalledResource,
  loading: false,
  error: null as AxiosError,
});

export function resource(state = DEFAULT_RESOURCE_API_STATE, action: EdexAction<any>): ResourceApiState {
  switch (action.type) {
    case rateResource.TRIGGER:
      return {
        ...state,
        data: {
          ...state.data,
          myRating: (action.payload as RateResourceActionPayload).rating,
        },
      };
    case resetResourceForm.SUCCESS:
      return DEFAULT_RESOURCE_API_STATE;
    case fetchResource.TRIGGER:
      return {
        ...DEFAULT_RESOURCE_API_STATE,
        loading: true,
      };
    case fetchResource.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case fetchResource.FULFILL:
      return {
        ...state,
        loading: false,
      };
    case reportResource.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          flagged: true,
        },
      };
    case unReportResource.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          flagged: false,
        },
      };
    case saveResource.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          favorite: true,
        },
      };
    case unSaveResource.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          favorite: false,
        },
      };
    case deleteResource.SUCCESS:
      return {
        ...state,
        data: null,
      };
    case fetchRelatedResources.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          relatedResources: action.payload.data,
        },
      };
    case createResource.SUCCESS:
    case fetchResource.SUCCESS:
    case updateResource.SUCCESS:
    case rateResource.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
