import { RouterLocation } from '@vaadin/router';
import mapValues from 'lodash-es/mapValues';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { ActionCreator } from 'redux';

import { MemberProfile } from '@adobe/edex/ui/shared/types';
import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { EdexAuth } from '@adobe/edex/ui/shared/config/auth';
import { Analytics } from '@adobe/edex/ui/shared/config/analytics';
import { EdexDomID, Locale } from '@adobe/edex/ui/shared/constants';

import { ADOBE_FOR_EDUCATION_ID, POST_AUTH_LS_KEY, ViewMode } from '@adobe/edex/ui/constants';
import { store } from '@adobe/edex/ui/store';
import { selectSelfIsFulfilled, selectUser, selectViewMode } from '@adobe/edex/ui/store/selectors';
import { openDialog, trackAnalytics } from '@adobe/edex/ui/store/routines';
import { TrackAnalyticsActionPayload } from '@adobe/edex/ui/types/payload';
import { PostAuth } from '@adobe/edex/ui/types';
import { NotAuthenticated } from '@adobe/edex/ui/errors';
import { getFromLocalStorage, saveToLocalStorage } from '@adobe/edex/ui/utils/localstorage';
import { isBuilderRoute, navigate } from '@adobe/edex/ui/config/router';
import { AnalyticsPageInfo } from '@adobe/edex/ui/shared/types/analytics';
import { getCurrentLocale } from '@adobe/edex/ui/shared/config/i18n';
import { addSourceInfoToPayload, getNavSource, processSEO, resetSEO } from '@adobe/edex/ui/shared/utils';
import { EdexSEO } from '@adobe/edex/ui/shared/types/ui';
import { isEmpty } from 'lodash-es';

export { BaseElement };

export class BaseConnectedElement extends connect(store)(BaseElement) {
  actions: Record<string, ActionCreator<any>> = {};

  // Reserved by router.
  // To prevent unwanted behavior use eiter router.location explicitly OR stateChanged/property if need to track updates.
  location: never;

  connectedCallback() {
    super.connectedCallback();
    this.actions = mapValues(this.actions, (actionCreator) => (payload) => store.dispatch(actionCreator(payload)));
  }

  navigate = navigate;

  /**
   * Analytics tracking kill-switch:
   *  - disable for builder routes;
   */
  private get canTrack(): boolean {
    return !isBuilderRoute();
  }

  track(params: TrackAnalyticsActionPayload) {
    if (!this.canTrack) {
      return;
    }
    if (params.payload) {
      params.payload = addSourceInfoToPayload(params.payload, getNavSource());
    }
    store.dispatch(trackAnalytics.trigger(params));
  }
}

export class BaseAuthElement extends BaseConnectedElement {
  static get properties() {
    return {
      user: { attribute: false },
      selfIsFulfilled: { attribute: false },
      viewMode: { attribute: false },
      activeRoute: { attribute: false },
    };
  }
  user: MemberProfile; // do not access user directly, use memberProfile instead
  selfIsFulfilled = false;
  viewMode: ViewMode;
  postAuth: PostAuth = getFromLocalStorage({
    key: POST_AUTH_LS_KEY,
    parse: true,
  });

  stateChanged(state) {
    this.user = selectUser(state);
    this.selfIsFulfilled = selectSelfIsFulfilled(state);
    this.viewMode = selectViewMode(state);
  }

  get memberProfile(): MemberProfile {
    const { user, isGuest } = this;
    return isGuest ? null : user;
  }

  get isGuest() {
    const { viewMode, user } = this;
    return !viewMode ? !user : viewMode === ViewMode.guest;
  }

  isOwner = (record: { createdBy }): boolean => {
    const { viewMode, user } = this;
    if (!record) {
      return false;
    }
    return !viewMode ? user?.id === record.createdBy : viewMode === ViewMode.owner;
  };

  get isAdmin(): boolean {
    const { viewMode, user } = this;
    return !viewMode ? !!user?.isAdmin : viewMode === ViewMode.admin;
  }

  get isShellCourseAdmin(): boolean {
    const { viewMode, user } = this;
    return !viewMode ? !!user?.isShellCourseAdmin : viewMode === ViewMode.admin;
  }

  get isShellCourseAssignmentReviewer(): boolean {
    const { viewMode, user } = this;
    return !viewMode ? !!user?.isShellCourseAssignmentReviewer : viewMode === ViewMode.admin;
  }

  get isCurrentUserAdobeForEducation() {
    const { user } = this;
    return user?.id === ADOBE_FOR_EDUCATION_ID;
  }

  get hasSchoolDistrictInfo() {
    const { user } = this;
    return !isEmpty(user?.schoolDistrict);
  }

  authGuard({
    event,
    postAuth,
    showSignInDialog = true,
  }: {
    event?: Event;
    postAuth?: PostAuth;
    showSignInDialog?: boolean;
  } = {}) {
    const { viewMode, user } = this;
    const isSignedIn = !viewMode ? !!user : viewMode !== ViewMode.guest;
    if (!isSignedIn) {
      if (event) {
        event.preventDefault();
      }
      if (showSignInDialog) {
        store.dispatch(
          openDialog.trigger({
            id: EdexDomID.resourceSignInConfirmationDialog,
            payload: { postAuth },
          })
        );
      } else {
        if (postAuth) {
          this.savePostAuth(postAuth);
        }
        EdexAuth.signIn();
      }
      throw new NotAuthenticated();
    }
  }

  savePostAuth(value: PostAuth) {
    return saveToLocalStorage({
      key: POST_AUTH_LS_KEY,
      serialize: true,
      value,
    });
  }

  removePostAuth() {
    this.postAuth = null;
    return localStorage.removeItem(POST_AUTH_LS_KEY);
  }
}

export abstract class RouterPageElement extends BaseAuthElement {
  abstract get seo(): EdexSEO;
  abstract siteSection: string;

  private async trackPageView(location: RouterLocation) {
    const pageInfo: AnalyticsPageInfo = {
      pageName: location.pathname,
      siteSection: this.siteSection,
      language: RouterPageElement.getAACountryCode(),
    };
    await Analytics.getInstance().pageView(pageInfo);
  }

  // We do not get geolocation or browser country support, using the
  // default/set locale as possible values only: en-US, de-DE, ja-JP
  private static getAACountryCode(): string {
    return {
      [Locale.en]: 'en-US',
      [Locale.de]: 'de-DE',
      [Locale.jp]: 'ja-JP',
    }[getCurrentLocale()];
  }

  /**
   * Router lifecycle callback
   *
   * https://vaadin.github.io/vaadin-router/vaadin-router/demo/#vaadin-router-lifecycle-callbacks-demos
   */
  onAfterEnter(location: RouterLocation) {
    this.trackPageView(location).catch(console.error);
    processSEO(this.seo);
  }

  onBeforeLeave() {
    resetSEO(this.seo);
  }
}
