export class PubSub {
  private static instance: HTMLElement = null;

  static getInstance() {
    if (!PubSub.instance) {
      PubSub.instance = document.createElement('div');
    }

    return PubSub.instance;
  }
}
