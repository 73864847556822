import { delay } from '@adobe/edex/ui/utils';
import { Analytics } from '../config/analytics';
import { getQueryParams } from './routes';

async function waitForLaunchScriptToLoad() {
  let attempt = 10;
  while (!(await Analytics.getInstance().isReady()) && attempt > 0) {
    await delay(1000);
    attempt -= 1;
  }
}

export async function getMarketingVehicleInfo() {
  await waitForLaunchScriptToLoad();
  const queryParams = getQueryParams(window.location.search);
  let campaignInfo: {
    id?: string;
    name?: string;
    queryParameter?: string;
  } = {};
  const channelInfo: {
    marketingVehicle?: string;
    marketingVehicleDetail?: string;
  } = {};
  let temp;

  // sdid
  if ((temp = queryParams.sdid)) {
    campaignInfo = {
      id: temp,
      name: 'CGen - sdid',
      queryParameter: 'sdid',
    };
    // trackingid
  } else if ((temp = queryParams.trackingid)) {
    campaignInfo = {
      id: temp,
      name: 'CGen - trackingid',
      queryParameter: 'trackingid',
    };
    // cgen
  } else if ((temp = queryParams.cgen)) {
    campaignInfo = {
      id: temp,
      name: 'CGen - cgen',
      queryParameter: 'cgen',
    };
    // s_cid
  } else if ((temp = queryParams.s_cid)) {
    campaignInfo = {
      id: temp,
      name: 'Salesforce - s_cid',
      queryParameter: 's_cid',
    };
    // s_rtid
  } else if ((temp = queryParams.s_rtid)) {
    campaignInfo = {
      id: temp,
      name: 'Salesforce - s_rtid',
      queryParameter: 's_rtid',
    };
  }

  // mv
  if (queryParams.mv) {
    channelInfo.marketingVehicle = String(queryParams.mv);
  }
  if (queryParams.mv2) {
    channelInfo.marketingVehicleDetail = String(queryParams.mv2);
  }

  return {
    channelInfo: channelInfo,
    campaignInfo: campaignInfo,
    landingPage: window.location.pathname,
  };
}
