import { Routine } from 'redux-saga-routines';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { call, cancelled, put } from 'redux-saga/effects';

export function v3request(api: AxiosInstance, config: AxiosRequestConfig) {
  return call(function* () {
    const source = axios.CancelToken.source();
    try {
      return yield call(api.request, {
        cancelToken: source.token,
        ...config,
      });
    } finally {
      if (yield cancelled()) {
        source.cancel();
      }
    }
  });
}

export function* v3requestRoutine({
  routine,
  config,
  api,
  onSuccess,
}: {
  routine: Routine;
  config: AxiosRequestConfig;
  api: AxiosInstance;
  onSuccess?: () => void;
}) {
  try {
    yield put(routine.request());
    const response = yield v3request(api, config);
    yield put(routine.success(response.data));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    yield put(routine.failure(err));
  } finally {
    yield put(routine.fulfill());
  }
}
