import { PubSub } from '@adobe/edex/ui/shared/utils/pubsub';
import {
  CourseCopyNotificationParams,
  CustomNotificationVariant,
  NotificationParams,
  NotificationPlacement,
  NotificationVariant,
} from '@adobe/edex/ui/shared/types';
import { LocalEventType } from '@adobe/edex/ui/shared/constants';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { isEmpty } from 'lodash-es';

function toast({
  itemId,
  message,
  variant,
  placement = NotificationPlacement.bottomCenter,
  custom,
  playlistNames,
  courses,
}: Partial<NotificationParams>) {
  PubSub.getInstance().dispatchEvent(
    new CustomEvent<NotificationParams>(LocalEventType.notification, {
      detail: {
        variant,
        message,
        placement,
        custom,
        itemId,
        playlistNames,
        courses,
      },
    })
  );
}

export function infoToast(params: { message: string; placement?: NotificationPlacement }) {
  toast({ ...params, variant: NotificationVariant.positive });
}

export function errorToast({
  message = I18nKey.somethingWentWrong,
}: {
  message?: string;
} = {}) {
  toast({ message, variant: NotificationVariant.negative });
}

export function customToast(params: {
  message: string;
  placement?: NotificationPlacement;
  variant: NotificationVariant;
  custom: CustomNotificationVariant;
  itemId?: string;
  playlistNames?: string[];
  courses?: CourseCopyNotificationParams[];
}) {
  if (!isEmpty(params.playlistNames) || !isEmpty(params.courses)) {
    toast({ ...params });
  }
}
