import { html, customElement } from '@adobe/edex/ui/edex-lit';

import { fetchMetadata, fetchSelf } from '@adobe/edex/ui/store/routines';
import '@adobe/edex/ui/shared/config/onetrust';

import '@adobe/edex/ui/spectrum-web-components';
import '@adobe/edex/ui/components/shared/view-mode-selector/view-mode-selector';
import '@adobe/edex/ui/components/shared/loader/loader';
import '@adobe/edex/ui/shared/components/header/header';
import '@adobe/edex/ui/shared/components/footer/footer';
import '@adobe/edex/ui/shared/components/edex-banner/edex-banner';
import '@adobe/edex/ui/components/notification/notification';
import '@adobe/edex/ui/components/not-found/not-found';

import { EdexTagName, RoutePath } from '@adobe/edex/ui/shared/constants';
import { EdexAuth } from '@adobe/edex/ui/shared/config/auth';
import { BaseAuthElement } from '@adobe/edex/ui/components/base.element';
import { initRouter, isBuilderRoute } from '@adobe/edex/ui/config/router';
import {
  selectApiSelfLoading,
  selectLocationPathname,
  selectProductsByVanity,
  selectSelf,
} from '@adobe/edex/ui/store/selectors';
import { initWcsLocale } from '@adobe/edex/ui/shared/config/i18n';
import { SearchKey } from '@adobe/edex/ui/shared/types/search';
import { IMS } from '@adobe/edex/ui/shared/config/ims';
import { AnalyticsEvent } from './constants';
import { getMarketingVehicleInfo } from '@adobe/edex/ui/shared/utils/marketingVehicle';
import { FullStoryUtils } from '@adobe/edex/ui/shared/config/fullstory';

const css = () =>
  import(
    /* webpackChunkName: "component/app-element-styles" */
    /* webpackMode: "lazy" */
    '@adobe/edex/ui/app.element.scss'
  );

@customElement(EdexTagName.root)
export class EdexApplication extends BaseAuthElement {
  get useLightDom() {
    return true;
  }

  static get properties() {
    return {
      ...super.properties,
      selfIsLoading: { attribute: false },
      locationPathname: { type: String },
      products: { attribute: false },
    };
  }

  selfIsLoading = false;
  locationPathname: string;

  actions = {
    fetchMetadata: fetchMetadata.trigger,
    fetchSelf: fetchSelf.trigger,
    fulfillSelf: fetchSelf.fulfill,
  };

  products: ReturnType<typeof selectProductsByVanity> = {};

  async connectedCallback() {
    await Promise.all([css(), initWcsLocale()]);
    await super.connectedCallback();
  }

  async firstUpdated() {
    await Promise.all([this.initRouter(), this.initSelf()]);
    this.handleCampaignEvents();
  }

  async handleCampaignEvents() {
    const { channelInfo, campaignInfo, landingPage } = await getMarketingVehicleInfo();
    if (channelInfo) {
      this.track({
        event: AnalyticsEvent.visitViaCampaign,
        payload: {
          mvParams: {
            cid: campaignInfo.id,
            cname: campaignInfo.name,
            cqueryParameter: campaignInfo.queryParameter,
            mv: channelInfo.marketingVehicle,
            mvDetail: channelInfo.marketingVehicleDetail,
            landingPage,
          },
        },
      });
    }
  }

  async initRouter() {
    return initRouter(this.querySelector('main'));
  }

  stateChanged(state) {
    super.stateChanged(state);
    this.selfIsLoading = selectApiSelfLoading(state);
    this.products = selectProductsByVanity(state);
    this.locationPathname = selectLocationPathname(state);
    this.identifyFSUSer(state);
  }

  private identifyFSUSer(state: any) {
    const self = selectSelf(state);
    if (self?.data) {
      FullStoryUtils.getInstance().identifyUser(self.data);
    }
  }

  async initSelf() {
    const isAuth = await IMS.isAuthenticated();
    if (isAuth) {
      this.actions.fetchSelf();
    } else {
      this.actions.fulfillSelf();
    }
  }

  onRequestMetadata({ detail }) {
    this.actions.fetchMetadata(detail);
  }

  onSearch({ detail: { q } }) {
    this.navigate({
      path: RoutePath.search,
      queryParams: {
        [SearchKey.query]: q,
      },
    });
  }

  render() {
    const { selfIsLoading, locationPathname, memberProfile, viewMode, products, onRequestMetadata, onSearch } = this;
    return html`
      <sp-theme color="lightest" scale="medium">
        <div class="app-wrapper">
          <edex-header
            .user=${memberProfile}
            .viewMode=${viewMode}
            .products=${products}
            @requestMetadata=${onRequestMetadata}
            @searchChanged=${onSearch}
            ?selfIsLoading=${selfIsLoading}
            locationPathname=${locationPathname}
          ></edex-header>
          <main></main>
          <edex-footer ?hidden=${isBuilderRoute()}></edex-footer>
          <edex-notification></edex-notification>
        </div>
      </sp-theme>
    `;
  }
}
