import { customElement, html } from '@adobe/edex/ui/edex-lit';
import { TemplateResult } from '@adobe/edex/ui/edex-lit';
import { classMap } from '@adobe/edex/ui/edex-lit';

import { EdexAuth } from '@adobe/edex/ui/shared/config/auth';
import { MemberProfile } from '@adobe/edex/ui/shared/types';
import {
  AdobeAnalyticsEvent,
  EdexDomID,
  EdexTagName,
  RoutePath,
  TARGET_TRIGGER_VIEW,
  ViewMode,
} from '@adobe/edex/ui/shared/constants';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { $t, getCurrentLocale } from '@adobe/edex/ui/shared/config/i18n';
import { Analytics } from '@adobe/edex/ui/shared/config/analytics';
import { IMS } from '@adobe/edex/ui/shared/config/ims';
import '@adobe/edex/ui/shared/components/apps-grid/apps-grid';

import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { Locale } from '../../constants';
import { edexLogo } from './logo';
import './member-menu/member-menu';
import './mobile-sidenav/mobile-sidenav';
import './search/search';
import './header.scss';
import { buildLink } from '@adobe/edex/ui/shared/utils/routes';
import '@adobe/edex/ui/shared/components/header/search/signUpDialog/signUp-dialog';

@customElement(EdexTagName.header)
export class Header extends BaseElement {
  get useLightDom() {
    return true;
  }

  static handleClickSearch() {
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.search, 'Main nav search');
  }

  static handleClickSignin() {
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signin, 'Main nav sign in');
    EdexAuth.signIn();
  }

  handleClickSignup() {
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signup, 'Main nav sign up');
    this.signUpDialogOpen = true;
  }

  static get properties() {
    return {
      user: { attribute: false },
      selfIsLoading: { type: Boolean },
      locationPathname: { type: String },
      imsIsReady: { attribute: false },
      viewMode: { attribute: false },
      products: { attribute: false },
      open: { type: Boolean },
      signUpDialogOpen: { type: Boolean },
    };
  }
  selfIsLoading = false;
  imsIsReady = false;
  locationPathname: string;
  open = false;
  signUpDialogOpen = false;

  async firstUpdated() {
    this.imsIsReady = await IMS.isReady();
  }

  get showSignIn(): boolean {
    const { isGuest, loading } = this;
    return isGuest && !loading;
  }

  get loading() {
    const { imsIsReady, selfIsLoading } = this;
    return !imsIsReady || selfIsLoading;
  }

  get showProfessionalLearning(): boolean {
    // TODO: dynamic based on course count in locale
    return getCurrentLocale() !== Locale.de;
  }

  isNavLinkActive(path: RoutePath): boolean {
    const localelessPathname = this.locationPathname?.replace(/^(\/(en|de|jp))?/, '');
    if (path === RoutePath.landing) {
      return localelessPathname === path;
    }
    return !!localelessPathname?.startsWith(path);
  }

  get memberProfile(): MemberProfile {
    const { user, isGuest } = this;
    return isGuest ? null : user;
  }

  get isGuest() {
    const { viewMode, user } = this;
    return !viewMode ? !user : viewMode === ViewMode.guest;
  }
  user: MemberProfile; // do not access user directly, use memberProfile instead
  viewMode: ViewMode;
  selfIsFulfilled = false;
  products;

  async connectedCallback() {
    super.connectedCallback();
    window.adobe?.target.triggerView(TARGET_TRIGGER_VIEW.header);
  }

  render(): TemplateResult {
    const { memberProfile, showSignIn, loading, showProfessionalLearning, locationPathname, products } = this;
    return html`
      <header>
        <div class="nav-left">
          <edex-mobile-sidenav
            class="mobile-only"
            .member=${memberProfile}
            .locationPathname=${locationPathname}
            .showProfessionalLearning=${showProfessionalLearning}
            ?loading=${loading}
            @signup=${() => (this.signUpDialogOpen = true)}
          ></edex-mobile-sidenav>
          ${this.renderTabAndDesktopLogo()} ${this.renderMobileLogo()}
          <nav class="nav-middle desktop-only" aria-label="site">
            <div
              class="main-nav__link ${classMap({
                'main-nav__link--active':
                  this.isNavLinkActive(RoutePath.teachingResources) ||
                  this.isNavLinkActive(RoutePath.teachingResourceNav),
              })}"
            >
              <sp-link href="${buildLink(RoutePath.teachingResourceNav)}" rel="external" target="_top" quiet>
                ${$t(I18nKey.navTeachingResources)}
              </sp-link>
            </div>
            ${showProfessionalLearning
              ? html`
                  <div
                    id="pl_navlink"
                    class="main-nav__link ${classMap({
                      'main-nav__link--active':
                        this.isNavLinkActive(RoutePath.professionalLearning) ||
                        this.isNavLinkActive(RoutePath.professionalLearningNav),
                    })}"
                  >
                    <sp-link href="${buildLink(RoutePath.professionalLearningNav)}" rel="external" target="_top" quiet>
                      ${$t(I18nKey.navProfessionalLearning)}
                    </sp-link>
                  </div>
                `
              : null}
            <div
              class="main-nav__link ${classMap({
                'main-nav__link--active': this.isNavLinkActive(RoutePath.community),
              })}"
            >
              <sp-link href="${buildLink(RoutePath.community)}" rel="external" target="_top" quiet>
                ${$t(I18nKey.navCommunity)}
              </sp-link>
            </div>
          </nav>
        </div>
        <div class="nav-right">
          <edex-search
            @click=${Header.handleClickSearch}
            @searchFocus=${() => {
              this.open = true;
            }}
            @searchBlur=${() => {
              this.open = false;
            }}
            tabindex="0"
          ></edex-search>
          ${showSignIn
            ? html`
                <sp-button
                  variant="primary"
                  id=${EdexDomID.signInDesktop}
                  class="desktop-only"
                  @click=${Header.handleClickSignin}
                >
                  ${$t(I18nKey.navSignIn)}
                </sp-button>
                <sp-button
                  variant="cta"
                  id=${EdexDomID.signUpDesktop}
                  class="desktop-only"
                  @click=${() => this.handleClickSignup()}
                >
                  ${$t(I18nKey.navSignUp)}
                </sp-button>
              `
            : null}
          ${memberProfile
            ? html`
                <edex-member-menu .member=${memberProfile} class="desktop-only"></edex-member-menu>
              `
            : null}
          <edex-apps-grid class="desktop-only" .products=${products}></edex-apps-grid>
        </div>
        ${this.renderSignUpModal()}
      </header>
    `;
  }

  renderTabAndDesktopLogo() {
    return html`
      <sp-link href="${buildLink(RoutePath.landing)}" class="logo desktop" quiet>${edexLogo}</sp-link>
      <sp-link href="${buildLink(RoutePath.landing)}" class="logo tablet" quiet>${edexLogo}</sp-link>
    `;
  }

  renderMobileLogo() {
    if (this.open) {
      return null;
    }
    return html`
      <sp-link href="${buildLink(RoutePath.landing)}" class="logo mobile" quiet>${edexLogo}</sp-link>
    `;
  }

  renderSignUpModal() {
    return html`
      <edex-signup-dialog
        .signUpDialogOpen=${this.signUpDialogOpen}
        @close=${() => {
          this.signUpDialogOpen = false;
        }}
      ></edex-signup-dialog>
    `;
  }
}
