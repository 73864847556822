import { fetchSelf, updateSchoolDistrict } from '@adobe/edex/ui/store/routines';
import { MemberProfile } from '@adobe/edex/ui/types';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';

const initialState = Object.freeze({
  data: null as MemberProfile,
  loading: false,
  isFulfilled: false,
  isUpdated: false,
  error: null,
});

export function self(state = initialState, action: EdexAction<MemberProfile>): typeof initialState {
  switch (action.type) {
    case fetchSelf.TRIGGER:
      return {
        ...state,
        loading: true,
        isFulfilled: false,
      };
    case fetchSelf.SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case fetchSelf.FAILURE:
      return state;
    case fetchSelf.FULFILL:
      return {
        ...state,
        loading: false,
        isFulfilled: true,
      };
    case updateSchoolDistrict.TRIGGER:
      return {
        ...state,
        isUpdated: false,
      };
    case updateSchoolDistrict.SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case updateSchoolDistrict.FAILURE:
      return {
        ...state,
        error: true,
      };
    case updateSchoolDistrict.FULFILL:
      return {
        ...state,
        isUpdated: true,
        error: false,
      };

    default:
      return state;
  }
}
