import './app/app.element.ts';

import { IMS } from '@adobe/edex/ui/shared/config/ims';
import { WebpSupport } from '@adobe/edex/ui/shared/utils/imageResize';

async function bootstrap() {
  WebpSupport.init();
  IMS.bootstrap();
}

bootstrap().catch(console.error);
