import { NX_CDN_HOST } from '@env/environment';
import { CDN_UPLOADS_FOLDER, Locale } from '@adobe/edex/ui/shared/constants';
import axios from 'axios';
import { CuratedContent } from '@adobe/edex/ui/types';

export const cdn = NX_CDN_HOST;
export const CDN_I18N_FOLDER = 'v3/i18n';

export const CDN_UPLOADS_PATH = `${cdn}/${CDN_UPLOADS_FOLDER}`;
export const CDN_I18N_PATH = `${cdn}/${CDN_I18N_FOLDER}`;

export const CDN_CURATED_CONTENT = `${cdn}/v3/curatedContent`;

export function replaceCdnHost(src: string): string {
  return !src
    ? src
    : src
        .replace('https://edexfe-public.s3.amazonaws.com', NX_CDN_HOST)
        .replace('https://ex-staticpages.s3.amazonaws.com', NX_CDN_HOST)
        .replace('https://ex-cms.s3.amazonaws.com', NX_CDN_HOST)
        .replace('https://edexfe.s3.amazonaws.com', NX_CDN_HOST)
        .replace('https://s3.amazonaws.com/edexfe-public', NX_CDN_HOST)
        .replace('https://s3.amazonaws.com/staticpages', NX_CDN_HOST)
        .replace('https://s3.amazonaws.com/ex-cms', NX_CDN_HOST)
        .replace('https://s3.amazonaws.com/edexfe', NX_CDN_HOST)
        .replace('https://s3.amazonaws.com/exAttachments', `${NX_CDN_HOST}/exAttachments`)
        .replace('https://s3.amazonaws.com/edexfe-programs', `${NX_CDN_HOST}/edexfe-programs`);
}

const curatedContentData = {};
export async function getCuratedContent(locale: Locale): Promise<CuratedContent> {
  if (!curatedContentData[locale]) {
    const response = await axios.get(`${CDN_CURATED_CONTENT}/${locale}.json`);
    curatedContentData[locale] = response.data;
  }
  return curatedContentData[locale];
}
