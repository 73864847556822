import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  actAsRequestInterceptor,
  authRequestInterceptor,
  getApiInstance,
  localeRequestInterceptor,
  newrelicResponseInterceptor,
} from '@adobe/edex/ui/shared/api/utils';

import { errorToast } from '../utils/notification';

const toastedResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(null, (error) => {
    if (!axios.isCancel(error) && ['put', 'post', 'delete'].includes(error.config?.method)) {
      errorToast();
    }
    return Promise.reject(error);
  });
};

const defaultInterceptors = [
  authRequestInterceptor,
  actAsRequestInterceptor,
  localeRequestInterceptor,
  newrelicResponseInterceptor,
  toastedResponseInterceptor,
];

export const getV3ApiInstance = (config?: AxiosRequestConfig, interceptors = defaultInterceptors) =>
  getApiInstance({
    interceptors,
    config,
  });

window.addEventListener('unhandledrejection', async function (event) {
  if (axios.isCancel(event.reason)) {
    console.warn('Unhandled cancel', event.reason);
    event.preventDefault();
  }
});
