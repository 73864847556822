import { appendSourceInfo, getNavSource, noticeError } from '@adobe/edex/ui/utils';
import {
  AnalyticsPageInfo,
  AnalyticsSearchFilterInfo,
  AnalyticsSearchInfo,
} from '@adobe/edex/ui/shared/types/analytics';

declare global {
  interface Window {
    alloy_all: {
      web?: {
        webPageDetails?: {
          name?: string;
          siteSection?: string;
        };
      };
      _adobe_corpnew?: {
        app?: {
          appName?: string;
          language?: string;
        };
      };
    };
    _satellite: {
      // Adobe Analytics launch script SPA tracking method
      track: (string, any) => any;
    };
  }
}

export class Analytics {
  private static instance: Analytics;

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance;
  }

  /**
   * https://wiki.corp.adobe.com/pages/viewpage.action?pageId=1792193314
   *
   * 5) __satelliteLoadedCallback and __satelliteLoadedPromise for detecting when Launch has loaded (for when people need to use _satellite APIs but don't know when it has loaded)
   */
  async isReady(): Promise<boolean> {
    return !!(await window['__satelliteLoadedPromise']);
  }

  private trackedPageload: boolean;
  public async pageView(pageInfo: AnalyticsPageInfo) {
    try {
      if (!(await this.isReady())) {
        return;
      }

      // add common things to the data layer
      window.alloy_all = window.alloy_all || {};
      window.alloy_all.web = window.alloy_all.web || {};
      window.alloy_all.web.webPageDetails = window.alloy_all.web.webPageDetails || {};
      window.alloy_all.web.webPageDetails.name = pageInfo.pageName;
      window.alloy_all.web.webPageDetails.siteSection = pageInfo.siteSection;
      window.alloy_all._adobe_corpnew = window.alloy_all._adobe_corpnew || {};
      window.alloy_all._adobe_corpnew.app = window.alloy_all._adobe_corpnew.app || {};
      window.alloy_all._adobe_corpnew.app.appName = 'EdEx';
      window.alloy_all._adobe_corpnew.app.language = pageInfo.language;

      // fire state call
      window._satellite.track(this.trackedPageload ? 'state' : 'pageload', {
        data: {},
        xdm: {},
      });

      this.trackedPageload = true;
    } catch (e) {
      noticeError(e);
    }
  }

  public async searchEvent(searchInfo: AnalyticsSearchInfo, filters: AnalyticsSearchFilterInfo[]) {
    try {
      if (!(await this.isReady())) {
        return;
      }

      // fire event call
      window._satellite.track('event', {
        data: {
          web: {
            webInteraction: {
              name: 'search',
            },
          },
          _adobe_corpnew: {
            digitalData: {
              search: {
                searchInfo: { ...searchInfo },
                filters: [...filters],
              },
            },
          },
        },
        xdm: {},
      });
    } catch (e) {
      noticeError(e);
    }
  }

  public async emitEvent(eventName: string, eventAction: string) {
    try {
      if (!(await this.isReady())) {
        return;
      }

      eventAction = appendSourceInfo(eventAction, getNavSource());

      // fire event call
      window._satellite.track('event', {
        data: {
          web: {
            webInteraction: {
              name: eventName,
            },
          },
          _adobe_corpnew: {
            digitalData: {
              primaryEvent: {
                eventInfo: {
                  eventName: eventName,
                  eventAction: eventAction,
                },
              },
            },
          },
        },
        xdm: {},
      });
    } catch (e) {
      noticeError(e);
    }
  }
}
