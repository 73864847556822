import { CancelTokenSource } from 'axios';
import { FileSchema } from '@adobe/edex/ui/types/index';
import { EdexDomID } from '@adobe/edex/ui/shared/constants';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { Actid } from '@adobe/edex/ui/constants';
import { LinkSchema } from '@adobe/edex/ui/shared/types';
import { DialogState } from '@adobe/edex/ui/types/state';

export * from '@adobe/edex/ui/types/state';

export enum RoutineStage {
  TRIGGER,
  SUCCESS,
  FAILURE,
}

export enum DateVariant {
  short = 'MM/DD/YYYY',
  full = 'MMMM D, YYYY',
}

export interface TagItem {
  label: string;
  url?: string;
}

export enum PreviewType {
  image = 'image',
  video = 'video',
  text = 'text',
  web = 'web',
  pdf = 'pdf',
  document = 'document',
}

export enum PreviewCCXAssetType {
  video = 'video',
  web = 'web',
  page = 'page',
}

export type CarouselItem = {
  id?: string;
  file?: FileSchema;
  type: PreviewType;
  url: string;
  title: string;
  typeDisplay: string;
  icon?: string;
  size?: string;
  image?: string;
  sortOrder: number;
  urn?: string;
  assetType?: string;
  isLinkFromAssetBrowser?: boolean;
  isLinkFromProjectXAssetBrowser?: boolean;
  ownerUserId?: string;
  components?: string[];
  branchURL?: string;
  componentLabel?: string;
  hasMetadata?: boolean;
  remixable?: boolean;
  isProjectXRemixLink?: boolean;
  largeThumbnail?: string;
  gneissAlias?: string;
};

export enum ResourceBuilderSection {
  info = 'info',
  content = 'content',
  details = 'details',
}

export interface EdexRoute {
  path: string;
}

export interface ConfirmationDialogParams {
  id: EdexDomID;
  title: I18nKey;
  subtext: I18nKey;
  body?: I18nKey;
  confirmLabel: I18nKey;
  onConfirm: () => any;
  confirmBtnVariant?: string;
}
export interface SendToDriveDialogPayload {
  source: CancelTokenSource;
}

export interface DownloadFileDialogPayload {
  file?: FileSchema;
}

export interface ExportedDialogPayload {
  downloadSuccessHeader?: string;
  folderName?: string;
  link?: string;
  downloadButtonText?: string;
  downloadDialogText?: string;
  exportType?: ExportType;
}

export interface AuthDialogPayload {
  postAuth?: PostAuth;
}

export interface DeleteCommentDialogPayload {
  confirmCommentID: string;
}

export interface ResourceBuilderFileDialogPayload {
  files: FileSchema[];
  editing: boolean;
  currentIndex: number;
}

export interface ResourceBuilderLinkDialogPayload {
  link: LinkSchema;
}

export interface ResourceBuilderDeleteFileDialogPayload {
  confirm: () => void;
}

export interface PostAuth {
  actid?: Actid;
  dialog?: DialogState;
}

export interface SteplistItem {
  id: ResourceBuilderSection;
  label: I18nKey;
  completed: boolean;
}

export interface EditorData {
  plain: string;
  html: string;
}

export enum ResourceAttachmentType {
  file = 'file',
  link = 'link',
  video = 'video',
}

export enum ExportType {
  local = 'local',
  googleDrive = 'gd',
  oneDrive = 'od',
}

export enum SocialNetwork {
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Pinterest = 'Pinterest',
  LinkedIn = 'LinkedIn',
}

export interface ShareButtonMeta {
  name: SocialNetwork | 'link';
  shareUrl: string;
}

export interface PlaylistShareButtonMeta extends ShareButtonMeta {
  className: string;
  toolTipExists: boolean;
}

export interface PreviewClickTrackingMeta {
  event?: Event;
  url: string;
  isCCXtemplateUrl?: boolean;
  assetType?: string;
  isLinkFromAssetBrowser?: boolean;
  branchURL?: string;
}
