import { LitElement } from '@adobe/edex/ui/edex-lit';

export class BaseElement extends LitElement {
  emitEvent<T = any>(name: string, payload?: T, options?: EventInit): CustomEvent<T> {
    const event = new CustomEvent(name, { detail: payload, ...options });
    this.dispatchEvent(event);
    return event;
  }

  get useLightDom() {
    return false;
  }

  createRenderRoot(): Element | ShadowRoot {
    if (this.useLightDom) {
      return this;
    } else {
      return super.createRenderRoot();
    }
  }

  private langRerender = ({ detail: { lang, previousLang } }: CustomEvent<{ lang: string; previousLang: string }>) => {
    if (lang !== previousLang) {
      return this.requestUpdate();
    }
  };

  connectedCallback() {
    window.addEventListener('langChanged', this.langRerender);
    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener('langChanged', this.langRerender);
    super.disconnectedCallback();
  }
}
