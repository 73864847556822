import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { combineReducers } from 'redux';
import {
  CollectionCardSchema,
  CourseCardSchema,
  DiscussionCardSchema,
  ResourceCardSchema,
} from '@adobe/edex/ui/shared/types';
import { PaginatedRecords } from '@adobe/edex/ui/types/misc';
import { searchCourses, searchDiscussions, searchResources, searchCollections } from '@adobe/edex/ui/store/routines';
import { SearchEntityType } from '@adobe/edex/ui/shared/types/search';
import { Routine } from 'redux-saga-routines';
import { SEARCH_COLLECTIONS_SUCCESS } from '../../components/search-page/constants';
import { modifyResponse } from '../../utils/collection-utils';

const defaultState = <T>(): PaginatedRecords<T> & {
  loading: boolean;
} => ({
  data: null,
  meta: {
    totalRecords: 0,
    offset: 0,
    limit: 0,
    hasMore: false,
  },
  loading: false,
});

const searchReducer = <T extends ResourceCardSchema | CourseCardSchema | DiscussionCardSchema | CollectionCardSchema>(
  routine: Routine
) => (
  state = defaultState<T>(),
  action: EdexAction<any>
): PaginatedRecords<T> & {
  loading: boolean;
} => {
  switch (action.type) {
    case routine.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case routine.SUCCESS:
      if (action.type === SEARCH_COLLECTIONS_SUCCESS) {
        action.payload.data = modifyResponse(action.payload);
        return {
          ...state,
          ...action.payload,
        };
      }
      return {
        ...state,
        ...action.payload,
      };
    case routine.FULFILL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const search = combineReducers({
  [SearchEntityType.resources]: searchReducer<ResourceCardSchema>(searchResources),
  [SearchEntityType.courses]: searchReducer<CourseCardSchema>(searchCourses),
  [SearchEntityType.discussions]: searchReducer<DiscussionCardSchema>(searchDiscussions),
  [SearchEntityType.collections]: searchReducer<CollectionCardSchema>(searchCollections),
});

export type SearchState = ReturnType<typeof search>;
