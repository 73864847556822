import { html } from '@adobe/edex/ui/edex-lit';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

import {
  DateVariant,
  PrimarySecondary,
  ProductSchema,
  TagItem,
  PreviewType,
  FileSchema,
  SubjectSchema,
  AcademicLevelSchema,
} from '@adobe/edex/ui/types';
import { mergePrimarySecondary, getAssetURL, getExtension } from '@adobe/edex/ui/shared/utils';
import {
  AcademicLevelI18nLabel,
  AcademicLevelID,
  ENGLISH_SUBJECT,
  IS_TEST,
  SubjectI18nLabel,
  SubjectID,
} from '@adobe/edex/ui/shared/constants';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { EdexMimeType, MIME_TYPE_IMAGE_REGEX, MIME_TYPE_TEXT_REGEX } from '@adobe/edex/ui/shared/constants';
import { SearchEntityType, SearchKey } from '@adobe/edex/ui/shared/types/search';
import { getEntitySearchUrl } from '@adobe/edex/ui/shared/utils/routes';
import { translateUnsafeHTML } from '@adobe/edex/ui/shared/config/i18n';
import { environment } from '@env/environment';
import { subjects2treeItems, sortAcademicLevels } from '@adobe/edex/ui/shared/utils/metadata';
import { AllID } from '../components/search-page/search-filters/search-filters';

export { delay } from '@adobe/edex/ui/shared/utils';
export * from '@adobe/edex/ui/shared/utils';
export * from '@adobe/edex/ui/shared/utils/imageResize';

export const sanitize = (source: string): string => DOMPurify.sanitize(source);

export function formatDateString(val: string, variant = DateVariant.full) {
  return dayjs(val).format(variant);
}

function handleTranslatedHTMLClick(handler: (e: Event) => unknown) {
  return function (e: Event) {
    const target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      e.preventDefault();
      handler(e);
    }
  };
}

interface RenderTranslatedHTMLParams {
  key: I18nKey;
  linkHandler?: (e: Event) => unknown;
}

export function renderTranslatedHTML(params: RenderTranslatedHTMLParams) {
  return html`
    <div @click="${params.linkHandler ? handleTranslatedHTMLClick(params.linkHandler) : null}">
      ${translateUnsafeHTML(params.key)}
    </div>
  `;
}

export const getTotalProducts = (products: PrimarySecondary<ProductSchema>): number =>
  products.secondary.length + (products.primary ? 1 : 0);

export const getTagItemsFromPrimarySecondary = (
  data: PrimarySecondary<SubjectSchema | AcademicLevelSchema>,
  queryParam: SearchKey.subject | SearchKey.ageLevel,
  entityType: SearchEntityType
): TagItem[] =>
  mergePrimarySecondary(data).map(({ i18nLabel, urlLabel }) => ({
    label: i18nLabel,
    url: getEntitySearchUrl(entityType, { [queryParam]: urlLabel }),
  }));

/**
 * in: /ui/:locale?/resource/:vanityURL { locale: 'en', vanityURL: 'test'}
 * out: /ui/en/resource/test
 */
export function compileRegexPath(path: string, dict: Record<string, string>) {
  return Object.entries(dict)
    .reduce((path, [key, value]) => path.replace(new RegExp(`:${key}[?]?`), value), path)
    .replace(/\/\//g, '/'); // -> /
}

export function triggerOnceIfVisible(targets: HTMLElement[], callback: () => void): (() => void)[] {
  const unsubscribe = [];

  targets = targets.filter(Boolean).filter((el) => IS_TEST || !!el.offsetParent);
  const events = ['scroll', 'resize'];
  const handler = () => {
    // if no elements provided - exit without callback
    if (targets.length === 0) {
      return true;
    }

    const isVisible = targets.some((target) => target.getBoundingClientRect().top <= window.innerHeight);
    if (isVisible) {
      callback();
      unsubscribe.forEach((cb) => cb());
    }
    return isVisible;
  };

  const isVisible = handler();
  if (!isVisible) {
    events.forEach((event) => {
      addEventListener(event, handler);
      unsubscribe.push(() => removeEventListener(event, handler));
    });
  }

  return unsubscribe;
}

export function mime2previewType(mimeType: string): PreviewType {
  let previewType: PreviewType = null;

  // full match
  switch (mimeType) {
    case EdexMimeType.pdf:
      previewType = PreviewType.pdf;
      break;
    case EdexMimeType.document:
    case EdexMimeType.presentation:
    case EdexMimeType.powerpoint:
    case EdexMimeType.slideshow:
    case EdexMimeType.sheet:
    case EdexMimeType.excel:
    case EdexMimeType.msword:
    case EdexMimeType.odp:
    case EdexMimeType.ods:
    case EdexMimeType.odt:
      previewType = PreviewType.document;
      break;
    case EdexMimeType.video:
      // Internally hosted video files excluded from rendering video preview
      break;
    default:
      break;
  }

  // if no match - check special cases
  if (!previewType) {
    if (MIME_TYPE_TEXT_REGEX.test(mimeType)) {
      previewType = PreviewType.text;
    } else if (MIME_TYPE_IMAGE_REGEX.test(mimeType) && mimeType !== EdexMimeType.psd) {
      previewType = PreviewType.image;
    } else {
      return previewType;
    }
  }

  return previewType;
}

function extension2icon(extension: string) {
  switch (extension) {
    case 'pdf':
      return 'pdf-icon.png';
    case 'doc':
    case 'docx':
      return 'Word.svg';
    case 'xls':
    case 'xlsx':
      return 'Excel.svg';
    case 'one':
      return 'OneNote.svg';
    case 'ppt':
    case 'pptx':
      return 'PowerPoint.svg';
    case 'odp':
      return 'odp_file.svg';
    case 'ods':
      return 'ods_file.svg';
    case 'odt':
      return 'odt_file.svg';
    case 'eps':
      return 'image-eps.png';
    case 'gif':
      return 'image-gif.png';
    case 'jpeg':
    case 'jpg':
      return 'image-jpeg.png';
    case 'png':
      return 'image-png.png';
    case 'raw':
      return 'image-raw.png';
    case 'svg':
      return 'image-svg.png';
    case 'tiff':
      return 'image-tiff.png';
    case 'bmp':
      return 'image-bmp.png';
    case 'aep':
      return 'aep-icon.png';
    case 'ai':
      return 'ai-icon.png';
    case 'aiff':
      return 'aiff-icon.png';
    case 'auproj':
      return 'auproj-icon.png';
    case 'avi':
      return 'avi_file.svg';
    case 'chproj':
      return 'chproj-icon.png';
    case 'csv':
      return 'csv_file.svg';
    case 'dn':
      return 'dn-icon.png';
    case 'fla':
      return 'fla-icon.png';
    case 'html':
      return 'html-icon.png';
    case 'indd':
      return 'indd-icon.png';
    case 'm4a':
      return 'm4a-icon.png';
    case 'mov':
      return 'mov_file.svg';
    case 'mp3':
      return 'mp3-icon.png';
    case 'mp4':
      return 'mp4_file.svg';
    case 'prproj':
      return 'prproj-icon.png';
    case 'psd':
      return 'psd-icon.png';
    case 'ruproj':
      return 'ruproj-icon.png';
    case 'wav':
      return 'wav-icon.png';
    case 'xd':
      return 'xd-icon.png';
    case 'zip':
      return 'zip_file.svg';
    default:
      return null;
  }
}

function mime2icon(mimeType: string): string {
  let result: string;
  if (MIME_TYPE_TEXT_REGEX.test(mimeType)) {
    result = 'text-icon.png';
  }
  return result;
}

export const DEFAULT_FILE_ICON = 'default-file-icon.svg';
export function getFileIconUrl({ storagePath, mimeType }: Pick<FileSchema, 'storagePath' | 'mimeType'>) {
  const ext = getExtension(storagePath);
  const icon = extension2icon(ext) || mime2icon(mimeType) || DEFAULT_FILE_ICON;
  return getAssetURL(icon);
}

export { buildPath } from '@adobe/edex/ui/shared/utils';

export const isAllid = (id: string): boolean => id === SubjectI18nLabel.all || id === AcademicLevelI18nLabel.all;

export function getBaseURL() {
  return environment.NX_UI_URL;
}

export function sortJsonArray(jsonArr, attr) {
  jsonArr.sort((a, b) => a[attr].localeCompare(b[attr]));
}

export function addOrder(jsonArr, startOrder = 0) {
  return jsonArr.map((item) => {
    startOrder += 1;
    return { ...item, sortOrder: `${startOrder}` };
  });
}

export function spMenuItemWrapper(value: string, text: string, disabled: boolean) {
  return disabled
    ? html`
        <sp-menu-item value=${value} disabled>${text}</sp-menu-item>
      `
    : html`
        <sp-menu-item value=${value}>${text}</sp-menu-item>
      `;
}

export function setCustomPartProperty(parentSelector: string, shadowRootSelector: string, partAttrValue: string) {
  window.document
    .querySelector(parentSelector)
    .shadowRoot.querySelector(shadowRootSelector)
    .setAttribute('part', partAttrValue);
}
