import { createRoutine } from 'redux-saga-routines';
import {
  CreateCommentPayload,
  FetchCommentsPayload,
  FetchMetadataActionPayload,
  IdActionPayload,
  RateResourceActionPayload,
  UpdateCommentPayload,
  UpdateResourceActionPayload,
  UpdateResourceFormActionPayload,
  ChangeViewModeActionPayload,
  TrackAnalyticsActionPayload,
  FetchRelatedResourcesPayload,
  CommentFormActionPayload,
  CreateResourceActionPayload,
  InitResourceFormActionPayload,
  UpdateFileActionPayload,
  SearchActionPayload,
  PreferencesActionPayload,
  UpdatePlaylistActionPayload,
  CreatePlaylistActionPayload,
  FetchPlaylistActionPayload,
  DeletePlaylistItemsActionPayload,
  UpdateItemToPlaylistActionPayload,
} from '@adobe/edex/ui/types/payload';
import { DialogState } from '@adobe/edex/ui/types';

export const fetchSelf = createRoutine<never>('FETCH_SELF');
export const updateSchoolDistrict = createRoutine<IdActionPayload>('UPDATE_SCHOOL_DISTRICT');
export const fetchResource = createRoutine<IdActionPayload>('FETCH_RESOURCE');
export const createResource = createRoutine<CreateResourceActionPayload>('CREATE_RESOURCE');
export const updateResource = createRoutine<UpdateResourceActionPayload>('UPDATE_RESOURCE');
export const deleteResource = createRoutine<IdActionPayload>('DELETE_RESOURCE');
export const reportResource = createRoutine<IdActionPayload>('REPORT_RESOURCE');
export const unReportResource = createRoutine<IdActionPayload>('UN_REPORT_RESOURCE');
export const saveResource = createRoutine<IdActionPayload>('SAVE_RESOURCE');
export const unSaveResource = createRoutine<IdActionPayload>('UN_SAVE_RESOURCE');
export const rateResource = createRoutine<RateResourceActionPayload>('RATE_RESOURCE');
export const fetchRelatedResources = createRoutine<FetchRelatedResourcesPayload>('FETCH_RELATED_RESOURCES');
export const fetchComments = createRoutine<FetchCommentsPayload>('FETCH_COMMENTS');
export const createComment = createRoutine<CreateCommentPayload>('CREATE_COMMENT');
export const deleteComment = createRoutine<IdActionPayload>('DELETE_COMMENT');
export const updateComment = createRoutine<UpdateCommentPayload>('UPDATE_COMMENT');
export const reportComment = createRoutine<IdActionPayload>('REPORT_COMMENT');
export const unReportComment = createRoutine<IdActionPayload>('UNREPORT_COMMENT');
export const fetchMetadata = createRoutine<FetchMetadataActionPayload>('FETCH_METADATA');

export const setCommentForm = createRoutine<CommentFormActionPayload>('SET_COMMENT_FORM');
export const updateCommentForm = createRoutine<CommentFormActionPayload>('UPDATE_COMMENT_FORM');

export const initResourceForm = createRoutine<InitResourceFormActionPayload>('INIT_RESOURCE_FORM');
export const updateResourceForm = createRoutine<UpdateResourceFormActionPayload>('UPDATE_RESOURCE_FORM');
export const resetResourceForm = createRoutine<UpdateResourceFormActionPayload>('RESET_RESOURCE_FORM');

export const changeViewMode = createRoutine<ChangeViewModeActionPayload>('CHANGE_VIEW_MODE');

export const trackAnalytics = createRoutine<TrackAnalyticsActionPayload>('TRACK_ANALYTICS');

export const createFile = createRoutine<any>('CREATE_FILE');
export const updateFile = createRoutine<UpdateFileActionPayload>('UPDATE_FILE');
export const deleteFile = createRoutine<IdActionPayload>('DELETE_FILE');

export const openDialog = createRoutine<DialogState>('OPEN_DIALOG');
export const closeDialog = createRoutine('CLOSE_DIALOG');

export const routerLocationChanged = createRoutine('ROUTER_LOCATION_CHANGED');

export const searchResources = createRoutine<SearchActionPayload>('SEARCH_RESOURCES');
export const searchCourses = createRoutine<SearchActionPayload>('SEARCH_COURSES');
export const searchDiscussions = createRoutine<SearchActionPayload>('SEARCH_DISCUSSIONS');
export const searchCollections = createRoutine<SearchActionPayload>('SEARCH_COLLECTIONS');

export const changePreferences = createRoutine<PreferencesActionPayload>('CHANGE_PREFERENCES');
export const fetchPreferences = createRoutine<PreferencesActionPayload>('FETCH_PREFERENCES');
export const savePreferences = createRoutine<PreferencesActionPayload>('SAVE_PREFERENCES');

export const fetchAllPlaylists = createRoutine<never>('FETCH_ALL_PLAYLISTS');
export const fetchPlaylistLists = createRoutine<IdActionPayload>('FETCH_PLAYLISTS_LIST');
export const createPlaylist = createRoutine<CreatePlaylistActionPayload>('CREATE_PLAYLIST');
export const fetchPlaylist = createRoutine<FetchPlaylistActionPayload>('FETCH_PLAYLIST');
export const updatePlaylist = createRoutine<UpdatePlaylistActionPayload>('UPDATE_PLAYLIST');
export const deletePlaylist = createRoutine<IdActionPayload>('DELETE_PLAYLIST');
export const deletePlaylistItems = createRoutine<DeletePlaylistItemsActionPayload>('DELETE_PLAYLIST_ITEMS');
export const updateItemToPlaylist = createRoutine<UpdateItemToPlaylistActionPayload>('UPDATE_ITEM_PLAYLIST');
