import { CourseType, EntityStatus, SubjectSchema } from '@adobe/edex/ui/types';
import { NX_CDN_HOST, NX_UI_URL } from '@env/environment';
import { environment, NX_ONETRUST_ENV } from '@env/environment';

export enum CustomHttpHeader {
  ui = 'x-ui',
  actAs = 'x-act-as',
  qa = 'x-qa',
  qaPayload = 'x-qa-payload',
  locale = 'x-locale',
  xApiKey = 'x-api-key',
}

export const CDN_UPLOADS_FOLDER = 'v3/uploads';
export const CDN_PREVIEWS_FOLDER = 'v3/previews';
export const CDN_META_IMAGES = 'v3/meta-image';
export const COLLAPSED_TAGS_DISPLAY_COUNT = 2;
export const COLLAPSED_TOOL_TAGS_DISPLAY_COUNT = 4;
export const COLLAPSED_FILE_PREVIEW_DISPLAY_COUNT = 4;
export const RELATED_RESOURCES_DESCRIPTION_MAX_LENGTH = 90;
export const MAX_RELATED_RESOURCES_EXPORT_DIALOG = 3;
export const HERO_IMAGE_WIDTH = 1146;
export const DEFAULT_AVATAR = '/ui/assets/defaultAvatar.svg';
export const DEFAULT_RESOURCE_IMAGE = '/ui/assets/resource.jpg';
export const GOOGLE_VIEWER_MAX_FILE_SIZE = 25e6;
export const RESOURCE_TOOL_DISPLAY_LIMIT = 4;
export const DEFAULT_FILE_SIZE_LIMIT_MB = 50;
export const COVER_IMAGE_FILE_SIZE_LIMIT_MB = 5;
export const COVER_IMAGE_ALLOWED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.gif'];
export const MIME_TYPE_IMAGE_REGEX = /^image\//;
export const MIME_TYPE_TEXT_REGEX = /^text\//;
export const RESOURCE_BUILDER_MAX_FILES = 10;
export const EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;
export const PLAYLIST_ALLOWED_DESCRIPTION_TOTAL_CHAR = 200;

export enum EdexTagName {
  root = 'edex-root',
  header = 'edex-header',
  footer = 'edex-footer',
  landing = 'edex-landing',
  notFound = 'edex-not-found',
  homePageGuest = 'home-page-guest',
  homePageUser = 'home-page-user',
  homepageTRShelf = 'edex-homepage-tr-shelf',
  languageSelector = 'edex-language-selector',
  courseCongrats = 'edex-course-congrats',
  resourceDetails = 'edex-resource-details',
  resourceBuilder = 'edex-resource-builder',
  resourceTypeCard = 'edex-resource-type-card',
  resourceBuilderSection = 'edex-resource-builder-section',
  resourceBuilderInfoGuide = 'edex-resource-builder-info-guide',
  resourceBuilderContentGuide = 'edex-resource-builder-content-guide',
  resourceBuilderDetailsGuide = 'edex-resource-builder-details-guide',
  loader = 'edex-loader',
  notification = 'edex-notification',
  toolbar = 'edex-toolbar',
  resourceHeaderToolbar = 'edex-resource-header-toolbar',
  saveToGD = 'edex-save-to-gd',
  saveToOD = 'edex-save-to-od',
  downloadLocal = 'edex-download-local',
  dialog = 'edex-dialog',
  card = 'edex-card',
  tags = 'edex-tags',
  toolTags = 'edex-tool-tags',
  rating = 'edex-rating',
  filePreview = 'edex-file-preview',
  attachmentPreview = 'edex-attachment-preview',
  appsGrid = 'edex-apps-grid',
  comment = 'edex-comment',
  commentList = 'edex-comment-list',
  commentForm = 'edex-comment-form',
  memberMenu = 'edex-member-menu',
  resourceTypeBadge = 'edex-resource-type',
  resourceCard = 'edex-resource-card',
  resourceCoverSelector = 'edex-resource-cover-selector',
  viewModeSelector = 'edex-view-mode-selector',
  mobileSidenav = 'edex-mobile-sidenav',
  toolCard = 'edex-tool-card',
  steplist = 'edex-steplist',
  editor = 'edex-editor',
  html = 'edex-html',
  dropzone = 'edex-dropzone',
  multiselect = 'edex-multiselect',
  toolSelector = 'edex-tool-selector',
  contentStandardSelector = 'edex-content-standard-selector',
  subjectSelector = 'edex-subject-selector',
  keywordSelector = 'edex-keyword-selector',
  academicLevelSelector = 'edex-academic-level-selector',
  resourceBuilderIntro = 'edex-resource-builder-intro',
  resourceBuilderInfoSection = 'edex-resource-builder-info-section',
  resourceBuilderContentSection = 'edex-resource-builder-content-section',
  resourceBuilderDetailsSection = 'edex-resource-builder-details-section',
  resourceBuilderFileForm = 'edex-resource-builder-file-form',
  resourceBuilderLinkForm = 'edex-resource-builder-link-form',
  resourceBuilderCCXTemplateLinkForm = 'edex-resource-builder-ccx-template-link-form',
  fileThumbnail = 'edex-file-thumbnail',
  sortableList = 'edex-sortable-list',
  publishedResourceDialog = 'edex-published-resource-dialog',
  textfield = 'edex-textfield',
  treeSelector = 'edex-tree-selector',
  itemSelector = 'edex-item-selector',
  search = 'edex-search',
  formError = 'edex-form-error',
  resourceFormError = 'edex-resource-form-error',
  pod = 'edex-pod',
  discussionCard = 'edex-discussion-card',
  courseCard = 'edex-course-card',
  externalCourseCard = 'external-course-card',
  playlistCard = 'edex-playlist-card',
  promoBanner = 'edex-promo-banner',
  promoBannerLIHP = 'edex-promo-banner-lihp',
  searchPage = 'edex-search-page',
  searchFilters = 'edex-search-filters',
  teachingResources = 'edex-teaching-resources',
  featuredCollections = 'edex-featured-collections',
  searchBanner = 'edex-search-banner',
  professionalLearning = 'edex-professional-learning',
  welcomeBanner = 'edex-welcome-banner',
  searchNoResults = 'edex-search-no-results',
  welcomeCard = 'edex-welcome-card',
  loadingCard = 'edex-loading-card',
  pagination = 'edex-pagination',
  dialogNext = 'edex-dialog-next',
  resourceCardWrapper = 'edex-resource-card-wrapper',
  playlistCardWrapper = 'edex-playlist-card-wrapper',
  aceReport = 'edex-ace-report',
  resourceSettingsDialog = 'edex-resource-settings-dialog',
  resourceActionBar = 'edex-resource-action-bar',
  preferencesDialog = 'preferences-dialog',
  customizedPreferences = 'customized-preferences',
  collectionCard = 'edex-collection-card',
  importResultDialog = 'edex-import-result-dialog',
  importDetailsDialog = 'edex-import-details-dialog',
  noPhonesNotice = 'edex-no-phones',
  myEdex = 'my-edex',
  myEdexTeaching = 'my-edex-teaching',
  myEdexLearning = 'my-edex-learning',
  myEdexPlaylists = 'my-edex-playlists',
  createCourse = 'create-course',
  createPlaylist = 'create-playlist',
  myEdexEmptyState = 'my-edex-empty-state',
  otpInput = 'edex-otp-input',
  singleOtpInput = 'edex-single-otp-input',
  edexNewsletter = 'edex-newsletter',
  studentUnsubscribe = 'edex-student-unsubscribe',
  playlistView = 'playlist-view',
  socialMediaShare = 'social-media-share',
  playlistAuthorView = 'playlist-author-view',
  playlistSaveItemDialog = 'playlist-save-item-dialog',
  playlistUrlCopyCard = 'edex-playlist-url-copy-card',
  playlistEditDetailsDialog = 'playlist-editdetails-dialog',
  urlCopyTextfield = 'edex-url-copy-textfield',
  relatedResourcesShelf = 'edex-related-resource-shelf',
  resourceSidebar = 'edex-resource-sidebar',
  toolsNew = 'edex-tools-new',
  resourceMeta = 'edex-resource-meta',
  auth = 'edex-auth',
  appShare = 'edex-app-share',
  btsBanner = 'edex-bts-banner',
  staticPage = 'edex-static-page',
  signUpDialog = 'edex-signup-dialog',
  studentStepsSelector = 'edex-student-steps-selector',
  schoolAttributionDialog = 'edex-school-attribution-dialog',
  autoSuggestionInput = 'edex-auto-suggested-input',
  myEdexExternalCourse = 'my-edex-external-course',
  liveSessionDialog = 'live-session-dialog',
}

export enum EdexDomID {
  views = 'views',
  save = 'save',
  share = 'share',
  getResource = 'getResource',
  report = 'report',
  edit = 'edit',
  delete = 'delete',
  publish = 'publish',
  ages = 'ages',
  subjects = 'subjects',
  standards = 'standards',
  license = 'license',
  keywords = 'keywords',
  tooltip = 'tooltip',
  studentTime = 'student-time',
  notFound = 'not-found-page',
  resourceSettingsDialog = 'resource-settings-dialog',
  resourceShareDialog = 'resource-share-dialog',
  resourceBuilderSections = 'resource-builder-sections',
  resourceBuilderCustomStandards = 'resource-builder-custom-standards',
  resourceBuilderTitle = 'resource-builder-title',
  resourceBuilderShortDescr = 'resource-builder-shortDescription',
  resourceBuilderDescr = 'resource-builder-description',
  editCommentForm = 'edit-comment-form',
  replyCommentForm = 'reply-comment-form',
  createCommentBtn = 'create-comment-btn',
  editCommentBtn = 'edit-comment-btn',
  replyCommentBtn = 'reply-comment-btn',
  deleteCommentBtn = 'delete-comment-btn',
  reportCommentBtn = 'report-comment-btn',
  loadMoreCommentsBtn = 'load-more-comments-btn',
  cancelCommentBtn = 'cancel-comment-btn',
  submitCommentBtn = 'submit-comment-btn',
  resourceSendToDriveProgressDialog = 'resourceSendToDriveProgressDialog',
  resourceSignInConfirmationDialog = 'resourceSignInConfirmationDialog',
  resourceReportConfirmationDialog = 'resourceReportConfirmationDialog',
  resourceDeleteConfirmationDialog = 'resourceDeleteConfirmationDialog',
  resourceUnpublishConfirmationDialog = 'resourceUnpublishConfirmationDialog',
  resourcePublishedConfirmationDialog = 'resourcePublishedConfirmationDialog',
  resourceDeleteFileConfirmationDialog = 'resourceDeleteFileConfirmationDialog',
  resourceSettings = 'resourceSettings',
  commentReportConfirmationDialog = 'commentReportConfirmationDialog',
  commentDeleteConfirmationDialog = 'commentDeleteConfirmationDialog',
  viewMore = 'viewMore',
  getResourceDialog = 'get-resource-dialog',
  videoTrainDialog = 'video-train-dialog',
  resourceCoverDialog = 'resource-cover-dialog',
  getResourceItemDialog = 'get-resource-item-dialog',
  resourceExportedDialog = 'resource-exported-dialog',
  resourceCard = 'resource-card',
  resourceCover = 'resource-cover',
  resourceCardImage = 'resource-card-image',
  signOut = 'signOut',
  signInMobile = 'signInMobile',
  signUpMobile = 'signUpMobile',
  signInDesktop = 'signInDesktop',
  signUpDesktop = 'signUpDesktop',
  downloadLink = 'downloadLink',
  seeAllToolsDialog = 'seeAllToolsDialog',
  sidenavMemberMenu = 'sidenavMemberMenu',
  resourceBuilderCoverImage = 'resource-builder-cover-image',
  resourceBuilderFilesLinks = 'resource-builder-files-links',
  resourceBuilderFileDialog = 'resource-builder-file-dialog',
  resourceBuilderFileDialogNextSave = 'resource-builder-file-dialog-next-save',
  resourceBuilderDiscardDialog = 'resource-builder-discard-dialog',
  resourceBuilderLinkDialog = 'resource-builder-add-link-dialog',
  resourceBuilderLinkDialogSave = 'resource-builder-link-dialog-save',
  resourceBuilderLinkDialogUrl = 'resource-builder-link-dialog-url',
  resourceBuilderLinkDialogBranchUrl = 'resource-builder-link-dialog-branchurl',
  resourceBuilderLinkDialogTitle = 'resource-builder-link-dialog-title',
  resourceBuilderAttachmentList = 'resource-builder-attachment-list',
  resourceBuilderCoverSelector = 'resource-builder-cover-selector',
  resourceBuilderSubjectSelector = 'resource-builder-subject-selector',
  resourceBuilderAgeSelector = 'resource-builder-age-selector',
  resourceBuilderContentSelector = 'resource-builder-content-selector',
  resourceBuilderDescriptionSelector = 'resource-builder-description-selector',
  resourceBuilderToolsSelector = 'resource-builder-tools-selector',
  resourceBuilderTimingSelector = 'resource-builder-timing-selector',
  resourceBuilderStudentStepsSelector = 'resource-builder-student-steps-selector',
  resourceBuilderKeywordsSelector = 'resource-builder-keywords-selector',
  resourceBuilderStandardsSelector = 'resource-builder-standards-selector',
  resourceBuilderLicensingSelector = 'resource-builder-licensing-selector',
  resourceBuilderPreviewBtn = 'resource-builder-preview',
  resourceBuilderReturnToEditBtn = 'resource-builder-return-to-edit',
  resourceBuilderSaveAndPublishBtn = 'resource-builder-publish',
  resourceBuilderSecondaryAuthorName = 'resource-builder-secondary-author-name',
  resourceBuilderSecondaryAuthorProfileURL = 'resource-builder-secondary-author-profile-url',
  publishedDialogEditBtn = 'published-dialog-edit',
  publishedDialogViewBtn = 'published-dialog-view',
  discardDialogPreviewBtn = 'discardDialogPreviewBtn',
  discardDialogContinueBtn = 'discardDialogContinueBtn',
  discardDialogDiscardBtn = 'discardDialogDiscardBtn',
  CoverImageDropZone = 'CoverImageDropZone',
  search = 'search',
  searchResults = 'search-results',
  desktopToolbar = 'desktop-toolbar',
  mobileToolbar = 'mobile-toolbar',
  preferencePodDialog = 'preference-pod-dialog',
  collectionCard = 'collection-card',
  resourceBuilderAddCCXLinkDialog = 'add-ccx-link-dialog',
  playlistView = 'playlist-view-page',
  playlistCreateBtn = 'create-playlist-btn',
  playlistSortBtn = 'playlist-sort-btn',
  playlistPreviewBtn = 'playlist-preview-btn',
  schoolAttributionDialog = 'school-attibution-dialog',
  liveSessionDialog = 'live-session-dialog',
}

export enum ResourceTypeI18nLabel {
  project = 'i18n.resourceType.Individual_Group_Project',
  supplemental = 'i18n.resourceType.Supplemental',
}

export const RESOURCE_TYPE_COLOR = new Map([
  [ResourceTypeI18nLabel.project, '#ce2783'],
  [ResourceTypeI18nLabel.supplemental, '#864ccc'],
]);

/***
 * see files.mimeType.count.csv.txt for the reference
 */
export enum EdexMimeType {
  jpeg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
  tiff = 'image/tiff',
  bmp = 'image/bmp',
  psd = 'image/vnd.adobe.photoshop',
  pdf = 'application/pdf',
  document = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  presentation = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  powerpoint = 'application/vnd.ms-powerpoint',
  slideshow = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  sheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  excel = 'application/vnd.ms-excel',
  video = 'video/mp4',
  msword = 'application/msword',
  ods = 'application/vnd.oasis.opendocument.spreadsheet',
  odt = 'application/vnd.oasis.opendocument.text',
  odp = 'application/vnd.oasis.opendocument.presentation',
}

export enum AcademicLevelID {
  allAges = '5f93600c-19c4-4799-a603-fe3913681237',
  earlyChildhood = '15952b70-f3a5-11ea-9b0b-0e061ef1479f',
  kindergarden_2ndGrade = '1cc2a518-f3a5-11ea-9b0b-0e061ef1479f',
  higherED = '35bc6c84-f3a5-11ea-9b0b-0e061ef1479f',
}

export enum FileTypeID {
  resourceFile = '0125C112-9746-416A-B9B1-69175A58CF9D',
  resourceFeaturedImage = '61e0e0f2-6a91-11e2-9f92-12313d026a8c',
}

export enum SubjectID {
  other = 'b3cd6d68-38ca-11eb-8335-0e485a9828bb',
  humanities = '8B960ABD-3872-455F-8AC4-17068388F09A',
  socialSciences = '649F9B1D-A5D3-4026-98F8-745C04EA0796',
  science = '0AA0D5E1-7669-4415-92B7-4961CA718C4C',
  digitalMedia = '65614F3E-217F-4C3C-A32E-805FBE121839',
  arts = 'B79ACB69-C7A2-4C4E-BDB1-41F2DD86CFCC',
  math = 'F8E1CBD6-6FBE-49C9-91AC-50C2BA082F1A',
}

export enum ProductID {
  photoshop = '31f8e1e7-a879-4eae-8149-0d4c980c2778',
  spark = '9cb258a2-5b8c-4e6b-a197-c8f5cec7c938',
  illustrator = 'c714eff0-aa52-437c-aed7-da1af1f986b0',
  rush = 'e0efd80f-9602-44d4-b3da-5c0361a7595e',
}

export enum ResourceBuilderPath {
  new = '/teaching-resources/new',
  edit = '/teaching-resources/edit',
}

export enum QueryParam {
  selectedIndex = 'selectedIndex',
  resourceEditID = 'id',
  previewItem = 'pi',
}

export enum ResourceBuilderMode {
  edit = 'edit',
  rdp = 'rdp',
  rpp = 'rpp',
}

export enum LocalEventType {
  adobeImsReady = 'adobeImsReady',
  notification = 'notification',
}

export const EDEX_LOCALE_LS_KEY = 'edexLocale';
export enum Locale {
  en = 'en',
  de = 'de',
  jp = 'jp',
}

export const LOCALES = Object.values(Locale);

export const IS_TEST = process.env.NODE_ENV === 'test';
export const IS_PROD = environment.production;

export const IS_ONETRUST_PROD = NX_ONETRUST_ENV === 'production';
export const ADOBE_ACCOUNT_URL = 'https://accounts.adobe.com/account';
export enum ViewMode {
  guest = 'guest',
  member = 'member',
  owner = 'owner',
  admin = 'admin',
}

export enum SubjectI18nLabel {
  all = 'i18n.All_Subjects',
}

export enum AcademicLevelI18nLabel {
  all = 'i18n.academicLevel.All_Ages',
}

export enum AdobeAnalyticsEvent {
  signin = 'auth.signin',
  signout = 'auth.signout',
  signup = 'auth.signup',
  mainNavSearch = 'nav.search',
  search = 'search',
  searchBanner = 'search.banner',
  resourceGet = 'resource.get',
  resourceDownload = 'resource.download',
  resourceOpenLink = 'resource.openlink',
  resourceOpenCCXTemplateLink = 'resource.openlink.ccxtemplate',
  resourceDownloadLocal = 'resource.download.local',
  resourceDownloadGoogle = 'resource.download.google',
  resourceDownloadOneDrive = 'resource.download.onedrive',
  resourceSeeAll = 'resource.seeall',
  resourceShare = 'resource.share',
  resourceComment = 'resource.comment',
  resourceRate = 'resource.rate',
  courseEnroll = 'course.enroll',
  dashboardEnrollInACourse = 'dashboard.enrollinacourse',
  courseShare = 'course.share',
  comment = 'comment',
  follow = 'member.follow',
  join = 'join',
  relatedResourceClick = 'related.resource.click',
  resourceSaveForLater = 'resource.saveForLater',
  resourceSaveForLaterAborted = 'resource.saveForLater.aborted',
  resourceViewGoogle = 'resource.view.google',
  resourceViewOneDrive = 'resource.view.onedrive',
  viewAllSelfPaced = 'course.viewall.selfpaced',
  viewAllToolkits = 'course.viewall.toolkits',
  editCustomisedLoggedIn = 'teachingresource.customised.edit.loggedin',
  editCustomisedLoggedOut = 'teachingresource.customised.edit.loggedout',
  seeMoreCustomised = 'teachingresource.customised.seeMore',
  preferencesGetResourcesClick = 'preferences.getResources.click',
  preferencesPodSelect = 'preferences.select',
  preferencesPodDeselect = 'preferences.deselect',
  preferencesSubjectSubmit = 'preferences.subject.submit',
  preferencesAcadSubmit = 'prefereces.acad.submit',
  productClick = 'product.click',
  personalisedResourceClick = 'personalised.resource.click',
  collectionCardClick = 'collection.card.click',
  personalisedResourceDownload = 'personalised.resource.download',
  resourceVideoPlay = 'resource.video.play',
  getFreeTrial = 'resource.click.getFreeTrial',
  resourceClickCCXTemplateLinkInline = 'resource.click.ccxTemplateLink.inline',
  seeAllResources = 'teachingresource.featured.click.seeAllResources',
  staticPageCCXTemplateLink = 'staticpage.click.ccxTemplateLink',
  staticPageCCXAcquisitionLink = 'staticpage.click.ccx.Acquisition',
  staticPageClickInstructions = 'staticpage.click.instructions',
  staticPageOpenLink = 'staticpage.click.otherLink',
  homePageCollectionClicked1 = 'home.collection1.clicked',
  homePageCollectionClicked2 = 'home.collection2.clicked',
  homePageCollectionClicked3 = 'home.collection3.clicked',
  homePageCollectionClicked4 = 'home.collection4.clicked',
  homeSeeAllCollectionsClicked = 'home.seeAllCollections.clicked',
  homeSearchFindResource = 'home.search.findResource',
  newMemberSignUp = 'new.member.signup',
  playlistShare = 'playlist.share',
  courseClickCCXTemplateLinkInline = 'course.click.ccxTemplateLink.inline',
  courseClickLinkInline = 'course.click.otherLink.inline',
  courseClickCCXAcquisition = 'course.click.ccx.Acquisition',
  courseEnrollClick = 'course.click.enroll',
  homeHeroBtnClicked = 'homepage.heroBanner.btn.clicked',
  studentSampleClick = 'homepage.studentSample.clicked',
  homeKeepExploreSubjectTag = 'homepage.keepExplore.subject.clicked',
  browseLibraryBtn = 'homepage.browseLibrary.clicked',
  courseLinkClick = 'homepage.pl.course.clicked',
  courseBrowseClick = 'homepage.pl.browse.button.clicked',
  homeExploreBtnClicked = 'homepage.explore.button.clicked',
  promoBannerBtnClicked = 'homepage.promoBanner.button.clicked',
  liveSessionCreated = 'course.newSession.success',
  liveSessionCreationFailed = 'course.newSession.failed',
}

export enum LoadingGifSize {
  small = 'small',
  large = 'large',
  medium = '',
}

export enum CourseTypeID {
  selfPaced = '614be368-11d7-45fe-b11a-8619ec959388',
  instructorLed = '4b61b658-8808-4bca-bb8a-679039beb070',
  toolkit = 'cae5c8c6-b56c-4d16-80b5-8bf51bdc9119',
  nonSearchableInstructorLed = '9903e26a-2cff-11ed-b197-9e457ca1b39b',
}

export enum CourseTypeRoutePath {
  selfPacedCourse = 'self-paced-course',
  instructorLedCourse = 'instructor-led-course',
  toolkit = 'toolkit',
  nonSearchableInstructorLed = 'live-instructor-led-course',
}

export const COURSE_TYPE_ID_TO_PATH = new Map([
  [CourseTypeID.selfPaced, CourseTypeRoutePath.selfPacedCourse],
  [CourseTypeID.instructorLed, CourseTypeRoutePath.instructorLedCourse],
  [CourseTypeID.toolkit, CourseTypeRoutePath.toolkit],
  [CourseTypeID.nonSearchableInstructorLed, CourseTypeRoutePath.nonSearchableInstructorLed],
]);

export const COURSE_TYPE_ID_TO_TYPE = new Map([
  [CourseTypeID.selfPaced, CourseType.selfPaced],
  [CourseTypeID.instructorLed, CourseType.instructorLed],
  [CourseTypeID.toolkit, CourseType.toolkit],
  [CourseTypeID.nonSearchableInstructorLed, CourseType.nonSearchableInstructorLed],
]);

export enum CourseTypeIDMapping {
  '614be368-11d7-45fe-b11a-8619ec959388' = 'i18n.professionalLearning.type.selfPaced',
  '4b61b658-8808-4bca-bb8a-679039beb070' = 'i18n.professionalLearning.type.instructorLed',
  'cae5c8c6-b56c-4d16-80b5-8bf51bdc9119' = 'i18n.professionalLearning.type.toolkit',
  '9903e26a-2cff-11ed-b197-9e457ca1b39b' = 'i18n.professionalLearning.type.nonSearchableInstructorLed',
}

export enum CourseTypeI18nLabel {
  instructorLed = 'i18n.instructorLed',
  selfPaced = 'i18n.selfPaced',
  toolkit = 'i18n.toolkit',
}

export enum RoutePath {
  landing = '/',
  teachingResources = '/teaching-resources',
  professionalLearning = '/professional-learning',
  community = '/community',
  member = '/community/member',
  discussion = '/community/discussion',
  search = '/search',
  dashboard = '/dashboard',
  teaching = '/dashboard/teaching',
  learning = '/dashboard/learning',
  playlists = '/dashboard/playlists',
  helpCenter = '/help-center',
  accountSettings = '/account/settings',
  teachingResourceNav = '/explore-teaching-resources',
  professionalLearningNav = '/explore-professional-learning',
  bts = '/bts',
  externalCourses = '/dashboard/external-courses',
}

export const VALID_PAGE_NUMBERS = new RegExp('^\\d*$');

export const DEFAULT_DOCUMENT_TITLE = 'Adobe Education Exchange';

export enum ResourceBuilderFocus {
  coverImage = 'coverImage',
  title = 'title',
  seconndaryAuthorName = 'seconndaryAuthorName',
  secondaryAuthorProfileURL = 'secondaryAuthorProfileURL',
  shortDescription = 'shortDescription',
  subjectAndAge = 'subjectAndAge',
  content = 'content',
  description = 'description',
  tools = 'tools',
  time = 'time',
  keywords = 'keywords',
  standards = 'standards',
  licensing = 'licensing',
  studentSteps = 'studentSteps',
}

export enum DialogHeadingClassName {
  exportDialog = 'export-dialog',
  default = '',
}

export enum CourseStatus {
  enrolled = 'Enrolled',
  closed = 'Course Closed',
  inProgress = 'In Progress',
  pendingReview = 'Coursework Under Review',
  complete = 'Complete',
}

export enum ResourceComponent {
  template = '50eb8e65-34e9-11eb-8335-0e485a9828bb',
}

// 301 redirect route
export const RedirectRoutePath = new Map<string, string>([
  ['/khan-create', '/khan'],
  ['/aca', '/adobe-certified-professional'],
  ['/aca/prep', '/adobe-certified-professional'],
  ['/aca/whycertify', '/adobe-certified-professional'],
  ['/aca/faq', '/adobe-certified-professional'],
  ['/aca/objectives', '/adobe-certified-professional/exams'],
  ['/aca/objectives/photoshop', '/teaching-resources/v97ccf4fe'],
  ['/aca/objectives/illustrator', '/teaching-resources/50a8dd9f '],
  ['/aca/objectives/indesign', '/teaching-resources/v042df592'],
  ['/aca/objectives/premierepro', '/teaching-resources/vef6389e5'],
  ['/aca/objectives/after-effects', '/teaching-resources/vc31d4dfc'],
  ['/aca/objectives/animate', '/teaching-resources/v38553d4b'],
  ['/aca/objectives/dreamweaver', '/teaching-resources/5259a10c'],
  ['/spark', '/express'],
]);

export const ProfessionalLearningRedirectRoute = new Map<string, string>([
  ['/spark-in-the-classroom', '/creative-cloud-express-in-the-classroom'],
  ['/get-creative-flipped-learning-for-your-classroom', '/flipped-learning-for-your-classroom'],
  ['/get-started-augment-reality-with-your-students', '/augment-reality-with-your-students'],
  ['/get-started-with-publishing', '/digital-publishing-with-your-students'],
  ['/get-started-with-graphics', '/design-graphics-with-your-students'],
  ['/get-started-with-imaging', '/create-digital-images-with-your-class'],
  ['/get-started-with-ux-design', '/design-fun-apps-with-your-students'],
  ['/make-impactful-videos-for-social-media', '/make-impactful-social-videos-with-your-students'],
]);

export enum PREFERENCE_TYPE {
  subject = 'subjects',
  academicLevel = 'academicLevels',
  product = 'product',
}

export const CCX_REGULAR_TEMPLATE_URL = [
  'https://express.adobe.com/sp/design/post/',
  'https://express-stage.adobeprojectm.com/sp/design/post/',
];
export const CCX_POST_REMIX_TEMPLATE_URL = [
  'https://express.adobe.com/post',
  'https://express-stage.adobeprojectm.com/post',
];
export const RESOURCE_INSTRUCTIONS_URL = 'https://cdn.edex.adobe.com/v3/uploads/resourceFile/';
export const MEMBER_PROFILE_URL = NX_UI_URL + '/community/member';
export const CCX_POST_PROJECT_URL = 'sp/design/post/urn';
export const SEEALLRESOURCES_HOMEPAGE_ACTIONNAME = 'see all resources (homepage)';
export const CCX_POST_REMIX_DIRECT_LINK = '/sp/design-remix/post';

export const ENGLISH_PERSONALISED_I18N = 'i18n.subjects.English.personalised';
export const ENGLISH_I18N = 'i18n.subjects.English';

export const ENGLISH_SUBJECT: SubjectSchema = {
  createdAt: '2013-02-16T21:33:16.000Z',
  updatedAt: null,
  id: '6D875091-AE57-4713-ADFF-4D50F5D52F45',
  parentID: '8B960ABD-3872-455F-8AC4-17068388F09A',
  i18nLabel: ENGLISH_I18N,
  urlLabel: 'English',
  imageURL: 'subject-english.png',
  status: EntityStatus.active,
};
export const CCX_PROJECT_LINK_ANALYTICS_IDENTIFIER = 'CCX Project Link';

export const ADOBE_FOR_EDU = {
  id: '82303c61-6102-11e2-9a53-12313b016471',
  i18nLabel: 'Adobe For Education',
};

export const ADOBE_FOR_EDU_JP = {
  id: 'fc7ab580-ed0c-4f70-8aa3-513eca263d28',
  i18nLabel: 'アドビ教育 コーディネーター',
};

export enum MY_EDEX_RESOURCES_TABS {
  MY_SAVED = 'mySaved',
  CREATED_BY_ME = 'createdByMe',
}

export enum MY_EDEX_LEARNING_TABS {
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  ACTIVE = 'active',
}

export const MAX_LIMIT_COUNT = 200;
export const EXTERNAL_COURSE_MAX_LIMIT_COUNT = 30;

export enum FsBookmarkAction {
  save = 'save',
  unsave = 'unsave',
}

export const MY_EDEX_TAB_TO_PATH = {
  mySaved: 'my-saved',
  createdByMe: 'created-by-me',
  inProgress: 'in-progress',
  completed: 'completed',
  active: 'active',
};

export const MY_EDEX_PATH_TO_TAB = {
  'my-saved': MY_EDEX_RESOURCES_TABS.MY_SAVED,
  'created-by-me': MY_EDEX_RESOURCES_TABS.CREATED_BY_ME,
  'in-progress': MY_EDEX_LEARNING_TABS.IN_PROGRESS,
  completed: MY_EDEX_LEARNING_TABS.COMPLETED,
  active: MY_EDEX_LEARNING_TABS.ACTIVE,
};

export enum TARGET_TRIGGER_VIEW {
  teachingResources = 'TeachingResources',
  professionalLearning = 'ProfessionalLearning',
  searchPage = 'SearchPage',
  resourcePreview = 'ResourcePreview',
  header = 'Header',
  courseAbout = 'CourseAbout',
}

export const RESOURCE_COMPONENT_LABELS = {
  '50eb8d87-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.Lesson_Plan',
  '50eb8e24-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.Project_instructions',
  '50eb8e37-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.Sample',
  '50eb8e46-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.Assessment',
  '50eb8e56-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.Tutorial',
  '50eb8e65-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.ExpressTemplate',
  '50eb8e75-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.Other',
  '50eb8e76-34e9-11eb-8335-0e485a9828bb': 'i18n.resourceComponent.TemplateOther',
};

export const COPY_PROJECT_TIMEOUT = 120000;

export const BOOST_VALUES = [0, -1, 0.5, 1, 2, 3, 4, 5];

export const CREDLY_DOWNLOAD_CERT_URL = 'https://www.credly.com/users/sign_in';

export const MAX_SELECTED_STUDENT_STEPS = 4;
export const MIN_SELECTED_STUDENT_STEPS = 3;

export enum MY_EDEX_EXTERNAL_COURSE_TABS {
  createdByMe = 'createdByMe',
  staffAssignedCourses = 'staffAssignedCourses',
  active = 'active',
  archived = 'archived',
}

export const EXTERNAL_COURSE_HERO_IMAGE_URL = `${NX_CDN_HOST}/externalCourseImages`;

export const SCHOOL_ATTRIBUTION = 'schoolAttributionDialog';
export const SURVEY_CONTENT_ACQUIRED_COOKIE_KEY = 'contentAcquired';
export const SCHOOL_DISTRICT_OTHER_TITLE = "OTHER: MY DISTRICT ISN'T LISTED";
export enum SCHOOL_DISTRICTS {
  other = 'f6e40a6d-c3fa-11ee-aeea-0eb0dcf3b623',
}
