import { changePreferences, fetchPreferences, savePreferences } from '@adobe/edex/ui/store/routines';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';

export interface PreferenceState {
  loading: boolean;
  subjects: Array<string>;
  academicLevels: Array<string>;
  resources: Record<string, any>;
}

export function preferences(
  state: PreferenceState = {
    subjects: [],
    academicLevels: [],
    resources: {},
    loading: false,
  },
  action: EdexAction<Record<string, any>>
): PreferenceState {
  switch (action.type) {
    case fetchPreferences.TRIGGER: {
      return {
        ...state,
        loading: true,
      };
    }
    case fetchPreferences.SUCCESS: {
      return {
        subjects: action.payload.preference.sb,
        academicLevels: action.payload.preference.ag,
        resources: action.payload.preferenceResult,
        loading: false,
      };
    }
    case fetchPreferences.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case savePreferences.TRIGGER: {
      return {
        ...state,
        subjects: action.payload.subjects,
        academicLevels: action.payload.academicLevels,
        loading: true,
      };
    }
    case savePreferences.SUCCESS: {
      return {
        ...state,
        resources: action.payload.preferenceResult,
      };
    }
    case savePreferences.FAILURE: {
      return { ...state, loading: false };
    }
    case savePreferences.FULFILL: {
      return {
        ...state,
        loading: false,
      };
    }
    case changePreferences.SUCCESS: {
      return {
        ...state,
        subjects: action.payload.subjects,
        academicLevels: action.payload.academicLevels,
      };
    }
    default:
      return state;
  }
}
