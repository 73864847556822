import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import * as reducers from './reducers';
import * as sagas from './sagas';
import { IS_PROD, IS_TEST } from '@adobe/edex/ui/constants';
import { router, ROUTER_LOCATION_CHANGED_EVENT } from '@adobe/edex/ui/config/router';
import { routerLocationChanged } from '@adobe/edex/ui/store/routines';

const rootReducer = combineReducers(reducers);

export type EdexState = ReturnType<typeof rootReducer>;

export const store = (() => {
  const sagaMiddleware = createSagaMiddleware();

  const enhancers = [applyMiddleware(sagaMiddleware) as any];
  if (!(IS_PROD || IS_TEST)) {
    enhancers.push(applyMiddleware(createLogger({ collapsed: true })) as any);
  }
  const store = createStore(rootReducer, {}, composeWithDevTools(...enhancers));

  Object.values(sagas).forEach((saga) => {
    sagaMiddleware.run(saga);
  });

  window.addEventListener(ROUTER_LOCATION_CHANGED_EVENT, () => {
    store.dispatch(routerLocationChanged.success(router.location));
  });

  return store;
})();
