import * as uuid from 'uuid';
import { isEmpty, last } from 'lodash-es';
import compact from 'lodash-es/compact';
import urlRegexSafe from 'url-regex-safe';
import * as Cookies from 'tiny-cookie';
import {
  CCXTemplateLinkValidity,
  MarshalledResource,
  PrimarySecondary,
  ProductHelpfulInfo,
  ProductSchema,
} from '@adobe/edex/ui/shared/types';
import DOMPurify from 'dompurify';
import {
  CDN_META_IMAGES,
  DEFAULT_AVATAR,
  DEFAULT_DOCUMENT_TITLE,
  IS_TEST,
  CourseStatus,
  CCX_REGULAR_TEMPLATE_URL,
  CCX_POST_REMIX_TEMPLATE_URL,
  CCX_POST_PROJECT_URL,
  ResourceComponent,
  RESOURCE_INSTRUCTIONS_URL,
  MEMBER_PROFILE_URL,
  SCHOOL_DISTRICTS,
  SCHOOL_DISTRICT_OTHER_TITLE,
} from '@adobe/edex/ui/shared/constants';
import { EdexMetaTag, EdexSEO } from '@adobe/edex/ui/shared/types/ui';
import { getResizedImageURL } from '@adobe/edex/ui/shared/utils/imageResize';
import { cdn } from '@adobe/edex/ui/shared/utils/cdn';
import { EnrollmentMeta } from '@adobe/edex/ui/types';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { getTranslation } from '@adobe/edex/ui/shared/config/i18n';
import { entitlementsAPI } from '@adobe/edex/ui/api';
import { SCHOOL_ATTRIBUTION_KEY, prodToFI } from '@adobe/edex/ui/constants';
import { getQueryParam } from './routes';

export function stripHTML(source: string) {
  return DOMPurify.sanitize(source, {
    ALLOWED_ATTR: [],
    ALLOWED_TAGS: [],
  });
}

export const generateUUID = () => uuid.v4();

export const IMS_ACCESS_TOKEN_PREFIX = '#access_token';

export const mergePrimarySecondary = <T>({ primary, secondary }: PrimarySecondary<T>): T[] =>
  compact([primary, ...secondary]);

export const getResourceAssetCount = ({ files, links }: Partial<MarshalledResource>): number =>
  files.length + (links?.video?.length || 0) + (links?.web?.length || 0);

export const shorten = (val: string, len: number) => (val.length > len ? `${val.slice(0, len)}...` : val);

export const stripAndShortenHTML = (val: string, len: number) => shorten(stripHTML(val), len);

export function getResourceShortDescription({ description, shortDescription }: MarshalledResource) {
  return (
    shortDescription ||
    // existing resources do not have shortDescription, use converted description instead
    replaceHtmlEntities(description)
  );
}

export function getShortenedResourceShortDescription(description: string, shortDescription: string, length: number) {
  return shorten(shortDescription, length) || shorten(replaceHtmlEntities(description), length);
}

export const replaceHtmlEntities = (description: string) => {
  const translate_re = /&(nbsp|amp);/g;
  const translate = {
    nbsp: ' ',
    amp: '&',
  };
  return stripHTML(description).replace(translate_re, (_, entity) => {
    return translate[entity];
  });
};

/**
 * Show helpful info when primary product is defined OR
 * when there's 1 product total
 */
export const getResourceHelpfulInfo = ({
  products: { primary, secondary },
}: Pick<MarshalledResource, 'products'>): ProductHelpfulInfo[] => {
  const product = primary || (secondary && secondary.length === 1 ? secondary[0] : null);
  if (!product || !product.helpfulInfo) {
    return [];
  }
  return product.helpfulInfo.filter(({ imageURL, title }) => !!(imageURL && title));
};

export const delay = (timeout = 0) => new Promise((resolve) => setTimeout(resolve, timeout));

export function buildPath({
  pathname = location.pathname,
  search = location.search,
  hash = location.hash,
}: Partial<Location> = location) {
  if (hash?.startsWith(IMS_ACCESS_TOKEN_PREFIX)) {
    hash = '';
  }
  return [pathname, search ? `?${search.replace(/^\?/, '')}` : '', hash ? `#${hash.replace(/^#/, '')}` : ''].join('');
}

export const unsafeHTMLtext = (html: string) => {
  if (!html) {
    return html;
  }
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent;
};

export const formatDocumentTitle = (title: string) => `${title} | Adobe Education Exchange`;

export const formatDocumentTitleAsSuffix = (title: string) => `Adobe Education Exchange | ${title}`;

export const getMetaTagImageURL = () => `${cdn}/${CDN_META_IMAGES}/AdobeEdEx_OGImages.png`;

export const getPlaylistImageURL = () => `${cdn}/${CDN_META_IMAGES}/Playlist_OGImage.png`;

export const containsURL = (param: string) => {
  const regex = new RegExp(
    '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
  );
  // returns true if param contain URL
  return regex.test(param);
};

export const getDomainOfURL = (url: string) => {
  const { hostname } = new URL(url);
  return hostname.replace('www.', '');
};

export function processSEO(seo: EdexSEO) {
  if (!seo) {
    return;
  }
  const { title, meta, canonicalURL } = seo;

  document.title = title;

  meta?.forEach((item) => {
    const existing = getMetaTag(item);
    const metaElement = existing || document.createElement('meta');
    Object.entries(item).forEach(([key, value]) => metaElement.setAttribute(key, value || ''));
    if (!existing) {
      document.head.appendChild(metaElement);
    }
  });

  if (canonicalURL) {
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canonicalURL);
    document.head.appendChild(link);
  }
}

export function resetSEO(seo: EdexSEO) {
  if (!seo) {
    return;
  }
  const { meta, canonicalURL } = seo;
  document.title = DEFAULT_DOCUMENT_TITLE;
  meta?.forEach((item) => {
    getMetaTag(item)?.remove();
  });
  if (canonicalURL) {
    document.querySelector('link[rel="canonical"]')?.remove();
  }
}

export const getAssetURL = (name: string) => `/ui/assets/${name}`;

export const getExtension = (src: string): string => last((src || '').split('.'));

export const getAvatarURL = (avatarURL: string) => {
  if (!avatarURL || avatarURL.includes('no-image-138.png')) {
    return DEFAULT_AVATAR;
  } else {
    return getResizedImageURL(avatarURL, 100, 100);
  }
};

export const getProductIconURL = (product: ProductSchema) => product.iconProps?.[2]?.url;

export function getMetaTag({ property, name }: EdexMetaTag): HTMLMetaElement {
  if (property) {
    return document.head.querySelector(`meta[property="${property}"]`);
  } else if (name) {
    return document.head.querySelector(`meta[name="${name}"]`);
  } else {
    return null;
  }
}

export const isValidURL = (url: string): boolean => urlRegexSafe().test(url) && !url.includes(CCX_POST_PROJECT_URL);

export const isValidMemberProfileURl = (url: string): boolean => {
  return urlRegexSafe().test(url) && url.includes(MEMBER_PROFILE_URL);
};

export function noticeError(err: Error) {
  if (IS_TEST) {
    return;
  }
  console.error(err);
}

export const getVariant = (status: CourseStatus) => {
  const variantMap = new Map([
    [CourseStatus.enrolled, 'info'],
    [CourseStatus.closed, 'neutral'],
    [CourseStatus.inProgress, 'notice'],
    [CourseStatus.complete, 'positive'],
    [CourseStatus.pendingReview, 'notice'],
  ]);
  return variantMap.get(status);
};

export const getTranslatedCourseStatus = (status: CourseStatus) => {
  const translatedMap = new Map([
    [CourseStatus.enrolled, I18nKey.enrolled],
    [CourseStatus.closed, I18nKey.courseClosed],
    [CourseStatus.inProgress, I18nKey.inProgress],
    [CourseStatus.complete, I18nKey.complete],
    [CourseStatus.pendingReview, I18nKey.pendingReview],
  ]);

  return getTranslation(translatedMap.get(status));
};

export const getCourseStatus = (enrollment: EnrollmentMeta) => {
  let status: CourseStatus;
  if (enrollment.enrolled) {
    status = CourseStatus.enrolled;
  } else if (enrollment.removed) {
    status = CourseStatus.closed;
  } else if (enrollment.started) {
    status = CourseStatus.inProgress;
  } else if (enrollment.passed) {
    status = CourseStatus.complete;
  } else {
    status = CourseStatus.pendingReview;
  }

  return status;
};

// Only support "post" type CCX links for those added via Ph-1 author flow
export const ccxTemplatePatternMatchingPh1 = (url: string) => {
  return (
    url &&
    /^(https:\/\/)(express.adobe|express-stage.adobeprojectm).com(\/sp\/design\/post\/(template\/)|\/post\/|\/sp\/remix\/post\/)([a-zA-Z0-9-]+)((\/)?)($|:\d\d\d\d)/.test(
      url
    )
  );
};

export const ccxTemplatePatternMatching = (url: string) => {
  return (
    url &&
    /^(https:\/\/)(express.adobe|express-stage.adobeprojectm).com(\/sp\/design\/post\/(template\/)|\/sp\/remix\/post\/|\/sp\/remix\/video\/|\/sp\/remix\/page\/|\/post\/|\/video\/|\/page\/)([a-zA-Z0-9-]+)((\/embed)?)((\/)?)($|:\d\d\d\d)/.test(
      url
    )
  );
};

export const projectXPageTemplatePatternMatching = (url: string) => {
  return (
    url &&
    /^(https:\/\/)(stage.projectx.corp.adobe|new.express.adobe|38707.prenv.projectx.corp.adobe).com(\/webpage\/)([a-zA-Z0-9--:]+)((\/)?[?&]?([^=]+)(=([^&#]*))?)$/.test(
      url
    )
  );
};

export const isProjectXLinkURLFormatValid = (url: string) => {
  return (
    url &&
    /^(https:\/\/)(stage.projectx.corp.adobe|new.express.adobe).com(\/x\/design-remix\/template\/|\/design\/template\/|\/x\/templates\/|\/design-remix\/template\/)(urn:aaid:sc:)([a-zA-Z0-9--:]+)((\/)?[?&]?([^=]+)(=([^&#]*))?)$/.test(
      url
    )
  );
};

export const isResourceContentAttachment = (url: string): boolean => url.startsWith(RESOURCE_INSTRUCTIONS_URL);

export const isCCXTemplateOrProjectUrl = (ccxValidityItems: CCXTemplateLinkValidity): boolean => {
  const {
    url,
    components,
    isLinkFromAssetBrowser,
    isProjectXRemixLink,
    isLinkFromProjectXAssetBrowser,
  } = ccxValidityItems;
  return isCCXTemplateResourceComponent(components)
    ? (isLinkFromAssetBrowser || isLinkFromProjectXAssetBrowser
        ? ccxTemplatePatternMatching(url) || projectXPageTemplatePatternMatching(url)
        : ccxTemplatePatternMatchingPh1(url)) || isProjectXRemixLink
    : false;
};

export const isCCXTemplateUrl = (ccxValidityItems: CCXTemplateLinkValidity): boolean => {
  const { url, components } = ccxValidityItems;
  return ccxTemplatePatternMatching(url) && isCCXTemplateResourceComponent(components);
};

// Only support "post" type CCX links for those added via Ph-1 author flow
export const isCCXTemplateUrlPh1 = (ccxValidityItems: CCXTemplateLinkValidity): boolean => {
  const { url, components } = ccxValidityItems;
  return ccxTemplatePatternMatchingPh1(url) && isCCXTemplateResourceComponent(components);
};

export const isCCXRegularTemplateType = (url: string) => {
  return url && CCX_REGULAR_TEMPLATE_URL.some((format) => url.includes(format));
};

export const isCCXPostRemixTemplateType = (url: string) => {
  return url && CCX_POST_REMIX_TEMPLATE_URL.some((format) => url.includes(format));
};

export const isEntitled = async (product: string) => {
  // for the response, see https://wiki.corp.adobe.com/pages/viewpage.action?pageId=2503017162
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entitlementsResponse: any = await entitlementsAPI.get<{ entitlements: any[] }>('/').catch(() => {
    return false;
  });
  const entitlements = entitlementsResponse?.data;

  if (!entitlements || !entitlements?.entitlements) {
    return false;
  }
  // see sample response:
  // https://wiki.corp.adobe.com/pages/viewpage.action?pageId=2503017162#EMSGetEntitlementsAPISpecification-SampleResponse
  /*
  Varahamurthy Jutur  :
  User is considered not entitled in all the following cases
    FI missing
    FI present but disabled
    FI present and enabled but it is part of a license/entitlement whose status is not ACTIVE
  In other words, if an FI is enabled in an ACTIVE entitlement,
  user is considered entitled to use the value provided by that FI, otherwise not
*/
  for (const e of entitlements?.entitlements) {
    const prodKey = prodToFI[product] || product;
    const prod = e.fulfillable_items?.[prodKey];
    if (e.status !== 'ACTIVE' || !prod) {
      continue;
    }
    return prod.enabled === true;
  }
  return false;
};

export const fetchProfileStorage = () => {
  const storageObject =
    window?.adobeIMS?.profileService?.storage[
      'adobeid_ims_access_token/edexchange2/false/AdobeID,creative_cloud,creative_sdk,openid,sao.ACOM_CLOUD_STORAGE'
    ];
  return storageObject ? JSON.parse(storageObject) : null;
};

export const fetchUserDetails = () => {
  try {
    const imsObj = fetchProfileStorage();
    const USER_ID = imsObj?.get('user_id');
    const SID = imsObj?.get('sid');
    return {
      USER_ID,
      SID,
    };
  } catch (e) {
    return { USER_ID: null, SID: null };
  }
};

// https://jira.corp.adobe.com/browse/CCEDU-4912 - set context if user came from express
export const appendSourceInfo = (eventAction: string, source: string): string => {
  try {
    return source.length > 0 ? `${eventAction} | navigatedFrom:${source}` : eventAction;
  } catch (e) {
    // to avoid any failure in Analytics due to addSourceInfoToPayload
    return eventAction;
  }
};

// https://jira.corp.adobe.com/browse/CCEDU-4912 - set context if user came from express
export const addSourceInfoToPayload = (payload, source) => {
  try {
    return {
      ...payload,
      ...(source.length > 0 && { source }),
    };
  } catch (e) {
    // to avoid any failure in Analytics due to addSourceInfoToPayload
    return payload;
  }
};

// https://jira.corp.adobe.com/browse/CCEDU-4912 - set context if user came from express
export const getNavSource = () => {
  try {
    const source = getQueryParam('src');
    return source?.length > 0 ? source : '';
  } catch (e) {
    return '';
  }
};

export const isCCXAcquisitionLink = (url: string) => {
  return (
    url &&
    (/^(https:\/\/)(express.adobe|express-stage.adobeprojectm|express-stage.adobe).com/.test(url) ||
      /^(https:\/\/)adobesparkpost.app.link/.test(url) ||
      isProjectXLinkURLFormatValid(url))
  );
};

export const isCCXTemplateResourceComponent = (components: string[]) => {
  return components && components.includes(ResourceComponent.template);
};

export const isEduUser = async () => {
  try {
    const tags: string[] = (await window.adobeIMS?.getProfile())?.tags;
    return tags.some((tag) => tag.includes('edu'));
  } catch (e) {
    return false;
  }
};

export async function trackTargetEvent(tagName) {
  try {
    await window.adobe?.target?.trackEvent({ mbox: tagName });
  } catch (ex) {
    return;
  }
}

export const isMobileDevice = () => {
  return (
    (window.navigator.userAgent.match(/iPhone|iPad|iPod/i) || window.navigator.userAgent.match(/Android/i)) !== null
  );
};

/*
 * Please note navigator.share will return true only if the browser supports native share irrespective of responsive mode.
 */
export const isNativeShareApplicable = () => {
  return 'share' in navigator && window.matchMedia('(max-width: 600px)').matches && isMobileDevice();
};

export const isSmallerMobileScreen = () => {
  return window.matchMedia('(max-width: 340px)').matches;
};

export const showExternalCourseTAB = (
  isAdmin: boolean,
  isShellCourseAdmin: boolean,
  isShellCourseAssignmentReviewer: boolean
): boolean => {
  return isAdmin || isShellCourseAdmin || isShellCourseAssignmentReviewer;
};
export function setSurveyCookie(name: string, value, options) {
  Cookies.setCookie(name, value, options);
}

export function setSurveyCookieForNoCAParams(key: string) {
  setSurveyCookie(key, true, { expires: 2 });
}

export function isSchoolDistrictAttributionDisabled() {
  return sessionStorage.getItem(SCHOOL_ATTRIBUTION_KEY) === 'disabled';
}

export function handleTextChangeInSchoolDistrictSelector(searchText, schoolDistricts) {
  let searchResults = [];
  if (!isEmpty(searchText)) {
    if (isNaN(searchText)) {
      // Input is text, search by name
      searchResults = schoolDistricts
        .filter((schoolDistrict) => schoolDistrict.districtName.toUpperCase().startsWith(searchText.toUpperCase()))
        .map((schoolDistrict) => ({
          id: schoolDistrict.id,
          title: `${schoolDistrict.districtName} (${schoolDistrict.ncesID})`,
        }));
    } else {
      // Input is number, search by ncesid
      searchResults = schoolDistricts
        .filter((schoolDistrict) => schoolDistrict.ncesID.startsWith(searchText))
        .map((schoolDistrict) => ({
          id: schoolDistrict.id,
          title: `${schoolDistrict.districtName} (${schoolDistrict.ncesID})`,
        }));
    }
    searchResults.push({ id: SCHOOL_DISTRICTS.other, title: SCHOOL_DISTRICT_OTHER_TITLE });
  }
  return searchResults;
}
