import { CourseTypeSchema } from './schemas';

export enum NotificationVariant {
  positive = 'positive',
  negative = 'negative',
  bookmark = 'bookmark',
}

export enum NotificationPlacement {
  bottomCenter = 'bottomCenter',
  topRight = 'topRight',
}

export interface CourseCopyNotificationParams {
  name: string;
  vanityURL: string;
  type: CourseTypeSchema;
}

export interface NotificationParams {
  message: string;
  variant: NotificationVariant;
  placement: NotificationPlacement;
  custom?: CustomNotificationVariant;
  itemId?: string;
  playlistNames?: string[];
  courses?: CourseCopyNotificationParams[];
}

export interface SaveNotificationParams {
  itemId: string;
  open: boolean;
}

export enum CustomNotificationVariant {
  bookmark = 'bookmark',
}
