import { closeDialog, openDialog } from '@adobe/edex/ui/store/routines';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { DialogState } from '@adobe/edex/ui/types';

export function dialog(state = null as DialogState, action: EdexAction<DialogState>): DialogState {
  switch (action.type) {
    case openDialog.TRIGGER:
      return {
        payload: {},
        ...action.payload,
      };
    case closeDialog.TRIGGER:
      return null;
    default:
      return state;
  }
}
