import { takeLatest, takeLeading } from 'redux-saga/effects';
import { fetchSelf, updateSchoolDistrict } from '@adobe/edex/ui/store/routines';
import { v3requestRoutine } from '@adobe/edex/ui/store/sagas/helpers';
import { selfAPI } from '@adobe/edex/ui/api';
import { EdexAction } from '../reducers/helpers';
import { IdActionPayload } from '../../types/payload';

function* fetchSelfWorker() {
  yield* v3requestRoutine({
    routine: fetchSelf,
    api: selfAPI,
    config: {
      method: 'GET',
      url: '',
    },
  });
}

function* updateSchooolDistrictWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: updateSchoolDistrict,
    api: selfAPI,
    config: {
      method: 'PUT',
      url: '/school-district',
      data: { id: action.payload.id },
    },
  });
}

export function* fetchSelfWatcher() {
  yield takeLeading(fetchSelf.TRIGGER, fetchSelfWorker);
}

export function* updateSchooolDistrictWatcher() {
  yield takeLatest(updateSchoolDistrict.TRIGGER, updateSchooolDistrictWorker);
}
