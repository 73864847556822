import { memoize } from 'lodash-es';
import { PrimarySecondary } from '@adobe/edex/ui/shared/types';

interface BaseMetadataItem {
  id: string;
  children?: any[];
}
export interface MetadataItem {
  id: string;
  i18nLabel: string;
}

export const indexMetadataByID = memoize(
  <T extends BaseMetadataItem>(items: T[]): Map<string, T> =>
    new Map(items?.flatMap((item) => [item, ...(item.children || [])]).map((item) => [item.id, item]))
);

export const indexMetadataByLabel = memoize(
  <T extends MetadataItem>(items: T[]): Map<string, T> => new Map(items.map((item) => [item.i18nLabel, item]))
);

export const getById = <T extends BaseMetadataItem>(id: string, metadata: T[] = []): T =>
  indexMetadataByID(metadata).get(id);

export const getByIds = <T extends BaseMetadataItem>(ids: string[], metadata: T[]): T[] => {
  const metadataByID = indexMetadataByID(metadata);
  return ids.map((id) => metadataByID.get(id));
};

export const marshalPrimarySecondary = <T extends { id: string }>(
  { primary, secondary }: PrimarySecondary,
  metadata: T[]
): PrimarySecondary<T> => {
  return {
    primary: indexMetadataByID(metadata).get(primary),
    secondary: getByIds(secondary, metadata),
  };
};

export const extractPrimarySecondaryIds = (marshalled: PrimarySecondary<{ id: string }>, adjustPrimary = false) => {
  let primary = marshalled.primary?.id || null;
  let secondary = marshalled.secondary.map(({ id }) => id);
  if (adjustPrimary && !primary && secondary.length === 1) {
    [[primary], secondary] = [secondary, []];
  }
  return {
    primary,
    secondary,
  };
};
