import { AnyAction } from 'redux';
import {
  fetchComments,
  createComment,
  deleteComment,
  updateComment,
  reportComment,
  unReportComment,
  fetchResource,
} from '@adobe/edex/ui/store/routines';
import { insertComment, replaceComment, removeComment, handleReport } from '@adobe/edex/ui/utils/comments';
import { MarshalledComment } from '@adobe/edex/ui/types';
import { PaginatedRecords } from '@adobe/edex/ui/types/misc';

export type CommentsState = PaginatedRecords<MarshalledComment> & {
  loading: boolean;
  error: boolean;
};

export const DEFAULT_COMMENTS_STATE: CommentsState = Object.freeze({
  data: null,
  meta: {
    limit: 0,
    offset: 0,
    totalRecords: 0,
  },
  loading: false,
  error: false,
});

export function comments(state = DEFAULT_COMMENTS_STATE, action: AnyAction): CommentsState {
  switch (action.type) {
    case fetchResource.TRIGGER:
      return DEFAULT_COMMENTS_STATE;
    case fetchComments.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case deleteComment.TRIGGER:
      return removeComment(state, action.payload.id);
    case reportComment.TRIGGER:
    case unReportComment.TRIGGER:
      return {
        ...state,
        data: handleReport(state.data, action.payload.id),
      };
    case createComment.SUCCESS:
      return {
        ...state,
        data: insertComment(state.data, action.payload),
        meta: {
          ...state.meta,
          totalRecords: state.meta.totalRecords + 1,
        },
      };
    case updateComment.SUCCESS:
      return {
        ...state,
        data: replaceComment(state.data, action.payload),
      };
    case fetchComments.SUCCESS:
      return {
        ...state,
        data: action.payload.meta.offset > 0 ? [...state.data, ...action.payload.data] : action.payload.data,
        meta: action.payload.meta,
      };
    case fetchComments.FAILURE:
      return { ...state, error: true };
    case fetchComments.FULFILL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
