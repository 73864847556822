import { takeEvery, debounce, put, fork } from 'redux-saga/effects';
import { trackAnalytics } from '@adobe/edex/ui/store/routines';
import { v3requestRoutine } from '@adobe/edex/ui/store/sagas/helpers';
import { analyticsAPI } from '@adobe/edex/ui/api';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { TrackAnalyticsActionPayload } from '@adobe/edex/ui/types/payload';

let PENDING: TrackAnalyticsActionPayload[] = [];
const PENDING_MAX_LIMIT = 10;
const TRACK_ANALYTICS_DEBOUNCE_MS = 1000;
const TRACK_ANALYTICS_DEBOUNCED = 'TRACK_ANALYTICS_DEBOUNCED';

function* trackAnalyticsWorker() {
  if (!PENDING.length) {
    return;
  }
  const events = PENDING;
  PENDING = [];
  yield* v3requestRoutine({
    routine: trackAnalytics,
    api: analyticsAPI,
    config: {
      method: 'POST',
      url: 'track',
      data: { events },
    },
  });
}

function* accumulate(action: EdexAction<TrackAnalyticsActionPayload>) {
  PENDING.push(action.payload);
  if (PENDING.length >= PENDING_MAX_LIMIT) {
    yield fork(trackAnalyticsWorker);
  } else {
    yield put({ type: TRACK_ANALYTICS_DEBOUNCED });
  }
}

export function* trackAnalyticsWatcher() {
  yield takeEvery(trackAnalytics.TRIGGER, accumulate);
}

export function* trackAnalyticsWatcherDebounced() {
  yield debounce(TRACK_ANALYTICS_DEBOUNCE_MS, TRACK_ANALYTICS_DEBOUNCED, trackAnalyticsWorker);
}
