import { Router, Route, RouterLocation, Commands, Context } from '@vaadin/router';
import { Locale, LOCALE_PARAM } from '@adobe/edex/ui/constants';
import {
  EdexTagName,
  ProfessionalLearningRedirectRoute,
  RedirectRoutePath,
  RoutePath,
} from '@adobe/edex/ui/shared/constants';
import { SEARCH_SUB_TYPES, SEARCH_PARENT_TYPES } from '@adobe/edex/ui/components/search-page/constants';
import { isString } from 'lodash-es';
import { buildLink, getQueryParams, getSearchString, historyReplace } from '@adobe/edex/ui/shared/utils/routes';

export { RouterLocation };

type EdexRoute = Route & { enabled?: boolean };

export const ROUTER_LOCATION_CHANGED_EVENT = 'vaadin-router-location-changed';

const localePrefix = `/:${LOCALE_PARAM}(${Object.values(Locale).join('|')})?`;

const getPath = (path: string) => `${localePrefix}${path}`;

const searchTypes = SEARCH_PARENT_TYPES.join('|');
const searchSubTypes = SEARCH_SUB_TYPES.join('|');

const getSearchRoute = (path: string): EdexRoute => ({
  path: getPath(`${RoutePath.search}${path}`),
  component: EdexTagName.searchPage,
  action: async () => {
    await import('@adobe/edex/ui/components/search-page/search-page');
  },
});

export enum SearchCanonicalUrlParam {
  filterLabel = 'filterLabel',
  searchType = 'searchType',
  searchSubType = 'searchSubType',
}

/**
 * Account for vue routes (prevent back button 404)
 */
const vueRoutes: EdexRoute[] = [
  '/professional-learning/:courseType/:courseVanityUrl*',
  '/pd/course/*',
  '/professional-learning/self-paced-course/',
  '/edu-student-course/',
  '/member/*',
  '/community/member/*',
  '/discussion/*',
  '/community/discussion/*',
  '/new-discussion',
  '/community/discussion/new',
  '/max',
  '/browse',
  '/community*',
  '/account/settings',
  '/comment/:commentID',
  '/leaderboard',
  '/help-center',
].map((path) => ({
  path: getPath(path),
  component: '',
  action: async () => {
    window.location.href = buildLink(window.location.pathname);
  },
}));

const redirectRoutePaths = new Map<string, string>([
  ...RedirectRoutePath,
  ...ProfessionalLearningRedirectRoute,
  ['/aca/prep', '/adobe-certified-professional'],
  ['/aca/whycertify', '/adobe-certified-professional'],
  ['/aca/objectives', '/adobe-certified-professional/exams'],
  ['/aca/faq', '/adobe-certified-professional'],
  ['/aca', '/adobe-certified-professional'],
  ['/common-core', '/'],
  ['/uk/adobe-for-teachers', '/'],
  ['/parents', '/'],
  ['/young-learners', '/'],
  ['/distance-learning/digital-literacy', '/'],
  ['/education-leader', '/community'],
]);

const getRedirects = (): EdexRoute[] => {
  const routeConfigs: EdexRoute[] = [];
  redirectRoutePaths.forEach((value, key) => {
    routeConfigs.push({
      path: getPath(`${key}`),
      action: (ctx: Context, cmds: Commands) => {
        return cmds.redirect(ctx.pathname.replace(key, value));
      },
    });
  });
  return routeConfigs;
};

const routes: EdexRoute[] = [
  {
    path: getPath(`/resource/(.*)`),
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace('/resource/', `${RoutePath.teachingResources}/`));
    },
  },
  // CCEDU-4250 resource re-direct
  {
    path: getPath(`${RoutePath.teachingResources}/-BEVm03AD`),
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace('/-BEVm03AD', '/AwXKpQpmV'));
    },
  },
  {
    path: getPath(`${RoutePath.teachingResources}/ykoYXYCWt`),
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace('/ykoYXYCWt', '/9iOd9sYJP'));
    },
  },
  {
    path: getPath(`${RoutePath.teachingResources}/-BEVm03AD`),
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace('/-BEVm03AD', '/AwXKpQpmV'));
    },
  },
  {
    path: getPath(`${RoutePath.teachingResources}/ykoYXYCWt`),
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace('/ykoYXYCWt', '/9iOd9sYJP'));
    },
  },
  {
    path: getPath(`${RoutePath.teachingResources}/(new|edit)`),
    component: EdexTagName.resourceBuilder,
    action: async () => {
      await import('@adobe/edex/ui/components/resource/resource-builder/resource-builder');
    },
  },
  {
    path: getPath(`${RoutePath.teachingResources}/:vanityURL`),
    component: EdexTagName.resourceDetails,
    action: async () => {
      await import('@adobe/edex/ui/components/resource/resource-details/resource-details');
    },
  },
  {
    path: getPath(`${RoutePath.teachingResources}/:vanityURL/preview`),
    component: EdexTagName.resourceDetails,
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace('/preview', '/'));
    },
  },
  getSearchRoute(''),
  getSearchRoute(
    `/:${SearchCanonicalUrlParam.searchType}(${searchTypes})/:${SearchCanonicalUrlParam.searchSubType}(${searchSubTypes})`
  ),
  getSearchRoute(`/:${SearchCanonicalUrlParam.searchType}(${searchTypes})/:${SearchCanonicalUrlParam.filterLabel}?`),
  {
    path: getPath('/'),
    component: EdexTagName.teachingResources,
    action: async () => {
      await import('@adobe/edex/ui/components/teaching-resources/teaching-resources');
    },
  },
  {
    path: getPath('/main/auth'),
    component: EdexTagName.auth,
    action: async () => {
      await import('@adobe/edex/ui/components/auth/auth');
    },
  },
  {
    path: getPath(`${RoutePath.dashboard}/:subPath/:tab?`),
    component: EdexTagName.myEdex,
    action: async () => {
      await import('@adobe/edex/ui/components/my-edex/my-edex');
    },
  },
  {
    path: getPath(''),
    action: (ctx: Context, cmds: Commands) => {
      return cmds.redirect(ctx.pathname.replace(/$/, '/'));
    },
  },
  {
    path: getPath(RoutePath.teachingResources),
    redirect: getPath(RoutePath.landing),
  },
  {
    path: getPath(RoutePath.professionalLearning),
    component: EdexTagName.professionalLearning,
    action: async () => {
      await import('@adobe/edex/ui/components/professional-learning/professional-learning');
    },
  },
  {
    path: getPath('/ace-report'),
    component: EdexTagName.aceReport,
    action: async () => {
      await import('@adobe/edex/ui/components/ace-report/ace-report');
    },
  },
  {
    path: getPath('/news'),
    component: 'edex-newsletter',
    action: async () => {
      await import('@adobe/edex/ui/components/newsletter/newsletter-page');
    },
  },
  {
    path: getPath('/student/unsubscribe'),
    component: EdexTagName.studentUnsubscribe,
    action: async () => {
      await import('@adobe/edex/ui/components/student-unsubscribe/student-unsubscribe-page');
    },
  },
  {
    path: getPath('/:memberVanityURL/playlists/:vanityURL'),
    component: EdexTagName.playlistView,
    action: async () => {
      await import('@adobe/edex/ui/components/playlists/playlistView');
    },
  },
  {
    path: getPath('/:vanityURL(.+)'),
    component: EdexTagName.staticPage,
    action: async () => {
      await import('@adobe/edex/ui/components/static-page/static-page');
    },
  },
  {
    path: '(.*)',
    component: '',
    action: async () => {
      if (router.location.route?.component === EdexTagName.auth) {
        const query = getQueryParams(router.location.search);
        window.location.href = buildLink(query['backUrl'].toString() || RoutePath.landing);
      } else {
        window.location.href = buildLink(window.location.pathname);
      }
    },
  },
].filter(({ enabled = true }) => enabled);

export const router = new Router(null, { baseUrl: null });

export async function initRouter(outlet: Element) {
  router.setOutlet(outlet);
  await router.setRoutes([...getRedirects(), ...vueRoutes, ...routes]);
}

export const isBuilderRoute = (location: RouterLocation = router.location) =>
  location.route?.component === EdexTagName.resourceBuilder;

export function navigate(
  params:
    | string
    | {
        path?: string;
        queryParams?: Record<string, any>;
        mergeQueryParams?: boolean;
        replaceState?: boolean;
      }
) {
  let path, queryParams, mergeQueryParams, search, replaceState;
  if (isString(params)) {
    path = params;
  } else {
    ({ path = router.location.pathname, queryParams, mergeQueryParams, replaceState } = params);
    const searchString = getSearchString(queryParams, mergeQueryParams);
    search = searchString ? `?${searchString}` : '';
  }
  const pathWithLocale = buildLink(path);
  // replace is not supported by the router :(
  if (replaceState) {
    historyReplace(`${pathWithLocale}${search}`);
  } else {
    Router.go({
      pathname: pathWithLocale,
      search,
    });
  }
}
