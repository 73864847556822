import { takeLeading } from 'redux-saga/effects';
import {
  fetchComments,
  createComment,
  deleteComment,
  updateComment,
  reportComment,
  unReportComment,
} from '@adobe/edex/ui/store/routines';
import { commentAPI, abuseAPI } from '@adobe/edex/ui/api';
import { v3requestRoutine } from '@adobe/edex/ui/store/sagas/helpers';
import { EntityType } from '@adobe/edex/ui/types';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import {
  CreateCommentPayload,
  FetchCommentsPayload,
  IdActionPayload,
  UpdateCommentPayload,
} from '@adobe/edex/ui/types/payload';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { infoToast } from '@adobe/edex/ui/utils/notification';

function* fetchCommentsWorker(action: EdexAction<FetchCommentsPayload>) {
  yield* v3requestRoutine({
    routine: fetchComments,
    api: commentAPI,
    config: {
      method: 'GET',
      url: '',
      params: action.payload.params,
    },
  });
}

function* createCommentWorker(action: EdexAction<CreateCommentPayload>) {
  yield* v3requestRoutine({
    routine: createComment,
    api: commentAPI,
    config: {
      method: 'POST',
      url: '',
      data: action.payload.data,
    },
  });
}

function* updateCommentWorker(action: EdexAction<UpdateCommentPayload>) {
  yield* v3requestRoutine({
    routine: updateComment,
    api: commentAPI,
    config: {
      method: 'PUT',
      url: `/${action.payload.id}`,
      data: action.payload.data,
    },
  });
}

function* deleteCommentWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: deleteComment,
    api: commentAPI,
    config: {
      method: 'DELETE',
      url: `/${action.payload.id}`,
    },
  });
}

function* reportCommentWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: reportComment,
    api: abuseAPI,
    config: {
      method: 'POST',
      url: '',
      data: {
        entityType: EntityType.Comment,
        entityID: action.payload.id,
      },
    },
    onSuccess: () => infoToast({ message: I18nKey.reported }),
  });
}

function* unReportCommentWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: unReportComment,
    api: abuseAPI,
    config: {
      method: 'DELETE',
      url: `/${EntityType.Comment}/${action.payload.id}`,
    },
  });
}

export function* fetchCommentsWatcher() {
  yield takeLeading(fetchComments.TRIGGER, fetchCommentsWorker);
}
export function* createCommentWatcher() {
  yield takeLeading(createComment.TRIGGER, createCommentWorker);
}
export function* updateCommentWatcher() {
  yield takeLeading(updateComment.TRIGGER, updateCommentWorker);
}
export function* deleteCommentWatcher() {
  yield takeLeading(deleteComment.TRIGGER, deleteCommentWorker);
}
export function* reportCommentWatcher() {
  yield takeLeading(reportComment.TRIGGER, reportCommentWorker);
}
export function* unReportCommentWatcher() {
  yield takeLeading(unReportComment.TRIGGER, unReportCommentWorker);
}
