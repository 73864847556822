import { html, customElement } from '@adobe/edex/ui/edex-lit';
import { DialogHeadingClassName, EdexTagName } from '@adobe/edex/ui/shared/constants';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { BaseElement } from '@adobe/edex/ui/components/base.element';
import { Overlay } from '@spectrum-web-components/overlay';
import '@spectrum-web-components/dialog/sp-dialog-wrapper.js';

import(
  /* webpackChunkName: "component/resource-delete-dialog-styles" */
  /* webpackPreload: true */
  /* webpackMode: "lazy" */
  './confirmation-dialog.scss'
);
@customElement(EdexTagName.dialogNext)
export class Dialog extends BaseElement {
  static get properties() {
    return {
      dismissable: { type: Boolean },
      header: { type: String },
      divider: { type: Boolean },
      headingClassName: { type: String },
      size: { type: String },
    };
  }
  dismissable = false;
  divider = true;
  header: string;
  headingClassName = DialogHeadingClassName.default;
  closeOverlay: any;
  size: string;

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('sp-closed', this.emitCloseEvent);
  }

  disconnectedCallback() {
    this.closeOverlay?.();
    this.removeEventListener('sp-closed', this.emitCloseEvent);
    super.disconnectedCallback();
  }

  async firstUpdated() {
    await this.openOverlay();
  }

  openOverlay = async () => {
    const interaction = 'modal';
    const content = this.shadowRoot.querySelector('sp-dialog-wrapper') as any;

    this.shadowRoot.querySelectorAll('slot').forEach((slot) => {
      const nodes = [...slot.assignedNodes({ flatten: true })];
      if (nodes.length) {
        content.querySelector(':first-child').appendChild(...nodes);
      }
    });

    const options = {
      offset: 0,
      placement: 'none',
      receivesFocus: 'auto',
    };
    this.closeOverlay = await Overlay.open(this, interaction, content, options as any);
  };

  emitCloseEvent = () => {
    this.emitEvent('close');
  };
  render() {
    const { header, dismissable, divider, headingClassName, size } = this;
    return html`
      <overlay-trigger>
        <sp-dialog-wrapper
          headline="${$t(header)}"
          underlay
          ?dismissable=${dismissable}
          ?no-divider=${!divider}
          @close=${this.emitCloseEvent}
          headingClass="${headingClassName}"
          size="${size}"
        >
          <div class="content-wrapper">
            <slot name="content"></slot>
            <slot name="footer"></slot>
          </div>
        </sp-dialog-wrapper>
      </overlay-trigger>
    `;
  }
}
