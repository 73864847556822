import { IMS } from '@adobe/edex/ui/shared/config/ims';
import { removeActAsInfoFromLocalStorage } from '@adobe/edex/ui/shared/utils/actAs';
import { BOOTCAMP, authenticate } from '@adobe/edex/ui/shared/api/auth';
import { SELECT_PREF_KEY } from '@adobe/edex/ui/constants';
import { FullStoryUtils } from './fullstory';
import { AdobeAnalyticsEvent } from '../constants';
import { sha1 } from 'object-hash';
import { Analytics } from './analytics';

export { BOOTCAMP };
export class EdexAuth {
  private authRequest: Promise<{
    user: string;
  }>;

  private static instance: EdexAuth;
  private static readonly NEW_MEMBER_THRESHOLD_IN_MILLIS = 10000;

  static getInstance(): EdexAuth {
    if (!EdexAuth.instance) {
      EdexAuth.instance = new EdexAuth();
    }
    return EdexAuth.instance;
  }

  private static clearLocalStorage() {
    removeActAsInfoFromLocalStorage();
  }

  static async signIn() {
    await EdexAuth.clearLocalStorage();
    await IMS.signIn();
  }
  static async signUp() {
    await EdexAuth.clearLocalStorage();
    await IMS.signUp();
  }

  static async signOut() {
    await EdexAuth.clearLocalStorage();
    sessionStorage.removeItem(SELECT_PREF_KEY);
    await IMS.signOut();
  }

  async authenticate() {
    if (!this.authRequest) {
      this.authRequest = this.getAuthRequest();
    }
    return this.authRequest;
  }

  private getAuthRequest() {
    return EdexAuth.doAuthenticate()
      .then((result) => {
        this.authRequest = null;
        return result;
      })
      .catch((error) => {
        this.authRequest = null;
        throw error;
      });
  }

  private static async doAuthenticate() {
    const { status, data } = await authenticate();
    EdexAuth.handleAuthEvents(data);
    return { status, user: await IMS.getUserId() };
  }

  private static async handleAuthEvents(authResponse) {
    try {
      if (EdexAuth.isNewMember(authResponse)) {
        await window.adobe?.target?.trackEvent({ mbox: 'edex-newuser' });
        Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.newMemberSignUp, 'New member signUp');
        FullStoryUtils.getInstance().event(AdobeAnalyticsEvent.newMemberSignUp, {
          id: sha1(authResponse.user?.id) || 'NA',
          createdAt: authResponse.user.createdAt,
        });
      }
    } catch (err) {
      // to avoid breaking existing auth because of FS event() failure
    }
  }

  private static isNewMember(authResponse): boolean {
    if (!authResponse?.user?.createdAt) {
      return false;
    }
    // not more than 10 seconds older
    return (
      new Date().getTime() < new Date(authResponse?.user?.createdAt).getTime() + EdexAuth.NEW_MEMBER_THRESHOLD_IN_MILLIS
    );
  }
}
