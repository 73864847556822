export const ACT_AS_KEY = 'actAs';

interface Member {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ActAsInfo {
  originalMember: Member;
  targetMember: Member;
}

export const getActAsInfo = (): ActAsInfo => {
  const data = localStorage.getItem(ACT_AS_KEY);
  if (!data) {
    return;
  }
  return JSON.parse(data);
};

export const storeActAsInfo = (data: ActAsInfo) => localStorage.setItem(ACT_AS_KEY, JSON.stringify(data));

export const removeActAsInfoFromLocalStorage = () => localStorage.removeItem(ACT_AS_KEY);

export const isActAsMode = () => !!localStorage.getItem(ACT_AS_KEY);
