import { takeLatest } from 'redux-saga/effects';
import * as routines from '@adobe/edex/ui/store/routines';
import { v3requestRoutine } from '@adobe/edex/ui/store/sagas/helpers';
import { searchAPI } from '@adobe/edex/ui/api';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { SearchActionPayload } from '@adobe/edex/ui/types/payload';
import { SearchEntityType } from '@adobe/edex/ui/shared/types/search';
import { Routine } from 'redux-saga-routines';

const searchWorkerFactory = (routine: Routine, r: SearchEntityType) =>
  function* (action: EdexAction<SearchActionPayload>) {
    yield* v3requestRoutine({
      routine,
      api: searchAPI,
      config: {
        method: 'GET',
        params: { ...action.payload, r },
      },
    });
  };

const searchResourcesWorker = searchWorkerFactory(routines.searchResources, SearchEntityType.resources);

const searchCoursesWorker = searchWorkerFactory(routines.searchCourses, SearchEntityType.courses);

const searchDiscussionsWorker = searchWorkerFactory(routines.searchDiscussions, SearchEntityType.discussions);

const searchCollectionsWorker = searchWorkerFactory(routines.searchCollections, SearchEntityType.collections);

export function* searchResources() {
  yield takeLatest(routines.searchResources.TRIGGER, searchResourcesWorker);
}

export function* searchCourses() {
  yield takeLatest(routines.searchCourses.TRIGGER, searchCoursesWorker);
}

export function* searchDiscussions() {
  yield takeLatest(routines.searchDiscussions.TRIGGER, searchDiscussionsWorker);
}

export function* searchCollections() {
  yield takeLatest(routines.searchCollections.TRIGGER, searchCollectionsWorker);
}
