import { TemplateResult } from 'lit';

type Modify<T, R> = Omit<T, keyof R> & R;

export enum EntityStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum EntityType {
  Assignment = 'Assignment',
  Comment = 'Comment',
  Course = 'Course',
  Discussion = 'Discussion',
  Member = 'Member',
  Resource = 'Resource',
  Workshop = 'Workshop',
}

export interface Timestamps {
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface BaseRecordSchema extends Timestamps {
  createdBy: string;
  updatedBy: string;
  requestID: string;
}

export interface BaseEntityRecordSchema extends BaseRecordSchema {
  id: string;
  vanityURL: string;
  status: EntityStatus;
  siteID: string;
}

export enum SystemEntityTypes {
  assignment = 'assignment',
  course = 'course',
  discussion = 'discussion',
  file = 'file',
  member = 'member',
  resource = 'resource',
}

//////
// Metadata Service
//////

export enum MetadataModelKey {
  languages = 'languages',
  countries = 'countries',
  academicLevels = 'academicLevels',
  badges = 'badges',
  certifications = 'certifications',
  regions = 'regions',
  contentStandards = 'contentStandards',
  subjects = 'subjects',
  copyLicenses = 'copyLicenses',
  schoolTypes = 'schoolTypes',
  fileTypes = 'fileTypes',
  jobFunctions = 'jobFunctions',
  educationSegments = 'educationSegments',
  reputationLevels = 'reputationLevels',
  teachingExperiences = 'teachingExperiences',
  technicalExpertise = 'technicalExpertise',
  topics = 'topics',
  resourceTypes = 'resourceTypes',
  forums = 'forums',
  products = 'products',
  productUsedCount = 'productUsedCount',
  components = 'components',
  timing = 'timing',
  courseType = 'courseType',
  duration = 'duration',
  author = 'a',
  lessonSteps = 'lessonSteps',
  schoolDistricts = 'schoolDistricts',
}

export interface BaseMetadataSchema<TStatus = EntityStatus> extends Timestamps {
  id: string;
  status: TStatus;
}

interface SearchableMetadataSchema {
  urlLabel: string;
}

export interface AcademicLevelSchema extends BaseMetadataSchema, SearchableMetadataSchema {
  i18nCategory: string;
  i18nLabel: string;
  sortOrder: number;
}

export enum BadgeCategory {
  activity = 'i18n.badge.activity',
  leadership = 'i18n.badge.leadership',
  certifications = 'i18n.badge.certifications',
  status = 'i18n.badge.status',
  course = 'i18n.badge.course',
  unassigned = 'i18n.badge.unassigned',
}

export interface BadgeSchema extends BaseMetadataSchema {
  languageID: string;
  imageURL: string;
  siteID: string;
  crossSite: boolean;
  i18nLabel: string;
  i18nDescription: string;
  category: BadgeCategory;
  awardPoints: number;
  repeatable: boolean;
  sortOrder: number;
  createdBy: string;
}

export interface CertificationSchema extends BaseMetadataSchema {
  siteID?: string;
  certification?: string;
  badgeID: string;
  i18nLabel?: string;
  adobe: boolean;
  sortOrder: number;
  type: string;
  createdBy: string;
}

export enum ContentStandardType {
  general = 'general',
  ISTENETS = 'ISTENETS',
}

export interface ContentStandardSchema extends BaseMetadataSchema {
  parentID: string;
  label: string;
  url: string;
  sortOrder: number;
  type: ContentStandardType;
}

export interface ComponentSchema extends BaseMetadataSchema {
  i18nLabel: string;
}

export interface CopyLicenseSchema extends BaseMetadataSchema {
  i18nLabel: string;
  i18nDescription: string;
  iconURL: string;
  licenseDeedURL: string;
  legalCodeURL: string;
}

export interface CountrySchema extends BaseMetadataSchema {
  iso2Code: string;
  iso3Code: string;
  i18nLabel: string;
}

export interface EducationSegmentSchema extends BaseMetadataSchema {
  i18nLabel: string;
}

export enum ForumStatus {
  active = 'active',
  archived = 'archived',
  closed = 'closed',
  deleted = 'deleted',
  inactive = 'inactive',
}

export interface ForumSchema extends BaseMetadataSchema<ForumStatus> {
  vanityURL: string;
  title: string;
  description: string;
  public: boolean;
  siteID: string;
}

export interface JobFunctionSchema extends BaseMetadataSchema {
  i18nLabel: string;
}

export interface LanguageSchema extends BaseMetadataSchema {
  code: string;
  name: string;
  englishName: string;
  countryID: string;
}

export enum ProductStatus {
  active = 'active',
  archived = 'archived',
  inactive = 'inactive',
  deleted = 'deleted',
}

export interface ProductHelpfulInfo {
  imageURL: string;
  title: string;
  url: string;
  description: string;
}

export interface ProductSchema extends BaseMetadataSchema<ProductStatus>, SearchableMetadataSchema {
  siteID: string;
  title: string;
  description: string;
  vanityURL: string;
  links: {
    buy: string;
    try: string;
    marketing: string;
    support: string;
  };
  iconProps: unknown;
  classifications: string[];
  creativeCloud: boolean;
  selectable: boolean;
  featured: boolean;
  sortOrder: number;
  helpfulInfo: ProductHelpfulInfo[];
}
export interface ProductCountSchema {
  id: string;
  siteID: string;
  name: string;
}
export interface RegionSchema extends BaseMetadataSchema {
  countryID: string;
  code: string;
  i18nLabel: string;
}

export interface ReputationLevelSchema extends BaseMetadataSchema {
  badgeID: string;
  i18nLabel: string;
  iconURL: string;
  minimumPoints: number;
}

export interface ResourceTypeSchema extends BaseMetadataSchema {
  i18nLabel: string;
  i18nDescription: string;
  vanityURL: string;
}

export interface SchoolTypeSchema extends BaseMetadataSchema {
  i18nLabel: string;
  sortOrder: number;
}
export interface TimingSchema extends BaseMetadataSchema, SearchableMetadataSchema {
  name: string;
  i18nLabel: string;
  sortOrder: number;
}

export interface SubjectSchema extends BaseMetadataSchema, SearchableMetadataSchema {
  parentID: string;
  i18nLabel: string;
  imageURL: string;
  children?: SubjectSchema[];
}

export interface DurationSchema extends SearchableMetadataSchema {
  // extend to BaseMetadataSchema if we are getting duration from backend. For now it is hardcoded on UI.
  id: string;
  name: string;
  i18nLabel: string;
}

export interface TeachingExperienceSchema extends BaseMetadataSchema {
  i18nLabel: string;
}

export interface TechnicalExpertiseSchema extends BaseMetadataSchema {
  i18nLabel: string;
}

export enum TopicStatus {
  active = 'active',
  archived = 'archived',
  inactive = 'inactive',
  deleted = 'deleted',
}

export interface TopicForum {
  id: string;
  title: string;
  public: boolean;
}

export interface TopicSchema extends BaseMetadataSchema<TopicStatus> {
  vanityURL: string;
  i18nLabel: string;
  i18nDescription: string;
  color: string;
  public: boolean;
  forums: TopicForum[];
  siteID: string;
}

export enum FileTypeI18nLabel {
  assignmentFile = 'i18n.fileType.assignmentFile',
  background = 'i18n.fileType.background',
  badgeIcon = 'i18n.fileType.badgeIcon',
  carouselImage = 'i18n.fileType.carouselImage',
  commentFile = 'i18n.fileType.commentFile',
  connectedFile = 'i18n.fileType.connectedFile',
  courseCertificate = 'i18n.fileType.courseCertificate',
  courseHeroImage = 'i18n.fileType.courseHeroImage',
  courseLandingPageImage = 'i18n.fileType.courseLandingPageImage',
  courseEnrollmentImage = 'i18n.fileType.courseEnrollmentImage',
  coursePassedImage = 'i18n.fileType.coursePassedImage',
  groupLandingPageImage = 'i18n.fileType.groupLandingPageImage',
  p100 = 'i18n.fileType.p100',
  p16 = 'i18n.fileType.p16',
  p32 = 'i18n.fileType.p32',
  pdHeroImage = 'i18n.fileType.pdHeroImage',
  pdItemImage = 'i18n.fileType.pdItemImage',
  pdLandingPageImage = 'i18n.fileType.pdLandingPageImage',
  profile = 'i18n.fileType.profile',
  progamApplicantFile = 'i18n.fileType.progamApplicantFile',
  progamApplicantReferral = 'i18n.fileType.progamApplicantReferral',
  resourceFeaturedImage = 'i18n.fileType.resourceFeaturedImage',
  resourceFile = 'i18n.fileType.resourceFile',
  resourceFileBundle = 'i18n.fileType.resourceFileBundle',
  resourceImage = 'i18n.fileType.resourceImage',
  resourcePresentation = 'i18n.fileType.resourcePresentation',
  resourceRelatedContent = 'i18n.fileType.resourceRelatedContent',
  resourceScreenshot = 'i18n.fileType.resourceScreenshot',
  resourceVideo = 'i18n.fileType.resourceVideo',
  resourceWorkbook = 'i18n.fileType.resourceWorkbook',
  syllabusBundle = 'i18n.fileType.syllabusBundle',
  syllabusFeaturedImage = 'i18n.fileType.syllabusFeaturedImage',
  syllabusOverview = 'i18n.fileType.syllabusOverview',
  syllabusUnitOverview = 'i18n.fileType.syllabusUnitOverview',
}

export interface FileTypeSchema extends BaseMetadataSchema {
  siteID: string;
  i18nLabel: FileTypeI18nLabel;
  createdBy: string;
}

export interface CourseTypeSchema extends BaseMetadataSchema {
  i18nLabel: string;
  i18nDescription: string;
  vanityURL: string;
}

export interface LessonStepsSchema extends BaseMetadataSchema {
  i18nLabel: string;
  imageURL: string;
}

export interface SchoolDistrictSchema extends BaseMetadataSchema {
  ncesID: string;
  districtName: string;
}

//////
// Member Service
//////

export enum MemberStatus {
  active = 'active',
  inactive = 'inactive',
  banned = 'banned',
  deleted = 'deleted',
}

export interface MemberSettings {
  prefLanguage: string;
  newsletters: boolean;
  surveys: boolean;
  notifications: boolean;
  k12Emails: boolean;
}

export enum SocialNetworkID {
  facebook = 'facebook',
  twitter = 'twitter',
  linkedin = 'linkedin',
  behance = 'behance',
  website = 'website',
}

export interface MemberSocialNetworks {
  id: SocialNetworkID;
  url: string;
}

export interface MemberToBadgeSchema {
  memberBadgeID: string;
  memberID: string;
  badgeID: string;
  status: string;
  courseVanityURL: string;
  createdBy: string;
  createdAt: string;
}

export interface Interests {
  products: string[];
  subjects: string[];
  academicLevels: string[];
}

export interface Expertise {
  products: string[];
  subjects: string[];
  certifications: string[];
  skills: string[];
}

export interface MemberReputationLevel {
  id: string;
  achievedAt: string;
}

export interface MemberSchema extends Omit<BaseRecordSchema, 'createdBy'> {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  vanityURL: string;
  adobeGUID: string;
  avatarURL: string;
  status: MemberStatus;
  jobTitle: string;
  biography: string;
  settings: MemberSettings;
  socialNetworks: MemberSocialNetworks[];
  roles: string[];
  countryCode: string;
  regionID: string;
  city: string;
  interests: Interests;
  expertise: Expertise;
  experience: ExperienceSchema[];
  reputationPoints: number;
  reputationLevels: MemberReputationLevel[]; // tracks history - index=0 is current
  educationSegmentID: string;
  updatedBy: string;
  siteID: string;
  imsUserId: string;
  imsAuthId: string;
  imsProfile: any;
  createdAt: string;
}

type MemberProfileBasicAttributes = Pick<
  MemberSchema,
  | 'id'
  | 'vanityURL'
  | 'avatarURL'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'jobTitle'
  | 'biography'
  | 'socialNetworks'
  | 'city'
  | 'reputationPoints'
>;

type MemberProfileOwnerAttributes = Partial<Pick<MemberSchema, 'email' | 'settings'>>;

type MemberProfileMarshalledAttributes = {
  badgesByCategory: BadgesByCategory;
  region: RegionSchema;
  country: CountrySchema;
  school?: string;
  profileCompletion: number;
  interests: MarshalledInterests;
  expertise: MarshalledExpertise;
  experience: MarshalledExperience[];
  schoolDistrict?: SchoolDistrictSchema;
  reputationLevel: MarshalledRepLevel; // current (index=0)
  followed: boolean;
  isAdmin?: boolean;
  isShellCourseAdmin?: boolean;
  isShellCourseAssignmentReviewer?: boolean;
};

export type MemberProfile = MemberProfileBasicAttributes &
  MemberProfileOwnerAttributes &
  MemberProfileMarshalledAttributes;

export const AUTHOR_ATTRIBUTES = [
  'id',
  'vanityURL',
  'avatarURL',
  'fullName',
  'firstName',
  'lastName',
  'jobTitle',
  'countryCode',
  'regionID',
] as const;

type AuthorAttrs = typeof AUTHOR_ATTRIBUTES[number];

export type Author = Pick<MemberSchema, AuthorAttrs> & {
  followed: boolean;
};

export type MemberCardSchema = Author & {
  awards: BadgeSchema[];
  reputationLevel: ReputationLevelSchema;
  entity: SystemEntityTypes.member;
};

export interface MarshalledBadgeSchema extends BadgeSchema {
  courseVanityURL: string;
  earned: string;
}

export type BadgesByCategory = Record<string, MarshalledBadgeSchema[]>;

export interface MarshalledRepLevel extends ReputationLevelSchema {
  achievedAt: string;
}

export interface MarshalledInterests {
  products: ProductSchema[];
  subjects: SubjectSchema[];
  academicLevels: AcademicLevelSchema[];
}

export interface MarshalledExpertise {
  products: ProductSchema[];
  subjects: SubjectSchema[];
  certifications: CertificationSchema[];
  skills: string[];
}

export interface ExperienceSchema {
  id: string;
  jobFunctionID: string;
  schoolTypeID: string;
  schoolID?: string;
  jobTitle: string;
  i18nCountry: string;
  description?: string;
  institution: string;
  city: string;
  startDate: string;
  endDate?: string;
  i18nRegion?: string;
  educationSegments: string[];
  sortOrder?: number;
}

export type MarshalledExperience = Modify<
  ExperienceSchema,
  {
    schoolType: SchoolTypeSchema;
    country: CountrySchema;
    region: RegionSchema;
    jobFunction: JobFunctionSchema;
    educationSegments: EducationSegmentSchema[];
  }
>;
export interface MemberFollowSchema {
  memberFollowerID: string;
  memberFollowedID: string;
  requestID: string;
}

//////
// File Service
//////

export enum FileStatus {
  inactive = 'inactive',
  active = 'active',
  deleted = 'deleted',
  rejected = 'rejected',
}
export enum FileImportStatus {
  pending = 'pending',
  processed = 'processed',
}

export const FileEntityTypes = [EntityType.Resource, EntityType.Assignment, EntityType.Course] as const;
export type FileEntityType = typeof FileEntityTypes[number];

export interface FileSchema extends BaseRecordSchema {
  id: string;
  siteID: string;
  entityType: FileEntityType;
  entityID: string;
  fileTypeID: string;
  label: string;
  originalFileName: string;
  storageOrigin: string;
  storagePath: string;
  size: number;
  mimeType: string;
  sortOrder: number;
  status: FileStatus;
  components: string[];
}

export interface FileImportSchema extends BaseRecordSchema {
  id: string;
  fileID: string;
  status: FileImportStatus;
}

//////
// Notification Service
//////

export enum EmailTemplateEntityType {
  course = 'course',
  connected = 'connected',
  programapplicant = 'programapplicant',
  community = 'community',
  invitation = 'invitation',
  announcement = 'announcement',
  member = 'member',
  resource = 'resource',
  comment = 'comment',
  contentChange = 'content_change',
}

export enum EmailTemplateName {
  CoursePassed = 'Course Passed',
  EdexInvitation = 'EDEX Invitation',
  NewAnnouncement = 'New Announcement',
  GroupAnnouncement = 'Group Announcement',
  NotifyMemberAddedToGroup = 'NOTIFY MEMBER ADDED TO GROUP',
  NotifyGroupOwner = 'NOTIFY GROUP OWNER',
  AssignmentCommentSubscribersAuthor = 'Assignment Comment Subscribers - Author',
  AssignmentCommentSubscribersParticipant = 'Assignment Comment Subscribers - Participant',
  DiscussionCommentSubscribersAuthor = 'Discussion Comment Subscribers - Author',
  DiscussionCommentSubscribersParticipant = 'Discussion Comment Subscribers - Participant',
  ResourceCommentSubscribersAuthor = 'Resource Comment Subscribers - Author',
  ResourceCommentSubscribersParticipant = 'Resource Comment Subscribers - Participant',
  AccountDeactivation = 'Account Deactivation',
  FollowingMember = 'Following Member',
  ReputationLevelChanged = 'Reputation Level Changed',
  CourseIncompleteReminder = 'Course Incomplete Reminder',
  CourseEnrollmentConfirmation = 'Course Enrollment Confirmation',
  WelcomeNewMember = 'Welcome New Users',
}

export interface EmailTemplateSchema extends BaseRecordSchema {
  id: string;
  siteID: string;
  templateName: EmailTemplateName;
  subject: string;
  richText: string;
  plainText: string;
  entityID: string;
  entityType: EmailTemplateEntityType;
  categories: string[];
  status: EntityStatus;
}

//////
// Analytics Service
//////

export interface AnalyticsSchema {
  index: string;
  filterBy?: string;
  filters?;
  range?: string;
}

//////
// Rating Service
//////

export const RatingEntityTypes = [
  EntityType.Resource,
  EntityType.Course,
  EntityType.Discussion,
  EntityType.Comment,
] as const;
export type RatingEntityType = typeof RatingEntityTypes[number];

export interface RatingSchema extends BaseRecordSchema {
  id: string;
  rating: number; // 0-100
  entityID: string;
  entityType: RatingEntityType;
  siteID: string;
}

export interface RatingSummary {
  total: number;
  average: number; // 0-5
  myRating?: number;
}

//////
// Comment Service
//////

export const CommentEntityTypes = [EntityType.Resource, EntityType.Discussion, EntityType.Assignment] as const;
export type CommentEntityType = typeof CommentEntityTypes[number];
export type CommentEntity = ResourceSchema | DiscussionSchema | AssignmentSchema;

export interface CommentSchema extends BaseRecordSchema {
  id: string;
  parentCommentID: string;
  siteID: string;
  comment: string;
  entityID: string;
  entityType: CommentEntityType;
  ratings: string[];
  subscribed: boolean;
  bestAnswer: boolean;
  status: EntityStatus;
}

export interface MarshalledComment extends CommentSchema {
  author: Author;
  flagged: boolean;
  entity: Partial<DiscussionSchema | ResourceSchema | AssignmentSchema>;
  nestingLevel?: number;
}

//////
// Tag Service
//////

export interface TagSchema extends BaseRecordSchema {
  id: string;
  title: string;
}

export type MarshalledTag = Pick<TagSchema, 'id' | 'title'>;

//////
// Localization
//////

export interface LocalizationSchema extends BaseRecordSchema {
  id: string;
  locale: string;
  name: string;
  value: string;
  comment: string;
  status: EntityStatus;
}

//////
// Curation Service
//////

export type CurationEntityType = EntityType.Resource;
export type CurationStatus = EntityStatus.active | EntityStatus.deleted;
export interface CurationSchema extends BaseRecordSchema {
  entityID: string;
  entityType: CurationEntityType;
  boost: number;
  collections: string[];
  status: CurationStatus;
}

//////
// Discussion Service
//////

export interface DiscussionSchema extends BaseRecordSchema {
  id: string;
  forumID: string;
  siteID: string;
  vanityURL: string;
  title: string;
  description: string;
  pinned: boolean;
  public: boolean;
  subscribed: boolean;
  locked: boolean;
  status: EntityStatus;
  products: PrimarySecondary;
  tags: string[];
  publishedAt: string;
}

export type MarshalledDiscussion = Modify<
  DiscussionSchema,
  {
    author: Author;
    products: ProductSchema[];
    forum: ForumSchema;
    tags: MarshalledTag[];
    myRating: number;
    ratings: RatingSummary;
    flagged: boolean;
    commentsCount: number;
    favoriteCount: number;
    viewCount: number;
    favorite: boolean;
    lastDiscussedAt: string;
  }
>;

export const DISCUSSION_CARD_ATTRIBUTES = ['id', 'title', 'description', 'vanityURL', 'createdBy'] as const;

export type DiscussionCardSchema = Pick<DiscussionSchema, typeof DISCUSSION_CARD_ATTRIBUTES[number]> & {
  author: Author;
  commentsCount: number;
  entity: SystemEntityTypes.discussion;
};

//////
// Playlist Service
//////
export interface PlaylistSchema {
  vanityURL: string;
  id: string;
  title: string;
  description: string;
  author: Partial<MemberCardSchema>;
  items: { data: PlaylistItemSchema[] };
}

export type UpdatePlaylistSchema = Modify<
  PlaylistSchema,
  {
    items: PlaylistItemSchema[];
  }
>;
export interface PlaylistListSchema {
  itemExistedPlaylist: PlaylistMetaSchema[];
  itemNotExistedPlaylist: PlaylistMetaSchema[];
}

export interface PlaylistMetaSchema {
  id: string;
  title: string;
}
export interface PlaylistItemSchema {
  id: string;
  playlistID: string;
  type: EntityType;
  itemID: string;
  status: EntityStatus;
  sortOrder: number;
  entityData: ResourceSchema;
}

export interface PlaylistItemObjectSchema {
  count: number;
  type: EntityType;
  i18nLabel: string;
}
export interface MarshalledPlaylist {
  id: string;
  title: string;
  description: string;
  groupedItemObject: PlaylistItemObjectSchema[];
  author: Record<string, any>;
  vanityURL: string;
}
export interface PlaylistCardSchema {
  id: string;
  title: string;
  userFullName: string;
  itemObject: PlaylistItemObjectSchema[];
  status: EntityStatus;
}
//////
// Abuse
//////

export const AbuseEntityTypes = [
  EntityType.Comment,
  EntityType.Discussion,
  EntityType.Resource,
  EntityType.Assignment,
] as const;
export type AbuseEntityType = typeof AbuseEntityTypes[number];

export interface AbuseSchema extends BaseRecordSchema {
  id: string;
  entityID: string;
  entityType: AbuseEntityType;
  status: EntityStatus;
  comment: string;
}

export interface MarshalledAbuse {
  id: string;
  entityID: string;
  entityType: AbuseEntityType;
  status: EntityStatus;
  comment: string;
  createdBy: string;
  entity: CommentSchema | DiscussionSchema | MemberSchema | ResourceSchema | AssignmentSchema;
  author: Author;
}

export enum AbuseRuleConsequences {
  notifyAdmins = 'notifyAdmins',
  remove = 'remove',
}

export interface AbuseRuleSchema extends BaseRecordSchema {
  id: string;
  entityType: AbuseEntityType;
  consequence: string;
  maxCount: number;
  status: EntityStatus;
}

//////
// Enrollment Service
//////

export enum EnrollmentStatus {
  enrolled = 'enrolled',
  started = 'started',
  pendingReview = 'pending-review',
  incomplete = 'incomplete',
  passed = 'passed',
  removed = 'removed',
}

export interface EnrollmentSchema extends Timestamps {
  memberID: string;
  courseID: string;
  status: EnrollmentStatus;
  progress: number;
  reviewComment: string;
  lastReviewedAt: string;
  lastReviewedBy: string;
  startedAt: string;
  completedAt: string;
  lastActivityAt: string;
  requestID: string;
}

export type EnrollmentMeta = Record<keyof typeof EnrollmentStatus, boolean>;
export interface MarshalledEnrollment extends EnrollmentSchema {
  meta: EnrollmentMeta;
  member?: Author;
  flagged?: boolean;
}

export interface EnrollmentProgress extends EnrollmentSchema {
  workshops: Pick<MarshalledWorkshop, 'title' | 'meta' | 'vanityURL'>[];
}

//////
// Yukon
//////

export enum YukonConfigEstimatedDuration {
  OneTo2Hours = '1 - 2 Hours',
  TwoTo5Hours = '2 - 5 Hours',
  FiveTo10Hours = '5 - 10 Hours',
  ElevenTo25Hours = '11 - 25 Hours',
  MoreThan25hours = 'More than 25 hours',
}

export enum YukonConfigStage {
  begin = 'begin',
  practice = 'practice',
  master = 'master',
  evolve = 'evolve',
}

export enum YukonConfigLevel {
  beginner = 'beginner',
  intermediate = 'intermediate',
  intermediateAdvanced = 'intermediate-advanced',
  experienced = 'experienced',
}

export interface YukonConfigRelatedApplicationsSapCodes {
  type: 'string';
}

export interface YukonConfigRelatedApplicationsFiCodes {
  type: 'string';
}

export interface YukonConfigDescriptions {
  short: string;
  medium: string;
  long: string;
}

export enum YukonEntityType {
  Resource = 'Resource',
  Course = 'Course',
  Workshop = 'Workshop',
  Member = 'Member',
  Discussion = 'Discussion',
  Group = 'Group',
  Syllabus = 'Syllabus',
  LiveEvent = 'LiveEvent',
  StaticPage = 'StaticPage',
}

export interface YukonConfig {
  title: string;
  estimatedDuration: YukonConfigEstimatedDuration;
  stage: YukonConfigStage;
  level: YukonConfigLevel;
  heroImageURL: string;
  thumbImageURL: string;
  durationInSeconds: string;
  applicationSapCode: string;
  applicationFiCode: string;
  relatedApplicationsSapCodes: YukonConfigRelatedApplicationsSapCodes[];
  relatedApplicationsFiCodes: YukonConfigRelatedApplicationsFiCodes[];
  descriptions: YukonConfigDescriptions;
}

export interface YukonContentConfigSchema extends BaseRecordSchema {
  entityID: string;
  entityType: YukonEntityType;
  siteID: string;
  yukonConfig: YukonConfig;
}

//////
// Assignment Service
//////

export enum LinkBehavior {
  iframe = 'iframe',
  newWindow = 'newWindow',
  currentWindow = 'currentWindow',
}

export interface LinkSchema {
  url: string;
  description: string;
  behavior: LinkBehavior;
  metadata?: LinkMetadata;
  sortOrder?: number;
  id?: string;
  components?: string[];
}

export interface LinkMetadata {
  contentType?: string;
  siteName?: string;
  title?: string;
  video?: string;
  image?: string;
  canonicalURL?: string;
  url?: string;
  imageURL?: string;
  imageSecureURL?: string;
  timestamp?: string;
  isLinkFromAssetBrowser?: boolean;
  isLinkFromProjectXAssetBrowser?: boolean;
  urn?: string;
  ccxAssetType?: string;
  remixable?: boolean;
  removeBranding?: boolean;
  ownerUserId?: string;
  branchURL?: string;
  isProjectXRemixLink?: boolean;
  largeThumbnail?: string;
  gneissAlias?: string;
}

export interface AssignmentSchema extends BaseRecordSchema {
  id: string;
  title: string;
  description: string;
  locked: boolean;
  products: string[];
  links: LinkSchema[];
  status: EntityStatus;
  publishedAt: string;
  subscribed: boolean;
  courseID: string;
  workshopID: string;
  siteID: string;
}

export type MarshalledAssignment = Modify<
  AssignmentSchema,
  {
    author: Author;
    products: ProductSchema[];
    flagged: boolean;
    commentsCount: number;
    files: FileSchema[];
  }
>;

export const ASSIGNMENT_CARD_ATTRIBUTES = [
  'id',
  'title',
  'description',
  'courseID',
  'workshopID',
  'createdBy',
] as const;

export type AssignmentCardSchema = Pick<AssignmentSchema, typeof ASSIGNMENT_CARD_ATTRIBUTES[number]> & {
  author: Author;
  entity: SystemEntityTypes.assignment;
};

export interface AuthorizationSchema {
  id: number;
  sec: string;
  pType: string;
  role: string;
  resource: string;
  action: string;
}

export const FavoriteEntityTypes = [EntityType.Discussion, EntityType.Resource, EntityType.Course] as const;
export type FavoriteEntityType = typeof FavoriteEntityTypes[number];

export interface FavoriteSchema extends BaseRecordSchema {
  id: string;
  entityID: string;
  entityType: FavoriteEntityType;
  status: EntityStatus;
  siteID: string;
}

//////
// GDPR Service
//////

export enum GDPRRoqName {
  imsGDPRRequestEDEX = 'ims_gdpr_request_edex',
  imsGDPRAgeoutsEDEX = 'ims_gdpr_ageouts_edex',
}

export enum GDPRStatus {
  pending = 'pending',
  failed = 'failed',
  completed = 'completed',
}

export type GDPRCompletions = Record<string, boolean>;

export interface GDPRSchema extends Timestamps {
  id: string;
  roq: string;
  membersCount: number;
  irsRequestID: string;
  irsRequestType: string;
  irsRequestStatus: string;
  irsRequestDate: string;
  hoolihanOffset: number;
  completions: GDPRCompletions;
  status: GDPRStatus;
  requestID: string;
}

//////
// Resource Service
//////

export enum ResourceType {
  supplemental = '3e72fa75-e25d-11ea-823a-128594b927d7',
  project = '5f81ac4d-1356-44ee-9fac-7a9a4d2ede25',
}

export enum ResourceTimingI18nLabels {
  lessThanTwoHours = 'i18n.less_than_1_hour',
  oneToTwoHours = 'i18n.1_2_hours',
  threeToFiveHours = 'i18n.3_5_hours',
  sixToTenHours = 'i18n.6_10_hours',
  moreThanTenHours = 'i18n.more_than_10_hours',
}

export interface ResourceTiming {
  id: string;
  name: string;
  i18nLabel: string;
}
interface ResourceStandards<T = string> {
  istenets: T[];
  general: T[];
  custom: string;
}

export interface ResourceRanking {
  k12GE: number;
  k12DM: number;
  hedGE: number;
  hedDM: number;
  search: number;
  total: number;
}

export interface ResourceLinks {
  web: LinkSchema[];
  video: LinkSchema[];
}

export interface ResourceSeo {
  title?: string;
  metaTitle?: string;
  metaDescription?: string;
  noIndex?: boolean;
}

export interface ResourceSettings {
  seo: ResourceSeo;
}

export interface ResourceSchema extends BaseRecordSchema {
  id: string;
  status: EntityStatus;
  vanityURL: string;
  SEOUrl: string;
  title: string;
  secondaryAuthor: {
    name?: string;
    profileURL?: string;
  };
  shortDescription: string;
  description: string;
  type: string;
  products: PrimarySecondary;
  subjects: PrimarySecondary;
  academicLevels: PrimarySecondary;
  tags: string[];
  subscribed: boolean;
  siteID: string;
  copyLicenses: string[];
  timing: ResourceTiming;
  technicalExpertise: string;
  standards: ResourceStandards;
  links: ResourceLinks;
  components: string[];
  heroImage: {
    url: string;
    caption: string;
  };
  ranking: ResourceRanking;
  public: boolean;
  locked: boolean;
  publishedAt: string;
  settings: ResourceSettings;
  favorite: boolean;
  lessonSteps: string[];
}

export type MarshalledResource = Modify<
  ResourceSchema,
  {
    author: Author;
    products: PrimarySecondary<ProductSchema>;
    subjects: PrimarySecondary<SubjectSchema>;
    academicLevels: PrimarySecondary<AcademicLevelSchema>;
    copyLicenses: CopyLicenseSchema[];
    components: ComponentSchema[];
    technicalExpertise: TechnicalExpertiseSchema;
    standards: ResourceStandards<ContentStandardSchema>;
    links: ResourceLinks;
    type: ResourceTypeSchema;
    tags: MarshalledTag[];
    flagged: boolean;
    favorite: boolean;
    myRating: number;
    ratings: RatingSummary;
    favoriteCount: number;
    viewCount: number;
    commentsCount: number;
    files: FileSchema[];
    bundle: FileSchema;
    relatedResources: ResourceCardSchema[];
    lessonSteps: LessonStepsSchema[];
  }
>;

export interface SecondaryAuthor {
  name: string;
  profileURL: string;
}

export const RESOURCE_CARD_ATTRIBUTES = [
  'id',
  'title',
  'description',
  'shortDescription',
  'vanityURL',
  'createdBy',
  'heroImage',
  'links',
  'favorite',
] as const;
export type ResourceCardSchema = Pick<ResourceSchema, typeof RESOURCE_CARD_ATTRIBUTES[number]> & {
  author: Author;
  typeI18nLabel: string;
  academicLevelI18nCategory: string;
  subjectI18nLabel: string;
  entity: SystemEntityTypes.resource;
};

export type CollectionCardSchema = {
  title: string;
  vanityURL: string;
  headerImageColor: string;
  pageTitle?: string;
  id?: string;
};

export type HomepageTRShelfSchema = {
  trShelfResources: ResourceSchema[];
  doodles: DoodleSchema[];
  header: TemplateResult | string;
  link: string;
  section: string;
  descritption?: string;
  browseBtnText: string;
};

export type DoodleSchema = {
  className: string;
  imageSrc: string;
};

export type LIHPPromoSchema = {
  heading: string;
  subheading: string;
  buttonText: string;
  link: string;
  direction: string;
  imageSrc: string;
};

//////
// Announcement Service
//////

export interface AnnouncementSchema extends BaseRecordSchema {
  id: string;
  courseID: string;
  title: string;
  richText: string;
  plainText: string;
  shouldNotifyEnrollees: boolean;
  status: EntityStatus;
  siteID: string;
}

export interface MarshalledAnnouncement extends AnnouncementSchema {
  author: Author;
}

//////
// Course Service
//////

export enum CourseType {
  instructorLed = 'instructor-led',
  selfPaced = 'self-paced',
  toolkit = 'toolkit',
  nonSearchableInstructorLed = 'live-instructor-led',
}

export enum Difficulty {
  beginner = 'beginner',
  intermediate = 'intermediate',
  advanced = 'advanced',
}

export interface CourseAssets {
  images: {
    hero: {
      legacy: string;
      caption: string;
      largeURL: string;
      thumbnail: string;
    };
    landing: { url: string; caption: string };
  };
  survey: { url: string };
  videos: { hero: { url: string; caption: string } };
  certificate: { url: string };
}

export interface CourseSeo {
  title: string;
  metaTitle: string;
  metaDescription: string;
}

export interface CourseSettings {
  points: { perWorkshop: number; perCourseCompletion: number };
  enableAutoGrade: boolean;
  enableHelpAndSupport: boolean;
  enableLearningJournal: boolean;
  seo?: CourseSeo;
}

export interface PrimarySecondary<T = string> {
  primary: T;
  secondary: T[];
}

export interface CourseEducators {
  staff: string[];
  instructors: string[];
}

export enum CourseTheme {
  getStarted = 'Get Started',
  getCreative = 'Get Creative',
  creativeClassroom = 'Creative Classroom',
  designForEducators = 'Design for Educators',
  trainTheTrainer = 'Train the Trainer',
}

export interface CourseSchema extends BaseRecordSchema {
  id: string;
  vanityURL: string;
  siteID: string;
  title: string;
  shortDescription: string;
  description: string;
  status: EntityStatus;
  type: CourseType;
  difficulty: Difficulty;
  settings: CourseSettings;
  assets: CourseAssets;
  theme: CourseTheme;
  tags: string[];
  workshops: string[];
  contentStandards: string[];
  academicLevels: PrimarySecondary;
  products: PrimarySecondary;
  subjects: PrimarySecondary;
  badges: string[];
  forumID: string;
  educators: CourseEducators;
  relatedContent: any;
  enrollmentOpensAt: string;
  startsAt: string;
  enrollmentClosesAt: string;
  publishAt: string;
  closesAt: string;
  forumClosesAt: string;
  graduationAt: string;
  courseTypeID: string;
}

export interface MarshalledCourseEducators {
  staff: Partial<MemberCardSchema>[];
  instructors: Partial<MemberCardSchema>[];
}

export interface PermissionsMap {
  create?: boolean;
  view?: boolean;
  update?: boolean;
  remove?: boolean;
}

export interface CoursePermissions {
  announcements: PermissionsMap;
  enrollments: PermissionsMap;
  courses: PermissionsMap;
  templates: PermissionsMap;
}

export interface CourseMeta {
  enrollment?: EnrollmentMeta;
  duration: number; // hrs
  progress: number; // %
  completed: boolean; // progress === 100
  started: boolean;
  closed: boolean;
  live: boolean; // started AND not closed
  enrollmentLive: boolean; // enrollment is open AND not closed
  canEnroll: boolean;
  forumClosed: boolean;
  lastActivityAt: string;
  enrolledAt: string;
}

export type MarshalledCourse = Modify<
  CourseSchema,
  {
    products: ProductSchema[];
    subjects: SubjectSchema[];
    academicLevels: AcademicLevelSchema[];
    badges: BadgeSchema[];
    contentStandards: ContentStandardSchema[];
    forum: ForumSchema;
    workshops: MarshalledWorkshop[];
    enrollees: number;
    meta: CourseMeta;
    type: CourseType;
    permissions?: CoursePermissions;
    courseType: CourseTypeSchema;
  }
>;

export const COURSE_CARD_ATTRIBUTES = [
  'id',
  'vanityURL',
  'title',
  'shortDescription',
  'assets',
  'startsAt',
  'closesAt',
  'type',
  'badges',
  'enrollees',
  'meta',
  'courseType',
] as const;

export type CourseCardSchema = Pick<MarshalledCourse, typeof COURSE_CARD_ATTRIBUTES[number]> & {
  entity: SystemEntityTypes.course;
  typeI18nLabel: string;
};

export type ExternalCourseCardSchema = {
  updatedAt: string;
  createdAt: string;
  course_type: string;
  title: string;
  published_type: string;
  vanity_url: string;
  status: string;
};

export interface LiveClass {
  TIME: string;
  location: string;
  liveClassURL: string;
  conferenceURL: string;
}

//////
// Workshop Service
//////

export enum WorkshopStepType {
  regular = 'regular',
  assignment = 'assignment',
  review = 'review', // peer-review

  // Legacy types for backward compatibility
  finalAssignment = 'final-assignment',
  finalReview = 'final-review',
}

export enum WorkshopStepItemEntityType {
  textBlock = 'text-block',
  image = 'image',
  hr = 'hr',
  video = 'video',
  link = 'link',
  resource = 'resource',
}

export interface WorkshopStepItem {
  id: string;
  title: string;
  description: string;
  entityID: string;
  entityType: WorkshopStepItemEntityType;
}

export type WorkshopStepMeta = Pick<CourseProgressSchema, 'completed' | 'assignmentID' | 'reviewed'>;

export interface WorkshopStep {
  id: string;
  title: string;
  type: WorkshopStepType;
  items?: WorkshopStepItem[];
  meta?: WorkshopStepMeta;
}

export interface WorkshopSection {
  id: string; // TODO: specify section id when updating step or search by step id in each section
  title: string;
  description: string;
  steps: WorkshopStep[];
}

export interface WorkshopSchema extends BaseRecordSchema {
  id: string;
  vanityURL: string;
  siteID: string; // TODO: confirm removal
  title: string;
  shortDescription: string;
  description: string;
  percentage: number; // weight (for progress % calculation)
  category: string;
  duration: number;
  liveClasses: LiveClass[];
  products: PrimarySecondary;
  subjects: PrimarySecondary;
  academicLevels: PrimarySecondary;
  status: EntityStatus;
  sections: WorkshopSection[];
  startsAt: string;
  publishAt: string;
}

export interface WorkshopMeta {
  started: boolean;
  completed: boolean;
  totalSteps: number;
  stepsCompleted: number;
  progress: number;
  assignmentID?: string;
  assignment?: Partial<AssignmentSchema>;
  assignmentFlagged?: boolean;
  reviewStepCompleted?: boolean;
}

export type MarshalledWorkshop = Modify<
  WorkshopSchema,
  {
    products: ProductSchema[];
    subjects: SubjectSchema[];
    academicLevels: AcademicLevelSchema[];
    meta: WorkshopMeta;
  }
>;

//////
// CourseProgress Service
//////

export interface CourseProgressSchema extends Timestamps {
  workshopStepID: string;
  memberID: string;
  courseID: string;
  workshopID: string;
  completed: boolean;
  assignmentID: string;
  reviewed: string[];
  requestID: string;
}

//////
// StaticPage Service
//////

export interface StaticPageCategory {
  id: string;
  name: string;
}

// https://developers.google.com/search/docs/data-types/faqpage
// https://schema.org/FAQPage
export interface FAQPage {
  '@context': 'https://schema.org/';
  '@type': 'FAQPage';
  mainEntity: Question[];
}

// https://schema.org/Question
export interface Question {
  '@type': 'Question';
  name: string;
  acceptedAnswer: Answer;
}

export interface Answer {
  '@type': 'Answer';
  text: string;
}

export interface StaticPageSchema extends BaseRecordSchema {
  id: string;
  title: string;
  description: string;
  vanityURL: string;
  html: string;
  css: string;
  categories: StaticPageCategory[];
  isSecure: boolean;
  isSearchable: boolean;
  status: EntityStatus;
  publishedAt: string;
  siteID: string;
  wordpressID: number;
  glueID: string;
  faqPage: FAQPage;
}

export interface StaticPageAssets {
  css?: string[];
  js?: string[];
  images?: string[];
}

export interface MarshalledStaticPage extends StaticPageSchema {
  assets?: StaticPageAssets;
  head?: string;
  image?: string;
}

export type ParsedWordpressDocument = Pick<
  MarshalledStaticPage,
  'html' | 'title' | 'description' | 'css' | 'assets' | 'head' | 'image'
>;

export type ParsedGlueDocument = Pick<MarshalledStaticPage, 'html' | 'title' | 'description' | 'assets' | 'image'>;

//////
// Survey Service
//////

export enum SurveyStatus {
  bounced = 'bounced',
  pending = 'pending',
  rejected = 'rejected', // i.e., requests w/invalid email address
  resetPending = 'reset-pending', // old unhandled records w/429 & others put back in the queue
  responded = 'responded',
  scrubbed = 'scrubbed',
  sent = 'sent',
  unsubscribed = 'unsubscribed',
  retry = 'retry',
}

export interface SurveySchema extends BaseRecordSchema {
  id: number;
  memberID: string;
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  joinedOn: string;
  lastActiveAt: string;
  surveyGroupID: number;
  surveyYear: number;
  surveyMonth: number;
  sentAt: string;
  status: SurveyStatus;
  statusAt: string;
}

export interface SurveyCandidateSchema extends BaseRecordSchema {
  memberID: string;
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  joinedOn: string;
  lastActiveAt: string;
}
export interface SurveyResponseSchema extends BaseRecordSchema {
  id: number;
  sequenceID: number;
  eventType: string;
  eventID: string;
  surveyType: string;
  score: number;
  comment: string;
  permaLink: string;
  sourceCreatedAt: number;
  sourceUpdatedAt: number;
  personProperties: PersonProperties;
  notes: any[];
  tags: string[];
  memberID: string;
  source: string;
}
export interface PersonProperties {
  sequence_id: string;
  edex_id: string;
  sent_to_email: string;
  survey_group_no: string;
  survey_year_month: string;
  delighted_email_subject?: string;
  original_email?: string;
  locale: string;
  thank_you_message?: string;
  thank_you_link_url?: string;
  thank_you_link_text?: string;
  'Delighted Device Type'?: string;
  'Delighted Operating System'?: string;
  'Delighted Browser'?: string;
}

export interface MemberInviteSchema extends BaseRecordSchema {
  id: string;
  memberID?: string;
  siteID: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface MailedItSubscriber {
  first_name: string;
  last_name: string;
  email_address: string;
  external_id: string;
  joined_at: string;
  timezone: number;
}

export interface CCXTemplateLinkValidity {
  url: string;
  components: string[];
  isLinkFromAssetBrowser: boolean;
  isLinkFromProjectXAssetBrowser: boolean;
  isProjectXRemixLink?: boolean;
}
