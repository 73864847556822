import { createSelector } from 'reselect';
import { keyBy } from 'lodash-es';

import { EdexState } from '@adobe/edex/ui/store/store';

const selectApi = (state: EdexState) => state.api;
const selectForms = (state: EdexState) => state.forms;
export const selectViewMode = (state: EdexState) => state.viewMode;
export const selectDialog = (state: EdexState) => state.dialog;
export const selectLocation = (state: EdexState) => state.location;
export const selectSearch = (state: EdexState) => state.search;
export const selectPreferences = (state: EdexState) => state.preferences;
export const selectPlaylist = (state: EdexState) => state.playlist;
export const selectSelf = (state: EdexState) => state.self;

export const selectLocationSearch = createSelector(selectLocation, ({ search }) => search);
export const selectLocationPathname = createSelector(selectLocation, ({ pathname }) => pathname);
export const selectUser = createSelector(selectApi, ({ self }) => self.data);
export const selectApiSelfLoading = createSelector(selectApi, ({ self }) => !!self.loading);

export const selectSelfIsFulfilled = createSelector(selectApi, ({ self }) => self.isFulfilled);

export const selectResourceApiState = createSelector(selectApi, ({ resource }) => resource);

export const selectResource = createSelector(selectResourceApiState, ({ data }) => data);

export const selectResourceLoading = createSelector(selectResourceApiState, ({ loading }) => loading);

export const selectResourceError = createSelector(selectApi, ({ resource }) => resource.error);

export const selectComments = createSelector(selectApi, ({ comments }) => comments);

export const selectMetadata = createSelector(selectApi, ({ metadata }) => metadata.byType);

export const selectProducts = createSelector(selectMetadata, ({ products }) => products);

export const selectProductsByVanity = createSelector(selectProducts, (products) => {
  return keyBy(products, 'vanityURL');
});

export const selectResourceForm = createSelector(selectForms, ({ resource }) => resource);

export const selectResourceFormFocus = createSelector(selectResourceForm, ({ guideFocus }) => guideFocus);

export const selectResourceFormData = createSelector(selectResourceForm, ({ data }) => data);

export const selectCommentForm = createSelector(selectForms, ({ comment }) => comment);

export const selectShowCreateForm = createSelector(selectCommentForm, ({ data }) =>
  Boolean(data && !(data.id || data.parentCommentID))
);
