import { html, customElement } from '@adobe/edex/ui/edex-lit';
import { EdexTagName } from '@adobe/edex/ui/shared/constants';
import styles from '@adobe/edex/ui/components/shared/loader/loader.lit.scss';
import { BaseElement } from '@adobe/edex/ui/components/base.element';

@customElement(EdexTagName.loader)
export class Loader extends BaseElement {
  static styles = styles;
  render() {
    return html`
      <sp-progress-circle indeterminate></sp-progress-circle>
    `;
  }
}
