import * as Cookies from 'tiny-cookie';
import * as FullStory from '@fullstory/browser';

import { NX_ADOBE_PRIVACY_SCRIPT, NX_COOKIE_DOMAIN, NX_ONETRUST_DOMAIN_ID } from '@env/environment';
import { cdn } from '@adobe/edex/ui/shared/utils/cdn';
import { IS_ONETRUST_PROD } from '@adobe/edex/ui/shared/constants';
import { FullStoryOptions, FullStoryUtils } from './fullstory';
import { isEduUser } from '../utils';

const ONETRUST_COOKIE = 'EDEX_EVIDON';
const ONETRUST_COOKIE_SETTINGS = {
  expires: 1,
  secure: true,
  domain: NX_COOKIE_DOMAIN,
};

declare global {
  interface Window {
    fedsConfig?: {
      privacy?: {
        otDomainId?: string;
        footerLinkSelector?: string;
      };
    };
    adobePrivacy?: {
      hasUserProvidedCustomConsent?: <T = unknown, R = unknown>(args?: T) => R;
      hasUserProvidedConsent?: <T = unknown, R = unknown>(args?: T) => R;
      loadResource: <T = unknown, R = unknown>(args?: T) => R;
    };
  }
}

/**
 * Before we load IMS, 3rd party scripts, or cookies we must get User Consent
 * The following class does all the checks needed before loading everything else
 */
export class EdexOneTrust {
  static consentGiven() {
    return Cookies.get(ONETRUST_COOKIE);
  }

  static bootstrap() {
    // Configure the OneTrust settings
    window.fedsConfig = window.fedsConfig || {}; // We don't want to override this nor .privacy if they already exist
    window.fedsConfig.privacy = window.fedsConfig.privacy || {};
    window.fedsConfig.privacy.otDomainId = NX_ONETRUST_DOMAIN_ID;
    window.fedsConfig.privacy.footerLinkSelector = '.onetrust-notice-link';

    // Inject the script always AFTER the vue app is loaded so the footer element exists
    const cmpScript = document.createElement('script');
    cmpScript.async = true;
    cmpScript.defer = true;
    cmpScript.src = NX_ADOBE_PRIVACY_SCRIPT;
    cmpScript.onload = () => {
      if (EdexOneTrust.consentGiven()) {
        this.enableTracking();
      }
      // No need to else, by the time consent is given, the window.adobePrivacy object will exist
    };

    // Only add it if it doesn't exist yet
    if (!document.querySelector(`script[src="${NX_ADOBE_PRIVACY_SCRIPT}"]`)) {
      document.head.appendChild(cmpScript);

      /* Fix the Optanon Cookie to flip to NOT track using Personalize Advertising cookies */
      cmpScript.onload = () => {
        setTimeout(async function () {
          if (await isEduUser()) {
            EdexOneTrust.setC0004CookieTo(false);
          } else {
            EdexOneTrust.setC0004CookieTo(true);
          }
        }, 1000);
      };
    }

    if (EdexOneTrust.consentGiven()) {
      this.enableTracking();
    } else {
      window.addEventListener('adobePrivacy:PrivacyConsent', this.handleConsentSettings);
      window.addEventListener('adobePrivacy:PrivacyReject', this.handleConsentSettings);
      window.addEventListener('adobePrivacy:PrivacyCustom', this.handleConsentSettings);
    }
  }

  static handleConsentSettings() {
    try {
      if (!window.adobePrivacy || window.adobePrivacy.hasUserProvidedCustomConsent()) {
        EdexOneTrust.removeConsent();
        return;
      }

      if (window.adobePrivacy.hasUserProvidedConsent()) {
        EdexOneTrust.giveConsent();
        EdexOneTrust.enableTracking();
      } else {
        EdexOneTrust.removeConsent();
      }
    } catch (e) {
      EdexOneTrust.removeConsent();
    }
  }

  static removeConsent() {
    Cookies.remove(ONETRUST_COOKIE);
  }

  static giveConsent() {
    Cookies.set(ONETRUST_COOKIE, '1', ONETRUST_COOKIE_SETTINGS);
  }

  static enableTracking = (() => {
    let executed = false;
    // initOnce pattern, in a closure so nothing outside can change the value
    return () => {
      if (!executed) {
        executed = true;
        FullStoryUtils.getInstance().consentGiven();

        // Load 3rd party tracking - Always cache busts via `v` url param in case Matt makes changes
        if (IS_ONETRUST_PROD) {
          // Initialize fullstory ONLY after user gives consent
          FullStory.init(FullStoryOptions);

          if (window.adobePrivacy) {
            window.adobePrivacy.loadResource({
              path: `${cdn}/assets/v3/tracking.js?v=${Math.floor(Date.now() / 1000)}`,
              type: 'script',
              async: true,
            });
          }
        }
      }
    };
  })();

  static setC0004CookieTo(value: boolean) {
    try {
      const optanonConsent = Cookies.getCookie('OptanonConsent');
      if (optanonConsent && optanonConsent.indexOf('C0004:' + Number(!value)) !== -1) {
        const optanonConsentFixed = optanonConsent.replace('C0004:' + Number(!value), 'C0004:' + Number(value));
        const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        Cookies.setCookie('OptanonConsent', optanonConsentFixed, decodeURI, {
          expires: oneYearFromNow.toString(),
          domain: '.adobe.com',
          path: '/',
        });
      }
    } catch (e) {
      console.warn('Error while flipping Optanon Cookie ');
    }
  }
}
