import { html, customElement } from '@adobe/edex/ui/edex-lit';
import { EdexTagName, EdexDomID, RoutePath } from '@adobe/edex/ui/shared/constants';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-home';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-help';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-classic-grid-view';
import { BaseElement } from '@adobe/edex/ui/components/base.element';
import { buildLink } from '@adobe/edex/ui/shared/utils/routes';

import(
  /* webpackChunkName: "component/not-found-element-styles" */
  /* webpackMode: "lazy" */
  '@adobe/edex/ui/components/not-found/not-found.scss'
);

@customElement(EdexTagName.notFound)
export class NotFound extends BaseElement {
  get useLightDom() {
    return true;
  }

  static get properties() {
    return {
      ...super.properties,
      playlistNotFound: { attribute: false },
    };
  }

  playlistNotFound = false;

  renderErrorMessage() {
    const { playlistNotFound } = this;
    return playlistNotFound
      ? html`
          <p class="text-center">${$t(I18nKey.errorPlaylistNotFoundMessage)}</p>
        `
      : html`
          <p class="text-center">${$t(I18nKey.errorNotFoundMessage)}</p>
        `;
  }

  render() {
    return html`
      <div id="${EdexDomID.notFound}" class="not-found-page">
        <div class="primary-container">
          <div class="primary-header">
            <h1 class="text-center  error-title">404:${$t(I18nKey.errorNotFound)}</h1>
            ${this.renderErrorMessage()}
          </div>
          <div class="parent-row">
            <div class="primary-row">
              <div class="substance">
                <sp-link href="${buildLink('/')}" rel="external" target="_top" quiet>
                  <sp-icon size="xxl"><sp-icon-home></sp-icon-home></sp-icon>
                  <div class="header">${$t(I18nKey.errorNotFoundHomepage)}</div>
                  <div class="desc">${$t(I18nKey.errorNotFoundToHome)}</div>
                </sp-link>
              </div>
              <div class="substance">
                <sp-link href="/help-center/" rel="external" target="_top" quiet>
                  <sp-icon size="xxl"><sp-icon-help></sp-icon-help></sp-icon>
                  <div class="header">${$t(I18nKey.errorNotFoundHelpCenter)}</div>
                  <div class="desc">${$t(I18nKey.errorNotFoundFindAnswers)}</div>
                </sp-link>
              </div>
              <div class="substance">
                <sp-link href="${RoutePath.dashboard}" rel="external" target="_top" quiet>
                  <sp-icon size="xxl">
                    <sp-icon-classic-grid-view></sp-icon-classic-grid-view>
                  </sp-icon>
                  <div class="header">${$t(I18nKey.errorNotFoundDashboard)}</div>
                  <div class="desc">${$t(I18nKey.errorNotFoundManageResources)}</div>
                </sp-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
