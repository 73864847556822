import { mapValues, sortBy } from 'lodash-es';
import { fetchMetadata } from '@adobe/edex/ui/store/routines';
import {
  AcademicLevelSchema,
  MetadataModelKey,
  ProductSchema,
  ResourceTypeSchema,
  SubjectSchema,
  CopyLicenseSchema,
  ComponentSchema,
  ContentStandardSchema,
  CourseTypeSchema,
  TimingSchema,
  ProductCountSchema,
  DurationSchema,
  LessonStepsSchema,
  SchoolDistrictSchema,
} from '@adobe/edex/ui/types';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';

export interface MetadataState {
  loading: boolean;
  byType: Partial<{
    [MetadataModelKey.resourceTypes]: ResourceTypeSchema[];
    [MetadataModelKey.academicLevels]: AcademicLevelSchema[];
    [MetadataModelKey.subjects]: SubjectSchema[];
    [MetadataModelKey.products]: ProductSchema[];
    [MetadataModelKey.productUsedCount]: ProductCountSchema[];
    [MetadataModelKey.timing]: TimingSchema[];
    [MetadataModelKey.copyLicenses]: CopyLicenseSchema[];
    [MetadataModelKey.components]: ComponentSchema[];
    [MetadataModelKey.contentStandards]: ContentStandardSchema[];
    [MetadataModelKey.courseType]: CourseTypeSchema[];
    [MetadataModelKey.duration]: DurationSchema[];
    [MetadataModelKey.lessonSteps]: LessonStepsSchema[];
    [MetadataModelKey.schoolDistricts]: SchoolDistrictSchema[];
  }>;
}

const initialState: MetadataState = Object.freeze({
  loading: false,
  byType: {},
});

export function metadata(state = initialState, action: EdexAction<Record<MetadataModelKey, any>>): MetadataState {
  switch (action.type) {
    case fetchMetadata.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case fetchMetadata.SUCCESS:
      return {
        ...state,
        byType: mapValues(
          {
            ...state.byType,
            ...action.payload,
          },
          (items) => items && sortBy(items, (i) => i['sortOrder'])
        ),
      };
    case fetchMetadata.FAILURE:
      return state;
    case fetchMetadata.FULFILL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
