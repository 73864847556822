import axios from 'axios';
import { NX_CDN_HOST, NX_VERSION } from '@env/environment';
import { configureLocalization, msg, unsafeHTML } from '@adobe/edex/ui/edex-lit';
import { EDEX_LOCALE_LS_KEY, Locale, LOCALES } from '@adobe/edex/ui/shared/constants';
import en from '@adobe/edex/ui/shared/i18n/en-us.json';

export const $t = (i18nKey, interpolate?) => {
  let translated;
  try {
    i18nKey = i18nKey || '';
    translated = msg(i18nKey, { id: i18nKey });
    // use fallback to en-us.json, ie when local has not been initialized yet (ajax call is pending or error)
    if (translated === i18nKey) {
      translated = en[i18nKey] || i18nKey;
    }
    if (interpolate) {
      Object.entries(interpolate).forEach(([key, value]) => {
        translated = translated.replace(new RegExp(`{${key}}`, `gm`), String(value));
      });
    }
  } catch (e) {
    console.error('Translation failure', e);
  }
  return translated;
};
export const getTranslation = $t;
export const translateUnsafeHTML = (...args: Parameters<typeof $t>) => {
  const translated = $t(...args);
  return unsafeHTML(translated);
};

// temporary variable, will be removed once translations
// for all locales are available.
// refer to https://wiki.corp.adobe.com/display/CCEducation/GLaaS+integration
// the GLaaS translation files reside in the UI repo and are copied to S3 during
// deployment via CircleCI. See .circleci in UI.
// ui/libs/shared/src/lib/i18n
const USE_GLAAS = true;

const CDN_I18N_FOLDER = 'v3/i18n';
const CDN_I18N_PATH = `${NX_CDN_HOST}/${CDN_I18N_FOLDER}`;
const CDN_I18N_GLAAS_FOLDER = 'v3/i18n2';
const CDN_I18N_GLAAS_PATH = `${NX_CDN_HOST}/${CDN_I18N_GLAAS_FOLDER}`;

export const getSessionLocale = () => localStorage.getItem(EDEX_LOCALE_LS_KEY) as Locale;
export const setSessionLocale = (locale: string) => localStorage.setItem(EDEX_LOCALE_LS_KEY, locale);

const i18nClient = axios.create({
  baseURL: USE_GLAAS ? CDN_I18N_GLAAS_PATH : CDN_I18N_PATH,
});

const loaders = {};
export const getLoader = (locale: Locale) => {
  if (loaders[locale]) {
    return loaders[locale];
  }
  const locLoader = (loc) => {
    return i18nClient
      .get(`${loc}.json`, { params: { v: NX_VERSION } })
      .then(({ data }) => data)
      .catch(() => null);
  };
  // with GLaaS, there are 2 jsons for each locale:
  // 1. the main translations file, ex, en-us.json
  // 2. the urls file, ex, en-us-urls.json.
  if (USE_GLAAS) {
    const map = { en: 'en-us', jp: 'ja-jp', de: 'de-de' };
    loaders[locale] = Promise.all([locLoader(map[locale]), locLoader(`${map[locale]}-urls`)])
      .then(([trans, urls]) => ({ ...trans, ...urls }))
      .catch(() => null);
  } else {
    loaders[locale] = locLoader(locale);
  }
  return loaders[locale];
};

const isValidLocale = (locale: Locale) => LOCALES.includes(locale);

export const getUrlLocale = (pathname: string): Locale | undefined => {
  const regex = new RegExp(`^/(?<urlLocale>(${Object.values(Locale).join('|')}))(/|$)`);
  return regex.exec(pathname)?.groups?.urlLocale as Locale;
};

export const { initWcsLocale, getCurrentLocale } = (() => {
  let localization: ReturnType<typeof configureLocalization>;

  async function initWcsLocale() {
    const { location } = window;
    const { pathname, origin, search } = location;
    const urlLocale = getUrlLocale(pathname);

    let redirect = false;
    const sessionLocale = getSessionLocale();
    let locale = urlLocale || sessionLocale || Locale.en;

    // sync session locale
    if (sessionLocale && locale !== sessionLocale) {
      setSessionLocale(isValidLocale(locale) ? locale : '');
    }

    // sync url locale
    if (!urlLocale && locale !== Locale.en) {
      redirect = true;
    }

    // handle invalid url locale
    else if (urlLocale && !isValidLocale(locale)) {
      redirect = true;
      locale = Locale.en;
    }

    // omit if `en`
    else if (urlLocale && locale === Locale.en) {
      redirect = true;
    }

    if (redirect) {
      const normalizedLocale = locale === Locale.en ? '' : locale;
      const noLocalePathname = pathname.replace(new RegExp(`^(/${urlLocale})`), '');
      const normalizedPathname = normalizedLocale ? `/${normalizedLocale}${noLocalePathname}` : noLocalePathname;
      location.replace(`${origin}${normalizedPathname}${search}`);
    } else {
      localization = configureLocalization({
        sourceLocale: null,
        targetLocales: LOCALES,
        loadLocale: async (locale) => {
          const templates = await getLoader(locale as Locale);
          return {
            templates,
          };
        },
      });
      await localization.setLocale(locale);
    }
  }

  const getCurrentLocale = () => (localization?.getLocale() as Locale) || Locale.en;

  return { initWcsLocale, getCurrentLocale };
})();
