import { channel, Channel } from 'redux-saga';
import { call, fork, take, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { fetchMetadata } from '@adobe/edex/ui/store/routines';
import { v3requestRoutine } from '@adobe/edex/ui/store/sagas/helpers';
import { metadataAPI } from '@adobe/edex/ui/api';

function* fetchMetadataByTypeWorker(action: AnyAction) {
  yield* v3requestRoutine({
    routine: fetchMetadata,
    api: metadataAPI,
    config: {
      method: 'GET',
      url: '',
      params: {
        types: action.payload.type,
      },
    },
  });
}

function* fetchMetadataByTypeHandler(chan: Channel<any>) {
  yield takeLeading(chan, fetchMetadataByTypeWorker);
}

export function* fetchMetadataByTypeWatcher() {
  const channels: Record<string, Channel<any>> = {};
  while (true) {
    const action = yield take(fetchMetadata.TRIGGER);
    const {
      payload: { type },
    } = action;
    if (!channels[type]) {
      channels[type] = yield call(channel);
      yield fork(fetchMetadataByTypeHandler, channels[type]);
    }
    yield channels[type].put(action);
  }
}
