import { isEmpty } from 'lodash-es';

import {
  BLUE_HEADER_IMAGES,
  GREEN_HEADER_IMAGES,
  PINK_HEADER_IMAGES,
  SALMON_HEADER_IMAGES,
  YELLOW_HEADER_IMAGES,
  COLOR,
} from '@adobe/edex/ui/components/search-page/constants';

// modifying the response for color mapping
export function modifyResponse(payload) {
  const {
    blueHeaderImages,
    pinkHeaderImages,
    greenHeaderImages,
    yellowHeaderImages,
    salmonHeaderImages,
  } = buildColorArray();
  return payload.data.map((v) => {
    const color = v.headerImageColor ? v.headerImageColor.toLowerCase() : '';
    let colorToBeAssigned: any;
    colorToBeAssigned = BLUE_HEADER_IMAGES.blueCircle;
    if (color.includes(COLOR.blue)) {
      if (!isEmpty(blueHeaderImages)) {
        colorToBeAssigned = blueHeaderImages.pop();
      } else {
        colorToBeAssigned = getRandomizedColor(getBlueHeaderImages());
      }
    } else if (color.includes(COLOR.pink)) {
      if (!isEmpty(pinkHeaderImages)) {
        colorToBeAssigned = pinkHeaderImages.pop();
      } else {
        colorToBeAssigned = getRandomizedColor(getPinkHeaderImages());
      }
    } else if (color.includes(COLOR.green)) {
      if (!isEmpty(greenHeaderImages)) {
        colorToBeAssigned = greenHeaderImages.pop();
      } else {
        colorToBeAssigned = getRandomizedColor(getGreenHeaderImages());
      }
    } else if (color.includes(COLOR.yellow)) {
      if (!isEmpty(yellowHeaderImages)) {
        colorToBeAssigned = yellowHeaderImages.pop();
      } else {
        colorToBeAssigned = getRandomizedColor(getYellowHeaderImages());
      }
    } else if (color.includes(COLOR.salmon)) {
      if (!isEmpty(salmonHeaderImages)) {
        colorToBeAssigned = salmonHeaderImages.pop();
      } else {
        colorToBeAssigned = getRandomizedColor(getSalmonHeaderImages());
      }
    }
    return Object.assign(v, { headerImageColor: colorToBeAssigned });
  });

  function getRandomizedColor(colorArray) {
    const randomIndex = getRandomInt(colorArray.length);
    return colorArray[randomIndex];
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  /* order of array elements will determine
        the randomness of the colors in 
        collection card
    */
  function getBlueHeaderImages() {
    return [
      BLUE_HEADER_IMAGES.blueArrow,
      BLUE_HEADER_IMAGES.blueBlob,
      BLUE_HEADER_IMAGES.blueTriangle,
      BLUE_HEADER_IMAGES.blueCircle,
    ];
  }

  function getPinkHeaderImages() {
    return [
      PINK_HEADER_IMAGES.pinkCircle,
      PINK_HEADER_IMAGES.pinkArrow,
      PINK_HEADER_IMAGES.pinkBlob,
      PINK_HEADER_IMAGES.pinkTriangle,
    ];
  }

  function getGreenHeaderImages() {
    return [
      GREEN_HEADER_IMAGES.greenTriangle,
      GREEN_HEADER_IMAGES.greenCircle,
      GREEN_HEADER_IMAGES.greenArrow,
      GREEN_HEADER_IMAGES.greenBlob,
    ];
  }

  function getYellowHeaderImages() {
    return [
      YELLOW_HEADER_IMAGES.yellowBlob,
      YELLOW_HEADER_IMAGES.yellowTriangle,
      YELLOW_HEADER_IMAGES.yellowCircle,
      YELLOW_HEADER_IMAGES.yellowArrow,
    ];
  }

  function getSalmonHeaderImages() {
    return [
      SALMON_HEADER_IMAGES.salmonArrow,
      SALMON_HEADER_IMAGES.salmonBlob,
      SALMON_HEADER_IMAGES.salmonTriangle,
      SALMON_HEADER_IMAGES.salmonCircle,
    ];
  }

  function buildColorArray() {
    return {
      blueHeaderImages: getBlueHeaderImages(),
      pinkHeaderImages: getPinkHeaderImages(),
      greenHeaderImages: getGreenHeaderImages(),
      yellowHeaderImages: getYellowHeaderImages(),
      salmonHeaderImages: getSalmonHeaderImages(),
    };
  }
}
