import { routerLocationChanged } from '@adobe/edex/ui/store/routines';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { router, RouterLocation } from '@adobe/edex/ui/config/router';

export function location(state = router.location, action: EdexAction<RouterLocation>): RouterLocation {
  switch (action.type) {
    case routerLocationChanged.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
