export const preloadImage = (src: string, onload?: (img: HTMLImageElement) => boolean) =>
  new Promise<boolean>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(onload ? onload(img) : true);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = src;
  });

export const WebpSupport: {
  on: boolean;
  init: () => Promise<boolean>;
} = (() => {
  /**
   * https://developers.google.com/speed/webp/faq#in_your_own_javascript
   */
  const lossyImg = 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
  let on = false;
  return Object.freeze({
    get on() {
      return on;
    },
    init: async () => {
      on = await preloadImage('data:image/webp;base64,' + lossyImg, (img) => img.width > 0 && img.height > 0);
      return on;
    },
  });
})();

/***
 * in:   https://cdn.edex.adobe.com/path/to/original/image.jpg, 100, 100
 * out:  https://cdn.edex.adobe.com/path/to/original/100x100/webp/image.jpg
 */
export const getResizedImageURL = (url: string, w: number, h = 0) => {
  const canResize = url && url.includes('cdn.edex.adobe.com');
  const format = WebpSupport.on ? 'webp' : 'jpeg';
  return canResize ? url.trim().replace(/\/([^/]+)$/, `/${w}x${h}/${format}/$1`) : url;
};
