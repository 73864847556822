import { customElement, html, TemplateResult } from '@adobe/edex/ui/edex-lit';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-globe-grid';

import styles from './languageSelector.lit.scss';
import { EdexTagName } from '@adobe/edex/ui/shared/constants';
import { Locale } from '@adobe/edex/ui/shared/constants';
import { getCurrentLocale, setSessionLocale } from '@adobe/edex/ui/shared/config/i18n';
import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';

const localeLink = (locale: Locale) => `/${locale === Locale.en ? '' : locale}`;
@customElement(EdexTagName.languageSelector)
export class LanguageSelector extends BaseElement {
  static styles = styles;

  private static items = [
    { locale: Locale.en, label: 'English' },
    { locale: Locale.jp, label: '日本語' },
    { locale: Locale.de, label: 'Deutsch' },
  ];

  get currentLocale() {
    return getCurrentLocale();
  }

  handleLocaleChange = (locale: Locale) => async (event: Event) => {
    event.preventDefault();
    if (locale === this.currentLocale) {
      return;
    }
    setSessionLocale(locale);
    window.location.href = localeLink(locale);
  };

  render(): TemplateResult {
    const { currentLocale, handleLocaleChange } = this;
    const { items } = LanguageSelector;
    return html`
      <sp-action-menu size="s" value=${currentLocale}>
        <slot name="icon" slot="icon">
          <sp-icon size="s"><sp-icon-globe-grid></sp-icon-globe-grid></sp-icon>
        </slot>
        <slot name="label" slot="label"></slot>
        ${items.map(
          ({ locale, label }) => html`
            <sp-menu-item
              value=${locale}
              ?selected=${locale === currentLocale}
              quiet
              href=${localeLink(locale)}
              @click=${handleLocaleChange(locale)}
            >
              ${label}
            </sp-menu-item>
          `
        )}
      </sp-action-menu>
    `;
  }
}
