import { customElement, html } from '@adobe/edex/ui/edex-lit';

import { EdexAuth } from '@adobe/edex/ui/shared/config/auth';
import { AdobeAnalyticsEvent, EdexTagName } from '@adobe/edex/ui/shared/constants';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { Analytics } from '@adobe/edex/ui/shared/config/analytics';
import '@adobe/edex/ui/shared/components/apps-grid/apps-grid';

import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { getAssetURL } from '@adobe/edex/ui/utils';
import './signUp-dialog.scss';

@customElement(EdexTagName.signUpDialog)
export class SignUpDialog extends BaseElement {
  get useLightDom() {
    return true;
  }

  static get properties() {
    return {
      signUpDialogOpen: { type: Boolean },
    };
  }
  signUpDialogOpen = false;

  async handleCloseDialog() {
    this.signUpDialogOpen = false;
    this.dispatchEvent(new Event('close'));
  }

  async handleSignInClick(e) {
    e.preventDefault();
    this.dispatchEvent(new Event('handleDialogCTAclick'));
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signin, 'SignUp Modal signIn click');
    EdexAuth.signIn();
  }

  async handleSignUpClick(e, signUpType) {
    e.preventDefault();
    this.dispatchEvent(new Event('handleDialogCTAclick'));
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.signup, `SignUp Modal signUp with ${signUpType} email click`);
    EdexAuth.signUp();
  }

  render() {
    if (!this.signUpDialogOpen) {
      return null;
    } else {
      return html`
        <edex-dialog-next .divider=${false} mode="fullscreen" size="${'undefined'}">
          <div id="signUpDialogContent" slot="content">
            <sp-action-button quiet class="fs-close-signUpModel" @click=${this.handleCloseDialog}>
              <sp-icon size="m" slot="icon">
                <sp-icon-close></sp-icon-close>
              </sp-icon>
            </sp-action-button>
            <div class="main-container">
              <div class="left-section">
                <img class="hero-logo-img-popup" src="${getAssetURL('LadywPenAdobeLogo.png')}" />
              </div>
              <div class="right-section">
                <h1>${$t(I18nKey.signUpDialogHeading)}</h1>
                <ul>
                  <li>${$t(I18nKey.signUpDialogContentLine1)}</li>
                  <li>${$t(I18nKey.signUpDialogContentLine2)}</li>
                  <li>${$t(I18nKey.signUpDialogContentLine3)}</li>
                </ul>
                <p>${$t(I18nKey.signUpDialogDescription)}</p>
                <div class="sign-cta">
                  <div class="left-cta-section">
                    <sp-button
                      class="fs-personalEmail-cta"
                      variant="primary"
                      size="m"
                      @click=${(e) => this.handleSignUpClick(e, 'personal')}
                    >
                      ${$t(I18nKey.signUpDialogPersonalBtn)}
                    </sp-button>
                  </div>
                  <div class="right-cta-section">
                    <sp-button
                      class="fs-schoolEmail-cta"
                      variant="cta"
                      size="m"
                      @click=${(e) => this.handleSignUpClick(e, 'school')}
                    >
                      ${$t(I18nKey.signUpDialogSchoolBtn)}
                    </sp-button>
                  </div>
                </div>
                <div class="sign-in-section">
                  ${$t(I18nKey.alreadyHaveAnAccount)}
                  <a class="sign-in-path" href="#" @click=${(e) => this.handleSignInClick(e)}>
                    ${$t(I18nKey.navSignIn)}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </edex-dialog-next>
      `;
    }
  }
}
