import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  deleteResource,
  fetchRelatedResources,
  fetchResource,
  rateResource,
  reportResource,
  saveResource,
  createResource,
  unReportResource,
  unSaveResource,
  updateResource,
  fetchPreferences,
  savePreferences,
} from '@adobe/edex/ui/store/routines';
import { v3requestRoutine } from '@adobe/edex/ui/store/sagas/helpers';
import {
  CustomNotificationVariant,
  EntityType,
  NotificationPlacement,
  NotificationVariant,
} from '@adobe/edex/ui/types';
import {
  CreateResourceActionPayload,
  FetchRelatedResourcesPayload,
  IdActionPayload,
  RateResourceActionPayload,
  UpdateResourceActionPayload,
} from '@adobe/edex/ui/types/payload';
import { abuseAPI, favoriteAPI, ratingAPI, resourceAPI } from '@adobe/edex/ui/api';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';
import { customToast, infoToast } from '@adobe/edex/ui/utils/notification';
import { isAllid } from '../../utils';

const fetchResourceWorker = (routine) =>
  function* (action: EdexAction<IdActionPayload>) {
    yield* v3requestRoutine({
      routine,
      api: resourceAPI,
      config: {
        method: 'GET',
        url: action.payload.id,
      },
    });
  };

function* fetchRelatedResourcesWorker(action: EdexAction<FetchRelatedResourcesPayload>) {
  yield* v3requestRoutine({
    routine: fetchRelatedResources,
    api: resourceAPI,
    config: {
      method: 'GET',
      url: '/related',
      params: action.payload,
    },
  });
}

function* createResourceWorker(action: EdexAction<CreateResourceActionPayload>) {
  yield* v3requestRoutine({
    routine: createResource,
    api: resourceAPI,
    config: {
      method: 'POST',
      data: action.payload.data,
    },
  });
}

function* updateResourceWorker(action: EdexAction<UpdateResourceActionPayload>) {
  yield* v3requestRoutine({
    routine: updateResource,
    api: resourceAPI,
    config: {
      method: 'PUT',
      url: action.payload.id,
      data: action.payload.data,
    },
    onSuccess: action.payload.onSuccess,
  });
}

function* deleteResourceWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: deleteResource,
    api: resourceAPI,
    config: {
      method: 'DELETE',
      url: action.payload.id,
    },
  });
}

function* reportResourceWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: reportResource,
    api: abuseAPI,
    config: {
      method: 'POST',
      data: {
        entityType: EntityType.Resource,
        entityID: action.payload.id,
      },
    },
    onSuccess: () => infoToast({ message: I18nKey.reported }),
  });
}

function* unReportResourceWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: unReportResource,
    api: abuseAPI,
    config: {
      method: 'DELETE',
      url: `/${EntityType.Resource}/${action.payload.id}`,
    },
  });
}

function* saveResourceWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: saveResource,
    api: favoriteAPI,
    config: {
      method: 'POST',
      url: `/${EntityType.Resource}/${action.payload.id}`,
    },
  });
}

function* unSaveResourceWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: unSaveResource,
    api: favoriteAPI,
    config: {
      method: 'DELETE',
      url: `/${EntityType.Resource}/${action.payload.id}`,
    },
  });
}

function* rateResourceWorker(action: EdexAction<RateResourceActionPayload>) {
  yield* v3requestRoutine({
    routine: rateResource,
    api: ratingAPI,
    config: {
      method: 'POST',
      data: {
        entityType: EntityType.Resource,
        entityID: action.payload.id,
        rating: action.payload.rating,
      },
    },
    onSuccess: () =>
      infoToast({
        message: I18nKey.rated,
      }),
  });
}

function* fetchPreferencesWorker() {
  yield* v3requestRoutine({
    routine: fetchPreferences,
    api: resourceAPI,
    config: {
      method: 'GET',
      url: `/personalization/preference`,
      params: { limit: 8, offset: 0, sort: 'ranked' },
    },
  });
}

function* savePreferencesWorker(action: any) {
  yield* v3requestRoutine({
    routine: savePreferences,
    api: resourceAPI,
    config: {
      method: 'POST',
      url: `/personalization/preference`,
      data: {
        sb: action.payload.subjects.filter((s: string) => !isAllid(s)),
        ag: action.payload.academicLevels.filter((l: string) => !isAllid(l)),
      },
      params: { limit: 8 },
    },
  });
}

export function* fetchResourceWatcher() {
  yield takeEvery(fetchResource.TRIGGER, fetchResourceWorker(fetchResource));
}

export function* fetchRelatedResourcesWatcher() {
  yield takeLatest(fetchRelatedResources.TRIGGER, fetchRelatedResourcesWorker);
}

export function* createResourceWatcher() {
  yield takeLatest(createResource.TRIGGER, createResourceWorker);
}

export function* updateResourceWatcher() {
  yield takeLatest(updateResource.TRIGGER, updateResourceWorker);
}

export function* deleteResourceWatcher() {
  yield takeLeading(deleteResource.TRIGGER, deleteResourceWorker);
}

export function* deletedResourceWatcher() {
  yield takeLeading(deleteResource.SUCCESS, () => (window.location.href = '/dashboard/teaching'));
}

export function* reportResourceWatcher() {
  yield takeLeading(reportResource.TRIGGER, reportResourceWorker);
}

export function* unReportResourceWatcher() {
  yield takeLeading(unReportResource.TRIGGER, unReportResourceWorker);
}

export function* saveResourceWatcher() {
  yield takeLeading(saveResource.TRIGGER, saveResourceWorker);
}

export function* unSaveResourceWatcher() {
  yield takeLeading(unSaveResource.TRIGGER, unSaveResourceWorker);
}

export function* rateResourceWatcher() {
  yield takeLeading(rateResource.TRIGGER, rateResourceWorker);
}

export function* fetchPreferenceWatcher() {
  yield takeLatest(fetchPreferences.TRIGGER, fetchPreferencesWorker);
}

export function* savePreferenceWatcher() {
  yield takeLeading(savePreferences.TRIGGER, savePreferencesWorker);
}
