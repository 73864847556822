import { html, customElement } from '@adobe/edex/ui/edex-lit';
import styles from '@adobe/edex/ui/components/shared/view-mode-selector/view-mode-selector.lit.scss';
import { EdexTagName } from '@adobe/edex/ui/shared/constants';
import { IS_PROD, ViewMode } from '@adobe/edex/ui/constants';
import { BaseAuthElement } from '@adobe/edex/ui/components/base.element';
import { changeViewMode } from '@adobe/edex/ui/store/routines';

@customElement(EdexTagName.viewModeSelector)
export class ViewModeSelector extends BaseAuthElement {
  static styles = styles;

  actions = {
    changeViewMode: changeViewMode.success,
  };

  get show() {
    return !IS_PROD && this.user?.isAdmin;
  }

  handleChange = (mode: ViewMode) => () => {
    this.actions.changeViewMode({ mode });
  };

  render() {
    const { show, viewMode } = this;
    if (!show) {
      return '';
    }
    return html`
      <sp-action-bar variant="fixed" flexible open>
        <p>Role:</p>
        <sp-split-button variant="primary" value=${viewMode}>
          <sp-popover slot="more" open>
            <sp-menu>
              ${Object.values(ViewMode).map(
                (mode) =>
                  html`
                    <sp-menu-item @click=${this.handleChange(mode)}>
                      <span>${mode}</span>
                    </sp-menu-item>
                  `
              )}
            </sp-menu>
          </sp-popover>
        </sp-split-button>
      </sp-action-bar>
    `;
  }
}
