import { html, customElement } from '@adobe/edex/ui/edex-lit';
import isEmpty from 'lodash-es/isEmpty';
import { MenuItem } from '@spectrum-web-components/menu';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-apps';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-info';

import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { AdobeAnalyticsEvent, EdexTagName } from '@adobe/edex/ui/shared/constants';
import { MetadataModelKey } from '@adobe/edex/ui/shared/types';
import { getProductIconURL } from '@adobe/edex/ui/shared/utils';
import styles from './apps-grid.lit.scss';
import { Analytics } from '@adobe/edex/ui/shared/config/analytics';

import(
  /* webpackChunkName: "component/apps-grid-styles" */
  /* webpackPreload: true */
  /* webpackMode: "lazy" */
  './apps-grid.scss'
);

export const PRODUCTS = [
  'document-cloud',
  'creative-cloud',
  'adobe-marketing-cloud',
  'express',
  'lightroom-cc',
  'behance',
  'behance-prosite',
  'stock',
  'typekit',
  'substance-3d-sampler',
  'substance-3d-stager',
  'substance-3d-designer',
  'substance-3d-painter',
];

// TODO: move to shared
const INFO_MENU_ITEM_ID = 'menu-item__info';

@customElement(EdexTagName.appsGrid)
export class AppsGrid extends BaseElement {
  static styles = styles;

  static get properties() {
    return {
      showInfo: {
        type: Boolean,
      },
      products: {
        attribute: false,
      },
    };
  }

  showInfo = false;
  products = {};

  requestMetadata() {
    this.dispatchEvent(
      new CustomEvent('requestMetadata', {
        bubbles: true,
        composed: true,
        detail: { type: MetadataModelKey.products },
      })
    );
  }

  get primaryApps() {
    return PRODUCTS.map((vanityURL) => this.products?.[vanityURL]).filter(Boolean);
  }

  infoMenuItem: MenuItem;

  fetchProducts() {
    if (!isEmpty(this.products)) {
      return;
    }

    this.requestMetadata();
  }

  handleActionMenuClick(event: MouseEvent) {
    this.fetchProducts();
  }

  handleInfoClick(event: MouseEvent) {
    this.handleMenuItemClick(event);
    this.toggleShowInfo();
  }

  handleInfoOkClick(event: MouseEvent) {
    this.handleInfoClick(event);
    this.infoMenuItem.focus();
  }

  handleMenuItemClick(event: MouseEvent) {
    event.stopPropagation();
  }

  handleMenuProductClick = (title: string) => (event: MouseEvent) => {
    this.handleMenuItemClick(event);
    Analytics.getInstance().emitEvent(AdobeAnalyticsEvent.productClick, `${title} clicked in header app grid`);
  };

  toggleShowInfo(nextState?: boolean) {
    if (nextState === undefined) {
      this.showInfo = !this.showInfo;
    } else if (this.showInfo !== nextState) {
      this.showInfo = nextState;
    }

    return this.showInfo;
  }

  render() {
    return html`
      <sp-action-menu
        label=${'View Web Apps and Services' /* TODO: l10n */}
        placement="bottom-end"
        @click=${this.handleActionMenuClick}
        size="s"
      >
        <sp-icon size="xl" slot="icon"><sp-icon-apps></sp-icon-apps></sp-icon>
        <sp-menu-item
          id=${INFO_MENU_ITEM_ID}
          @click=${this.handleInfoClick}
          label=${'About this menu' /* TODO: l10n */}
          value=${NaN}
        >
          <sp-icon size="s" slot="icon"><sp-icon-info></sp-icon-info></sp-icon>
        </sp-menu-item>
        <sp-menu-group>
          <header slot="header" class="apps-grid-header">${$t('i18n.products.Web_Apps_and_Services')}</header>
          ${this.showInfo
            ? html`
                <section class="info">
                  <header>${$t('i18n.products.Find_the_app_you_need')}</header>
                  <main class="content">${$t('i18n.products.Find_the_app_you_need.description')}</main>
                  <footer>
                    <sp-menu-item @click=${this.handleInfoOkClick}>
                      <span>${$t('i18n.products.OK')}</span>
                    </sp-menu-item>
                  </footer>
                </section>
              `
            : html`
                <div class="primaryApps">
                  ${this.primaryApps.map(
                    (product) => html`
                      <sp-menu-item
                        @click=${this.handleMenuProductClick(product.title)}
                        aria-label="${product.title}"
                        class="product"
                        target="_blank"
                        href=${product.links.marketing}
                        value=${NaN}
                      >
                        <div>
                          <sp-icon size="xl" src=${getProductIconURL(product)}></sp-icon>
                          <span>${product.title}</span>
                        </div>
                      </sp-menu-item>
                    `
                  )}
                </div>
              `}
        </sp-menu-group>
        <sp-menu-item
          @click=${this.handleMenuItemClick}
          href="https://www.adobe.com/creativecloud/catalog/desktop.html"
          rel="noopener noreferrer"
          target="_blank"
          class="view-all-apps"
          value=${NaN}
          style="${this.showInfo ? 'display: none' : ''}"
        >
          <span>${$t('i18n.products.View_all_apps')}</span>
        </sp-menu-item>
      </sp-action-menu>
    `;
  }

  async firstUpdated() {
    this.infoMenuItem = this.shadowRoot.getElementById(INFO_MENU_ITEM_ID) as MenuItem;
    await Promise.resolve();
    const popover = this.shadowRoot.querySelector('sp-action-menu').shadowRoot?.querySelector('sp-popover');
    popover?.classList.add('apps-grid-popover');
  }
}
