import { playlistsAPI } from '@adobe/edex/ui/api';
import { takeEvery, takeLeading, takeLatest } from 'redux-saga/effects';
import { NotificationPlacement } from '../../types';
import {
  UpdateItemToPlaylistActionPayload,
  CreatePlaylistActionPayload,
  UpdatePlaylistActionPayload,
  FetchPlaylistActionPayload,
  IdActionPayload,
  DeletePlaylistItemsActionPayload,
} from '../../types/payload';
import { customToast, errorToast, infoToast } from '../../utils/notification';
import { EdexAction } from '../reducers/helpers';
import {
  deletePlaylist,
  fetchAllPlaylists,
  fetchPlaylist,
  updatePlaylist,
  createPlaylist,
  updateItemToPlaylist,
  fetchPlaylistLists,
  deletePlaylistItems,
} from '../routines';
import { v3requestRoutine } from './helpers';
import { NotificationVariant, CustomNotificationVariant } from '@adobe/edex/ui/shared/types/notifications';
import _ from 'lodash';
import { $t } from '@adobe/edex/ui/shared/config/i18n';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';

function* fetchPlaylistWorker(action: EdexAction<FetchPlaylistActionPayload>) {
  yield* v3requestRoutine({
    routine: fetchPlaylist,
    api: playlistsAPI,
    config: {
      method: 'GET',
      url: `/${action.payload.memberVanityURL}/${action.payload.id}`,
      params: { limit: action.payload.limit, offset: action.payload.offset },
    },
  });
}

function* fetchAllPlaylistsWorker(action: EdexAction<never>) {
  yield* v3requestRoutine({
    routine: fetchAllPlaylists,
    api: playlistsAPI,
    config: {
      method: 'GET',
    },
  });
}

function* fetchPlaylistListsWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: fetchPlaylistLists,
    api: playlistsAPI,
    config: {
      method: 'GET',
      url: `/list?itemID=${action.payload.id}`,
    },
  });
}

function* createPlaylistWorker(action: EdexAction<CreatePlaylistActionPayload>) {
  yield* v3requestRoutine({
    routine: createPlaylist,
    api: playlistsAPI,
    config: {
      method: 'POST',
      data: action.payload.data,
    },
  });
}

function* deletePlaylistWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: deletePlaylist,
    api: playlistsAPI,
    config: {
      method: 'DELETE',
      url: action.payload.id,
    },
    onSuccess: () =>
      infoToast({
        message: $t(I18nKey.playlistDeletedMessage),
      }),
  });
}

function* updatePlaylistWorker(action: EdexAction<UpdatePlaylistActionPayload>) {
  yield* v3requestRoutine({
    routine: updatePlaylist,
    api: playlistsAPI,
    config: {
      method: 'PUT',
      url: action.payload.id,
      data: action.payload.data,
    },
    onSuccess: () =>
      infoToast({
        message: $t(I18nKey.playlistUpdatedMessage),
      }),
  });
}

function* deletePlaylistItemsWorker(action: EdexAction<DeletePlaylistItemsActionPayload>) {
  yield* v3requestRoutine({
    routine: deletePlaylistItems,
    api: playlistsAPI,
    config: {
      method: 'DELETE',
      url: action.payload.id + '/deleteItems',
      data: { itemIDs: action.payload.items },
    },
    onSuccess: () =>
      infoToast({
        message: $t(I18nKey.playlistItemsDeleted),
      }),
  });
}
function* updateItemToPlaylistWorker(action: EdexAction<UpdateItemToPlaylistActionPayload>) {
  yield* v3requestRoutine({
    routine: updatePlaylist,
    api: playlistsAPI,
    config: {
      method: 'PUT',
      url: `updateItems`,
      data: _.omit(action.payload, ['playlistNames']),
    },
    onSuccess: () =>
      customToast({
        message: 'Added to:',
        placement: NotificationPlacement.topRight,
        variant: '' as NotificationVariant,
        custom: CustomNotificationVariant.bookmark,
        itemId: action.payload.itemID,
        playlistNames: action.payload.playlistNames,
      }),
  });
}

export function* fetchPlaylistWatcher() {
  yield takeLeading(fetchPlaylist.TRIGGER, fetchPlaylistWorker);
}

export function* fetchAllPlaylistsWatcher() {
  yield takeLatest(fetchAllPlaylists.TRIGGER, fetchAllPlaylistsWorker);
}

export function* createPlaylistWatcher() {
  yield takeLeading(createPlaylist.TRIGGER, createPlaylistWorker);
}

export function* deletePlaylistWatcher() {
  yield takeLeading(deletePlaylist.TRIGGER, deletePlaylistWorker);
}

export function* updatePlaylistWatcher() {
  yield takeEvery(updatePlaylist.TRIGGER, updatePlaylistWorker);
}

export function* failedUpdatePlaylistWatcher() {
  yield takeLeading(updatePlaylist.FAILURE, () =>
    errorToast({
      message: 'Failed to update the Playlist',
    })
  );
}

export function* deletePlaylistItemsWatcher() {
  yield takeEvery(deletePlaylistItems.TRIGGER, deletePlaylistItemsWorker);
}

export function* updateItemToPlaylistWatcher() {
  yield takeEvery(updateItemToPlaylist.TRIGGER, updateItemToPlaylistWorker);
}

export function* fetchPlaylistListsWatcher() {
  yield takeEvery(fetchPlaylistLists.TRIGGER, fetchPlaylistListsWorker);
}
