import { combineReducers } from 'redux';
import { self } from '@adobe/edex/ui/store/reducers/api/self';
import { comments } from '@adobe/edex/ui/store/reducers/api/comments';
import { resource } from '@adobe/edex/ui/store/reducers/api/resource';
import { metadata } from '@adobe/edex/ui/store/reducers/api/metadata';
import { playlist } from '@adobe/edex/ui/store/reducers/api/playlist';

export const api = combineReducers({
  self,
  resource,
  metadata,
  comments,
  playlist,
});
