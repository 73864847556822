import querystring from 'querystring';

import { LocalEventType } from '@adobe/edex/ui/shared/constants';
import { buildPath } from '@adobe/edex/ui/shared/utils';
import { NX_IMS_SIGNUP_SUSI_DCTX_ID, NX_IMS_SIGNIN_SUSI_DCTX_ID } from '@env/environment';

export class IMS {
  static bootstrap() {
    // update adobeid config with the callbacks we need
    window.adobeid = Object.assign(window.adobeid || {}, {
      onAccessTokenHasExpired: () => this.onAccessTokenHasExpired(),
    });
  }

  private static async onAccessTokenHasExpired() {
    // TODO:
    // await EdexAuth.signIn();
  }

  static async isReady(): Promise<boolean> {
    let resolver;
    this._isReady =
      this._isReady ||
      window.adobeIMS?.initialized ||
      new Promise((resolve) => {
        resolver = function () {
          resolve(true);
        };
        if (window.adobeIMS?.initialized) {
          resolver();
        } else {
          document.addEventListener(LocalEventType.adobeImsReady, resolver);
        }
      }).then(() => {
        document.removeEventListener(LocalEventType.adobeImsReady, resolver);
        return true;
      });
    return this._isReady;
  }
  private static _isReady: Promise<boolean> = null;

  static async getAdobeIMS() {
    await IMS.isReady();
    return window.adobeIMS;
  }

  static async getAccessToken() {
    const imsTokenDetails: { token: string; expire: Date; sid: string } = (
      await this.getAdobeIMS()
    ).getAccessToken() as any;
    return imsTokenDetails.token;
  }

  static async getUserId() {
    return (await (await this.getAdobeIMS())?.getProfile())?.userId;
  }

  static async isAuthenticated(): Promise<boolean> {
    return (await this.getAdobeIMS()).isSignedInUser();
  }

  static async signIn() {
    (await this.getAdobeIMS()).signIn({
      redirect_uri: this.getRedirectURL(),
      dctx_id: NX_IMS_SIGNIN_SUSI_DCTX_ID,
    });
  }

  static async signOut() {
    (await this.getAdobeIMS()).signOut({
      redirect_uri: window.location.origin,
    });
  }

  static async signUp() {
    (await this.getAdobeIMS()).signUp({
      redirect_uri: this.getRedirectURL(),
      dctx_id: NX_IMS_SIGNUP_SUSI_DCTX_ID,
    });
  }

  static async refreshToken() {
    return await (await IMS.getAdobeIMS()).refreshToken();
  }

  static getRedirectURL() {
    const backUrl = buildPath();
    return `${window.location.origin}/main/auth/?${querystring.stringify({
      backUrl,
    })}`;
  }
}
