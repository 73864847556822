import { remove } from 'lodash-es';
import { LinkSchema, ResourceLinks } from '@adobe/edex/ui/types';
import { MIME_TYPE_IMAGE_REGEX } from '@adobe/edex/ui/shared/constants';

const ALLOWED_VIDEO_DOMAINS = Object.freeze([
  'vimeo.com',
  'player.vimeo.com',
  'www.vimeo.com',
  'spark.adobe.com',
  'youtube.com',
  'www.youtube.com',
  'video.tv.adobe.com',
  'tv.adobe.com',
]) as string[];

function isAllowedDomain(url: string, domains: string[]): boolean {
  try {
    const { host } = new URL(url);
    return domains.includes(host);
  } catch (err) {
    console.error(err);
    return false;
  }
}

export const mergeResourceLinks = (links: ResourceLinks) => [...(links?.web || []), ...(links?.video || [])];

const isWebImage = (link: LinkSchema): boolean =>
  link.metadata?.contentType ? MIME_TYPE_IMAGE_REGEX.test(link.metadata.contentType) : false;

const isWebVideo = (link: LinkSchema): boolean =>
  !!link.metadata?.video && isAllowedDomain(link.metadata.video, ALLOWED_VIDEO_DOMAINS);

export const groupResourceLinks = (
  links: LinkSchema[]
): { web: LinkSchema[]; video: LinkSchema[]; images: LinkSchema[] } => {
  return {
    video: remove(links, isWebVideo),
    images: remove(links, isWebImage),
    web: links, // neither video nor image
  };
};
