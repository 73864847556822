import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';

import { NX_IMS_CLIENT_ID, environment } from '@env/environment';
import { CustomHttpHeader } from '@adobe/edex/ui/shared/constants';
import { getCurrentLocale } from '@adobe/edex/ui/shared/config/i18n';
import { IMS } from '../config/ims';
import { isActAsMode, getActAsInfo } from '../utils/actAs';

export const localeRequestInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(async (req) => {
    req.headers[CustomHttpHeader.locale] = getCurrentLocale();
    return req;
  });
};

export const newrelicResponseInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(null, (error: AxiosError) => {
    if (!axios.isCancel(error)) {
      window[`newrelic`]?.noticeError(error.message);
    }
    return Promise.reject(error);
  });
};

const defaultHeaders = Object.freeze({
  Pragma: 'no-cache',
  'Cache-Control': 'no-cache',
  [CustomHttpHeader.ui]: 'true',
  [CustomHttpHeader.xApiKey]: NX_IMS_CLIENT_ID,
});

export const getApiInstance = ({
  config,
  interceptors,
}: {
  config?: AxiosRequestConfig;
  interceptors: ((instance: AxiosInstance) => void)[];
}) => {
  const instance = axios.create({
    timeout: 10000,
    baseURL: environment.api,
    withCredentials: true,
    headers: defaultHeaders,
    ...config,
  });
  interceptors.forEach((applyInterceptor) => applyInterceptor(instance));
  return instance;
};

export const AUTH_HEADER = 'Authorization';
export const authReqFn = async (req: AxiosRequestConfig | any) => {
  if (await IMS.isAuthenticated()) {
    req.headers[AUTH_HEADER] = `Bearer ${await IMS.getAccessToken()}`;
  }
  return req;
};
export const authRequestInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(authReqFn);
};

export const actAsFn = (req: AxiosRequestConfig | any) => {
  if (isActAsMode()) {
    const { targetMember } = getActAsInfo();
    req.headers[CustomHttpHeader.actAs] = targetMember.id;
  }
  return req;
};
export const actAsRequestInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(actAsFn);
};
