export enum SearchEntityType {
  resources = 'resources',
  courses = 'courses',
  discussions = 'discussions',
  collections = 'staticpages',
}

export enum SearchSort {
  relevance = 'Relevance',
  date = 'Date',
  views = 'Views',
  featured = 'Featured',
}

export enum SearchType {
  teachingResources = 'teaching-resources',
  projects = 'projects',
  supplemental = 'supplemental',
  professionalLearning = 'professional-learning',
  selfPacedCourses = 'self-paced-courses',
  toolkits = 'toolkits',
  discussions = 'discussions',
  collections = 'staticpages',
}

export enum LegacySearchKey {
  type = 'r',
  product = 'p',
  subject = 'sb',
  ageLevel = 'ag',
}

export enum SearchKey {
  type = 'type',
  query = 'q',
  sort = 'sort',
  limit = 'limit',
  page = 'page',
  product = 'product',
  subject = 'subject',
  ageLevel = 'age_level',
  time = 'time',
  author = 'a',
  duration = 'duration',
  template = 'template',
}

export type SearchParams = Partial<Record<SearchKey, any>>;
