import { AnyAction } from 'redux';
import { createComment, setCommentForm, updateComment, updateCommentForm } from '@adobe/edex/ui/store/routines';
import { CommentSchema } from '@adobe/edex/ui/shared/types';

export type CommentFormState = {
  loading: boolean;
  error: boolean;
  data: Partial<CommentSchema>;
};

const DEFAULT_FORM_DATA: CommentFormState['data'] = Object.freeze({
  parentCommentID: null,
  subscribed: true,
});

const initialState: CommentFormState = {
  loading: false,
  error: false,
  data: null,
};

export function comment(state = initialState, action: AnyAction): CommentFormState {
  switch (action.type) {
    case updateCommentForm.TRIGGER:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case setCommentForm.TRIGGER:
      return {
        ...state,
        data: action.payload ? { ...DEFAULT_FORM_DATA, ...action.payload } : null,
      };
    case createComment.TRIGGER:
    case updateComment.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case createComment.SUCCESS:
    case updateComment.SUCCESS:
      return {
        ...state,
        data: null,
      };
    case createComment.FAILURE:
    case updateComment.FAILURE:
      return { ...state, error: true };
    case createComment.FULFILL:
    case updateComment.FULFILL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
