import { html, customElement } from '@adobe/edex/ui/edex-lit';
import { TemplateResult } from '@adobe/edex/ui/edex-lit';
import { ifDefined } from '@adobe/edex/ui/edex-lit';
import '@spectrum-web-components/icons-workflow/icons/sp-icon-magnify';

import { EdexDomID, EdexTagName } from '@adobe/edex/ui/shared/constants';
import { BaseElement } from '@adobe/edex/ui/shared/components/base.element';
import { getTranslation } from '@adobe/edex/ui/shared/config/i18n';
import { I18nKey } from '@adobe/edex/ui/shared/constants/i18n';

import(
  /* webpackChunkName: "component/search-element-styles" */
  /* webpackPreload: true */
  /* webpackMode: "lazy" */
  './search.scss'
);

@customElement(EdexTagName.search)
export class Search extends BaseElement {
  static get properties() {
    return {
      ...super.properties,
      open: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  get useLightDom() {
    return true;
  }

  open = window.innerWidth > 1024;

  render(): TemplateResult {
    const search = getTranslation(I18nKey.navSearch);
    return html`
      <form @submit=${this.handleSubmit} action="javascript:void(0)">
        <label for=${EdexDomID.search}>
          <sp-action-button size="s" quiet>
            <sp-icon size="xl" slot="icon">
              <sp-icon-magnify .label="${search}"></sp-icon-magnify>
            </sp-icon>
          </sp-action-button>
        </label>
        <input
          type="search"
          tabindex="-1"
          id=${EdexDomID.search}
          placeholder=${search}
          aria-label=${search}
          aria-hidden=${ifDefined(this.open ? undefined : 'true')}
          required
          autocomplete="off"
          @focus=${this.handleFocus}
          @blur=${this.handleBlur}
          @keydown=${this.handleKeydown}
          @click=${this.handleLogoIcon}
        />
      </form>
    `;
  }

  search() {
    const input = this.renderRoot.querySelector('input');
    const { value, validity } = input;
    if (validity.valid) {
      this.emitEvent('searchChanged', { q: value }, { bubbles: true, composed: true });
      input.value = '';
      this.open = false;
      input.blur();
    }
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    this.search();
  }

  handleKeydown(event: KeyboardEvent) {
    switch (event.code) {
      case 'Escape':
        event.preventDefault();
        (event.target as HTMLInputElement).value = '';
        break;
      default:
        break;
    }
  }

  handleFocus() {
    this.open = true;
  }

  handleBlur() {
    this.open = false;
    this.emitEvent('searchBlur');
  }

  handleLogoIcon() {
    if (this.open) {
      this.emitEvent('searchFocus');
    }
  }
}
