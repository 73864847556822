import { changeViewMode, fetchSelf } from '@adobe/edex/ui/store/routines';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import { IS_PROD, ViewMode } from '@adobe/edex/ui/constants';
import { ChangeViewModeActionPayload } from '@adobe/edex/ui/types/payload';
import { MemberProfile } from '@adobe/edex/ui/shared/types';

export function viewMode(state = null, action: EdexAction<ChangeViewModeActionPayload & MemberProfile>): ViewMode {
  if (IS_PROD) {
    return null;
  }
  switch (action.type) {
    case changeViewMode.SUCCESS:
      return action.payload.mode;
    case fetchSelf.SUCCESS:
      return action.payload.isAdmin ? ViewMode.admin : null;
    default:
      return state;
  }
}
