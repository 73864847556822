import '@spectrum-web-components/action-bar/sp-action-bar.js';
import { customElement } from 'lit/decorators';
import { EdexTagName, RoutePath } from '../../constants';
import { BaseElement } from '../base.element';
import { html } from 'lit';
import { getAssetURL } from '../../utils';
import { BTS_BANNER_KEY } from '@adobe/edex/ui/constants';
import { I18nKey } from '../../constants/i18n';
import { $t, getCurrentLocale } from '../../config/i18n';
import { getBaseURL } from '@adobe/edex/ui/utils';
import { getBTSURL } from '../../utils/routes';
import { CuratedContent } from '../../types';
import { getCuratedContent } from '../../utils/cdn';
import { Locale } from '@adobe/edex/ui/constants';
import('./edex-banner.scss');

@customElement(EdexTagName.btsBanner)
export class BTSBanner extends BaseElement {
  get useLightDom() {
    return true;
  }

  static get properties() {
    return {
      text: { attribute: false },
      link: { attribute: false },
      bannerClose: { type: Boolean },
      loading: { attribute: false },
    };
  }
  loading = false;

  curatedContent: CuratedContent['edexBanner'] = {
    pageBanner: {
      buttonText: '',
      buttonLink: '',
    },
  };

  get locale() {
    return getCurrentLocale();
  }

  async firstUpdated() {
    this.loading = true;
    try {
      this.curatedContent = (await getCuratedContent(this.locale)).edexBanner;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  bannerClose = sessionStorage.getItem(BTS_BANNER_KEY) === 'disabled';

  toggleSessionStorage() {
    sessionStorage.setItem(BTS_BANNER_KEY, 'disabled');
    this.bannerClose = true;
  }

  render() {
    if (this.loading) {
      return;
    }
    const { bannerClose } = this;
    const {
      curatedContent: {
        pageBanner: { buttonText, buttonLink },
      },
    } = this;

    return !bannerClose
      ? html`
      <div class="edex-banner-container">
        <div class="edex-banner">
          <img src="${getAssetURL('bts_banner_icon.svg')}" alt="bts-banner-icon" />
          <a class="edex-banner-text" href="${buttonLink}" rel="external" target="_top" aria-label="${buttonText}" 
   role="link" >
            ${buttonText}
          </a>
          <sp-action-button
                    class="close-btn"
                    aria-label="closeBtn"
                    quiet
                    @click="${() => {
                      this.toggleSessionStorage();
                    }}"
                  >
          <sp-icon slot="icon">
            <sp-icon-close></sp-icon-close>
          </sp-icon>
          </sp-action-button
        </div>
      </div>
    `
      : null;
  }
}
