import { noticeError } from '@adobe/edex/ui/utils/index';

export function getFromLocalStorage<T = any>({
  key,
  parse = false,
  defaultValue = null,
}: {
  key: string;
  parse?: boolean;
  defaultValue?: T;
}): T {
  let value = localStorage.getItem(key);
  if (value && parse) {
    try {
      value = JSON.parse(value);
    } catch (err) {
      noticeError(err);
    }
  }
  return ((value as unknown) as T) || defaultValue;
}

export function saveToLocalStorage({
  key,
  serialize = false,
  value,
}: {
  key: string;
  serialize?: boolean;
  value: any;
}) {
  localStorage.setItem(key, value && serialize ? JSON.stringify(value) : value);
}
