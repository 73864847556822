import { fileAPI } from '@adobe/edex/ui/api';
import { takeEvery, takeLeading } from 'redux-saga/effects';
import { IdActionPayload, UpdateFileActionPayload } from '../../types/payload';
import { EdexAction } from '../reducers/helpers';
import { deleteFile, updateFile } from '../routines';
import { v3requestRoutine } from './helpers';

function* deleteFileWorker(action: EdexAction<IdActionPayload>) {
  yield* v3requestRoutine({
    routine: deleteFile,
    api: fileAPI,
    config: {
      method: 'DELETE',
      url: action.payload.id,
    },
  });
}

function* updateFileWorker(action: EdexAction<UpdateFileActionPayload>) {
  yield* v3requestRoutine({
    routine: updateFile,
    api: fileAPI,
    config: {
      method: 'PUT',
      url: action.payload.id,
      data: action.payload.data,
    },
  });
}

export function* deleteFileWatcher() {
  yield takeLeading(deleteFile.TRIGGER, deleteFileWorker);
}

export function* updateFileWatcher() {
  yield takeEvery(updateFile.TRIGGER, updateFileWorker);
}
