import { Locale } from '@adobe/edex/ui/shared/constants';

export {
  EDEX_LOCALE_LS_KEY,
  Locale,
  LOCALES,
  LocalEventType,
  IS_TEST,
  IS_PROD,
  IS_ONETRUST_PROD,
  ViewMode,
  HERO_IMAGE_WIDTH,
} from '@adobe/edex/ui/shared/constants';

export const POST_AUTH_LS_KEY = 'edexPostAuth';
export const CC_LICENSE_NONE_ID = 'd977d84a-6af6-11e2-bdd2-086c08e35d24';
export const RESOURCE_TOOLTIP_COUNT_KEY = 'edexResourceTooltipCount';
export const RESOURCE_SHOW_TOOLTIP_MAXTIMES = 5;
export const LOCALE_PARAM = 'locale';

export enum AnalyticsEvent {
  resourceView = 'resource.pageview.details',
  resourcePreview = 'resource.pageview.preview',
  resourceAssetPreview = 'resource.subview.asset',
  resourceClickWebLink = 'resource.click.weblink',
  resourceClickInlineLink = 'resource.click.inlineLink',
  resourceClickCCXAcquisition = 'resource.click.ccx.Acquisition',
  resourceClickRelated = 'resource.click.related',
  resourceClickShareDialog = 'resource.click.shareDialog',
  resourceClickShare = 'resource.click.share',
  resourceClickRelatedResource = 'resource.click.relatedResource',
  resourceClickSendToGD = 'resource.click.sendToGD',
  resourceClickSendToOD = 'resource.click.sendToOD',
  resourceClickDownloadToDevice = 'resource.click.downloadToDevice',
  resourceVisited = 'resource.visited',
  getResourcesBtn = 'teachingResource.click.getResourcesBtn',
  getFreeTrial = 'resource.click.getFreeTrial',
  ccxBuy = 'resouce.click.ccxBuy',
  ccxNewProject = 'resource.click.ccxAcquisition',
  toolsLogin = 'resource.click.login',
  resourceClickCCXTemplateLink = 'resource.click.ccxTemplateLink',
  resourceClickCCXTemplateLinkInline = 'resource.click.ccxTemplateLink.inline',
  resourceVideoPlay = 'resource.video.play',
  collectionCardClick = 'collection.card.click',
  resourceClickSeeAllResources = 'teachingResource.featured.click.seeAllResources',
  homepageVisit = 'v1.visit.homepage',
  acrobatBuy = 'resouce.click.acrobatBuy',
  acrobatNewProject = 'resource.click.acrobatAcquisition',
  visitViaCampaign = 'edex.visit.mv',
  seeAll = 'resource.seeall',
  getResource = 'resource.get',
  homeSeeAllCollectionsClicked = 'home.seeAllCollections.clicked',
  homePageCollectionClicked1 = 'home.collection1.clicked',
  homePageCollectionClicked2 = 'home.collection2.clicked',
  homePageCollectionClicked3 = 'home.collection3.clicked',
  homePageCollectionClicked4 = 'home.collection4.clicked',
  resourceBookmarkClickedFromResourceCard = 'bookmark.click.resource-card',
  resourceBookmarkClickedFromToolBar = 'bookmark.click.tool-bar',
  collectionCardClickedFromTRPage = 'collection.click.home-page',
  collectionCardClickedFromSRP = 'collection.click.sr-page',
  playlistClickShare = 'playlist.click.share',
  courseClickCCXTemplateLinkInline = 'course.click.ccxTemplateLink.inline',
  courseClickLinkInline = 'course.click.otherLink.inline',
  courseClickCCXAcquisition = 'course.click.ccx.Acquisition',
  homeHeroBtnClicked = 'homepage.heroBanner.btn.clicked',
  promoBannerBtnClicked = 'homepage.promoBanner.button.clicked',
  studentSampleClick = 'homepage.studentSample.clicked',
  homeKeepExploreSubjectTag = 'homepage.keepExplore.subject.clicked',
  courseLinkClick = 'homepage.pl.course.clicked',
  courseBrowseClick = 'homepage.pl.browse.button.clicked',
  homeExploreBtnClicked = 'homepage.explore.button.clicked',
  resourceSaveForLater = 'resource.saveForLater',
  resourceSaveForLaterAborted = 'resource.saveForLater.aborted',
  liveSessionCreated = 'course.newSession.success',
  liveSessionCreationFailed = 'course.newSession.failed',
}

export enum ResourceCardActionButton {
  save = 'save',
  drag = 'drag',
  checkbox = 'checkbox',
  menu = 'menu',
}

export enum CourseCardType {
  default = 'default',
  checkbox = 'checkbox',
}

export enum Actid {
  saveResource = 'saveResource',
}

export const SELECT_PREF_KEY = 'edexSelectPreferences';

export const TESTIMONIAL_KEY = 'edexTestimonialKey';

// List of Product vanityURL for which we need to check entitlement and show appropriate link.
// refer to edex_metadata.products table.
export const CHECK_ENTITLEMENTS = ['express', 'spark', 'acrobat'];

// product to fullfillable item map, returned by entitlement API.
export const prodToFI = { express: 'spark', acrobat: 'acrobat_web' };

export const productToEventName = {
  express: { createNewProject: AnalyticsEvent.ccxNewProject, buy: AnalyticsEvent.ccxBuy },
  acrobat: { createNewProject: AnalyticsEvent.acrobatNewProject, buy: AnalyticsEvent.acrobatBuy },
};
export const SHOW_ALL = 'showAll';
export const INCLUDES_TEMPLATE = 'includesTemplate';

export const ADOBE_FOR_EDUCATION_ID = '82303c61-6102-11e2-9a53-12313b016471';

export const BTS_BANNER_KEY = 'BTS_BANNER_KEY';

export const SCHOOL_ATTRIBUTION_KEY = 'SCHOOL_ATTRIBUTION_KEY';
