/**
 * Values that do not have i18n prefix are needed to be added/translated.
 */
export enum I18nKey {
  admin = 'i18n.roles.Admin',
  author = 'i18n.roles.Author',
  description = 'i18n.Description',
  resourceLearningObjectives = 'i18n.resource.Learning_Objectives',
  subjects = 'i18n.Subjects',
  updatedOn = 'i18n.Last_update_on',
  ages = 'i18n.Ages',
  studentTime = 'i18n.resource.view.Student_Time',
  ratingsAndComments = 'i18n.resource.view.Ratings_and_Comments',
  standards = 'i18n.Content_Standards',
  custom = 'i18n.resource.view.Custom_Standards',
  customStandardsPlaceholder = 'i18n.Custom_Standards',
  ratings = 'i18n.Ratings',
  rateResource = 'i18n.Rate_Resource',
  view = 'i18n.resource.view.View',
  views = 'i18n.resource.view.Views',
  save = 'i18n.Save',
  saves = 'i18n.Saves',
  saveYourChanges = 'i18n.save_your_changes',
  share = 'i18n.Share',
  report = 'i18n.Report',
  edit = 'i18n.Edit',
  editDetails = 'i18n.Edit_Details',
  reply = 'i18n.Reply',
  submit = 'i18n.forms.button.Submit',
  cancel = 'i18n.Cancel',
  delete = 'i18n.Delete',
  discard = 'i18n.Discard',
  next = 'i18n.Next',
  about = 'i18n.courses.nav.About',
  deleteCommentConfirm = 'i18n.delete_comment.confirm',
  deleteCommentDescription = 'i18n.delete_comment.description',
  deleteCommentHeader = 'i18n.delete_comment.header',
  deleteCommentCanelBtn = 'i18n.delete_comment.no_cancel',
  deleteCommentConfirmBtn = 'i18n.delete_comment.yes_delete',
  downloadThisFreeResource = 'i18n.resource.view.download_this_free_resource',
  errorNotFound = 'i18n.Page_not_found',
  errorNotFoundMessage = 'i18n.Page_not_found_message',
  errorPlaylistNotFoundMessage = 'i18n.Playlist_not_found_message',
  errorNotFoundHomepage = 'i18n.Homepage',
  errorNotFoundToHome = 'i18n.Return_to_home_explore_EDEX',
  errorNotFoundHelpCenter = 'i18n.Help_Center',
  errorNotFoundFindAnswers = 'i18n.Find_the_fastest_way_get_answers',
  errorNotFoundDashboard = 'i18n.My_Dashboard',
  errorNotFoundManageResources = 'i18n.Manage_your_profile_resources_more',
  emailCommentSubscribe = 'i18n.Email_Comment',
  select = 'i18n.Select',
  begin = 'i18n.resource.contribute.sectionBegin',
  information = 'i18n.tag.subjects.Information',
  content = 'i18n.Content',
  details = 'i18n.Details',
  loadMore = 'i18n.Load_More',
  postComment = 'i18n.Post_Comment',
  whatTypeOfResource = 'i18n.select_resource_type_to_continue',
  activeStudentTime = 'i18n.resource.contribute.StudentTime',
  resourceBuilderGetStarted = 'i18n.resource.contribute.GetStarted',
  resourceInfoStepDescription = 'i18n.resource.contribute.Information_description',
  resourceContentStepDescription = 'i18n.resource.contribute.content_section_description',
  resourceDetailsStepDescription = 'i18n.resource.contribute.details_section_description',
  resourceAddNew = 'i18n.resource.add_new',
  letsGetStarted = 'i18n.resource.contribute.Start_Heading',
  letsGetStartedVideoId = 'i18n.resource.contribute.Start_Video_Id',
  letsGetStartedDescription = 'i18n.resource.contribute.Start_Description',
  letsGetStartedEdexSummary = 'i18n.resource.contribute.Start_Summary',
  letsGetStartedVideo = 'i18n.resource.contribute.Start_Video_Description',
  preview = 'i18n.Preview',
  saved = 'i18n.resource.view.Saved',
  reported = 'i18n.resource.view.Reported',
  rated = 'i18n.resource.view.Rated',
  flagForAbuse = 'i18n.Flag_for_Abuse',
  viewSavedResource = 'i18n.View_Saved_Resources',
  yesFlagContent = 'i18n.flag-for-abuse.resource.accept',
  flagContentConfirmation = 'i18n.flag-for-abuse.resource.confirm',
  noCancel = 'i18n.flag-for-abuse.cancel',
  nextSection = 'i18n.resource.contribute.Next_Section',
  getResource = 'i18n.resource.view.get-modal.Get_resource',
  downloadFile = 'i18n.Download_file',
  getThisFreeResource = 'i18n.resource.view.Get_this_free_resource',
  helpfulInformationForThisResource = 'i18n.resource.view.Helpful_information',
  helpfulResources = 'i18n.resource.view.Helpful_resources',
  ccLicense = 'i18n.license',
  additionalCategoriesKeywords = 'i18n.resource.view.Additional_categories',
  toolsTotal = 'i18n.resource.view.Tools_count',
  productsTotal = 'i18n.resource.view.Products_count',
  toolsFor = 'i18n.resource.view.toolsmodal',
  tools = 'i18n.Tools',
  products = 'i18n.Products',
  seeAllTools = 'i18n.resource.view.See_all_tools',
  getFreeTrial = 'i18n.resource.view.Get_free_trial',
  seeAllPiecesIncluded = 'i18n.resource.view.See_all_pieces',
  viewAllPiecesIncluded = 'i18n.resource.view.View_all_pieces',
  getAllPiecesIncluded = 'i18n.resource.preview.get_all_pieces',
  commentsCount = 'i18n.count_Comments',
  viewMore = 'i18n.resource.view.View_more',
  viewAll = 'i18n.View_All',
  viewLess = 'i18n.View_less',
  publish = 'i18n.Publish_resource_New',
  saveAndPublish = 'i18n.Publish_resource_Existing',
  unpublish = 'i18n.Unpublish_resource_New',
  published = 'i18n.dashboard.Published',
  draft = 'i18n.draft',
  unpublishContentConfirmBtn = 'i18n.Unpublish_Content.confirm_btn',
  unpublishContentSubtext = 'i18n.Unpublish_Content.subtext',
  unpublishContentBody = 'i18n.Unpublish_Content.body',
  unpublishContentTitle = 'i18n.Unpublish_Content.title',
  deletionConfirmBtn = 'i18n.Confirm_Deletion.confirm_btn',
  cancelBtn = 'i18n.Confirm_Button.cancel',
  resourceDeletionConfirmTitle = 'i18n.Confirm_Resource_Deletion.title',
  editPlaylistDetails = '18n.Edit_Playlist.details',
  playlistDeletionConfirmTitle = 'i18n.Confirm_Playlist_Deletion.title',
  deletionConfirmSubtext = 'i18n.Confirm_Deletion.subtext',
  deletionResourceConfirmSubtext = 'i18n.Confirm_Resource_Deletion.subtext',
  deletionPlaylistConfirmSubtext = 'i18n.Confirm_Playlist_Deletion.subtext',
  deletionConfirmBody = 'i18n.Confirm_Deletion.body',
  deletionConfirmTitle = 'i18n.Confirm_Deletion.title',
  somethingWentWrong = 'i18n.Announcements.createError',
  thisResourceIncludes = 'i18n.resource.view.resource_includes',
  inviteMemberDefaultMessage = 'i18n.referral_widget.default_message',
  inviteMemberTitle = 'i18n.referral_widget.title',
  inviteMemberSubtitle = 'i18n.referral_widget.subtitle',
  inviteMemberDescription = 'i18n.referral_widget.description',
  inviteMemberRecipientsPlaceholder = 'i18n.referral_widget.recipients_placeholder',
  inviteMemberSendButton = 'i18n.referral_widget.send_button',
  inviteMemberSuccessTitle = 'i18n.referral_widget.success_title',
  inviteMemberSuccessDescription = 'i18n.referral_widget.success_description',
  inviteMemberSuccessSendMore = 'i18n.referral_widget.success_send_more_button',
  inviteMemberFailedTitle = 'i18n.referral_widget.failed_title',
  inviteMemberFailedDescription = 'i18n.referral_widget.failed_description',
  inviteMemberErrorNoRecipients = 'i18n.referral_widget.error.no_recipients',
  inviteMemberErrorInvalidRecipientEmail = 'i18n.referral_widget.error.invalid_recipient_email',
  inviteMemberErrorTooManyRecipeints = 'i18n.referral_widget.error.too_many_recipients',
  relatedResources = 'i18n.cards.Related_Resources',
  reltedResourcesHeader = 'i18n.cards.Related_Resources_header',
  downloading = 'i18n.resource.view.Downloading_file',
  exportedToGoogleDrive = 'i18n.Exported_to_Google_Drive',
  exportedToOneDrive = 'i18n.Exported_to_OneDrive',
  mayWeAlsoSuggest = 'i18n.resource.view.may_we_also_suggest',
  accountSettings = 'i18n.Account_Settings',
  profileCompleteness = 'i18n.Profile_Completeness',
  completeProfile = 'i18n.Complete_Profile',
  myProfile = 'i18n.My_Profile',
  myDashboard = 'i18n.My_Dashboard',
  learning = 'i18n.Learning',
  teaching = 'i18n.Teaching',
  discussions = 'i18n.Discussions',
  connections = 'i18n.Connections',
  navigationShare = 'i18n.navigation.Share',
  navigationPublishTeachingMaterial = 'i18n.navigation.Publish_a_teaching_material',
  navigationStartDiscussion = 'i18n.navigation.Start_a_discussion',
  dismiss = 'i18n.Dismiss',
  help = 'i18n.Help',
  signOut = 'i18n.Sign_Out',
  navDashboard = 'i18n.nav.My_EdEx',
  navDiscover = 'i18n.nav.Discover',
  navBrowse = 'i18n.nav.Browse',
  navCommunity = 'i18n.nav.Community',
  navSignIn = 'i18n.nav.Sign_In',
  navSignUp = 'i18n.nav.Sign_Up',
  navTeachingResources = 'i18n.nav.teaching_resources',
  navProfessionalLearning = 'i18n.nav.professional_learning',
  navCollections = 'i18n.nav.collections',
  navSearch = 'i18n.nav.search',
  mainNavTRDashboard = 'i18n.main.nav.My_Teaching_Resouces',
  mainNavPLDashboard = 'i18n.main.nav.My_Professional_Learning',
  mainNavPlaylistDashboard = 'i18n.main.nav.My_Playlist',
  resourceSavedToMyEdex = 'i18n.resource.saved_to_my_edex',
  resourceSignInConfirmationDialogHeadline = 'i18n.resource.sign_in_confirmation_dialog.headline',
  resourceSignInConfirmationDialogBody = 'i18n.resource.sign_in_confirmation_dialog.body',
  resourceSignInConfirmationDialogCancel = 'i18n.resource.sign_in_confirmation_dialog.cancel',
  resourceSignInConfirmationDialogConfirm = 'i18n.resource.sign_in_confirmation_dialog.confirm',
  resourceSendToDriveProgressDialogHeadline = 'i18n.resource.send_to_drive_progress_dialog.headline',
  resourceSendToDriveProgressDialogBody = 'i18n.resource.send_to_drive_progress_dialog.body',
  resourcePreviewActionbarSignInToDownload = 'i18n.resource.action_bar.sign_in_to_download',
  resourcePreviewActionbarSignInToOpenLink = 'i18n.resource.action_bar.sign_in_to_open',
  resourcePreviewActionbarDownload = 'i18n.resource.action_bar.download',
  resourcePreviewActionbarOpenLink = 'i18n.resource.action_bar.open_link',
  resourcePreviewCCXTemplateActionbarTypeDisplay = 'i18n.resource.action_bar.preview_main_type_display',
  resourcePreviewActionbarCCXTemplateOpenLink = 'i18n.resource.action_bar.ccx_template_open_link',
  resourcePreviewActionbarCCXTemplateSignInLink = 'i18n.resource.action_bar.ccx_template_signin_link',
  resourceLinkWeb = 'i18n.resource.link_web',
  resourceLinkVideo = 'i18n.resource.link_video',
  resourceFileType = 'i18n.resource.file_type',
  resourceImageType = 'i18n.resource.image_type',
  resourcePreviewNewDowloadTooltip = 'i18n.resource.preview.Download_tooltip',
  sendToGoogleDrive = 'i18n.resource.Send_to_Google_Drive',
  sendToOneDrive = 'i18n.resource.Send_to_One_Drive',
  downloadToDevice = 'i18n.resource.view.download_device',
  noPreviewAvailable = 'i18n.resource.no_preview',
  cannotBePreviewed = 'i18n.resource.cannot_be_previewed',
  estimatedClassTime = 'i18n.resource.estimated_class_time',
  timeOneToTwoHours = 'i18n.1_2_hours',
  timeTwoPlusHours = 'i18n.2+_hours',
  varies = 'i18n.Varies',
  changeRegion = 'i18n.Change_region',
  copyright = 'i18n.footer.Copyright',
  courseCongratsHeading = 'i18n.course.congrats.heading',
  courseCongratsWhatHappensNextSUbHeading = 'i18n.course.congrats.what_happens_next_subheading',
  courseCongratsHasAssignment = 'i18n.course.congrats.has_assignment',
  courseCongratsNoAssignment = 'i18n.course.congrats.no_assignment',
  courseCongratsHasAssignmentContent = 'i18n.course.congrats.has_assignment.content',
  courseCongratsNoAssignmentContent = 'i18n.course.congrats.no_assignment.content',
  courseCongratsSurveyHeading = 'i18n.course.congrats.course_survey.heading',
  courseCongratsSurveyPleaseComplete = 'i18n.course.congrats.course_survey.please_complete',
  courseCongratsSurveyContent = 'i18n.course.congrats.course_survey.content',
  courseCongratsTakeNextStep = 'i18n.course.congrats.take_next_step',
  courseCongratsWhenReady = 'i18n.course.congrats.when_ready',
  courseCongratsEnrollInEdEx = 'i18n.course.congrats.enroll.in_edex',
  courseSuccesfullyCompletedStatement = 'i18n.course.congrats.successfully_completed_statement',
  resourceBuilderSectionsEndOfSectionGuidance1 = 'i18n.resource.contribute.end_of_section1_guidance',
  resourceBuilderSectionsEndOfSectionGuidance2 = 'i18n.resource.contribute.end_of_section2_guidance',
  resourceBuilderSectionsEndOfSectionGuidance3 = 'i18n.resource.contribute.end_of_section3_guidance',
  resourceBuilderSectionsInfoFeaturedImage = 'i18n.resource.contribute.sections.info.Featured_Image',
  resourceBuilderSectionsInfoResourceTitle = 'i18n.resource.contribute.sections.info.Resource_Title',
  resourceBuilderSectionsInfoShortDescription = 'i18n.resource.contribute.sections.info.Short_Description',
  resourceBuilderSectionsInfoSubjectAndAge = 'i18n.resource.contribute.sections.info.Subject_And_Age',
  resourceBuilderSectionsContentWebLinksVideos = 'i18n.resource.contribute.sections.content.Files_web_links_videos',
  resourceBuilderSectionsContentDescription = 'i18n.resource.contribute.sections.content.Description',
  resourceBuilderSectionsDetailsTools = 'i18n.resource.contribute.sections.details.Tools',
  resourceBuilderSectionsDetailsTimeNeeded = 'i18n.resource.contribute.sections.details.Time_Needed',
  resourceBuilderSectionsDetailsKeywordsAndStandards = 'i18n.resource.contribute.sections.details.Keywords_And_Standards',
  resourceBuilderSectionsDetailsStudentSteps = 'i18n.resource.contribute.sections.details.StudentSteps',
  resourceBuilderSectionsDetailsLicensing = 'i18n.resource.contribute.sections.details.Licensing',
  resourceBuilderSectionsDetailsCommentsCheckbox = 'i18n.resource.contribute.sections.details.Checkbox',
  resourceBuilderSectionsDetailsComments = 'i18n.resource.contribute.sections.details.Comments',
  resourceContributeMobileViewTitle = 'i18n.resource.contribute.mobile.view.title',
  resourceContributeMobileViewDescription = 'i18n.resource.contribute.mobile.view.description',
  resourceContributeMobileViewLink = 'i18n.resource.contribute.mobile.view.link',
  resourceBuilderExampleLessonLink = 'i18n.resource.contribute.ExampleLesson',
  resourceBuilderExampleProjectLink = 'i18n.resource.contribute.ExampleProject',
  resourceBuilderExampleSupplementalLink = 'i18n.resource.contribute.ExampleSupplemental',
  resourceBuilderInfoSectionHeader = 'i18n.resource.contribute.Image-Title-Description',
  resourceBuilderCoverImage = 'i18n.Featured_Image',
  resourceBuilderCoverImageKeepInMind = 'i18n.resource.contribute.file_guidelines_heading',
  resourceBuilderCoverImageMaxSize = 'i18n.resource.restricted_filesize',
  resourceBuilderCoverImageSupportedTypes = 'i18n.resource.restricted_filetypes',
  resourceBuilderSelectCoverImage = 'i18n.resource.contribute.select_default_cover_image',
  resourceBuilderSelectCoverSubject = 'i18n.Add_Subjects',
  resourceBuilderSelectCoverMessage = 'i18n.resource.contribute.or_select_default_cover_image',
  resourceBuilderCoverSubjectAll = 'i18n.All_Subjects',
  resourceBuilderCoverSubjectDesign = 'i18n.subjects.DigitalMedia',
  resourceBuilderCoverSubjectHumanities = 'i18n.subjects.Humanities',
  resourceBuilderCoverSubjectMath = 'i18n.subjects.Mathematics',
  resourceBuilderCoverSubjectScience = 'i18n.subjects.Science',
  resourceBuilderCoverSubjectSocialStudies = 'i18n.subjects.SocialSciences',
  resourceBuilderCoverSubjectArt = 'i18n.subjects.Arts',
  resourceBuilderSectionsDetailsDropdownSelectAnOption = 'i18n.resource.contribute.Select_time_range',
  resourceBuilderPrimarySubject = 'i18n.resource.contribute.Main_Subject',
  resourceBuilderSecondarySubject = 'i18n.resource.contribute.Additional_Subjects',
  resourceBuilderAddSubjects = 'i18n.Add_Subjects',
  resourceBuilderSelectAdditionalSubjects = 'i18n.resource.contribute.Select_additional_subjects',
  resourceContributeMainAgeLevel = 'i18n.resource.contribute.Main_Age_Level',
  resourceContributeAdditionalAgeLevel = 'i18n.resource.contribute.Additional_Age_Levels',
  resourceContributeToolsAndTimeNeeded = 'i18n.resource.contribute.Tools_and_Time_Needed',
  resourceContributeWhatAdobeProducts = 'i18n.resource.contribute.What_Adobe_products',
  resourceContributeMainTool = 'i18n.resource.contribute.Main_tool',
  resourceContributeAdditionalTools = 'i18n.resource.contribute.Additional_tools',
  resourceContributeToolsGuidance = 'i18n.resource.contribute.tools_guidance',
  resourceContributeToolsInstructions = 'i18n.resource.contribute.Adobe_tools_used',
  resourceContributeToolsDefaultText = 'i18n.resource.contribute.tools_default_text',
  resourceContributeToolsAdditionalNote = 'i18n.resource.contribute.tools_additional_note',
  resourceContributeNoUrlsAllowed = 'i18n.resource.contribute.no_urls_allowed',
  resourceContributeTitlePlaceholder = 'i18n.resource.contribute.default_title_text',
  resourceContributeShortDescriptionPlaceholder = 'i18n.resource.contribute.default_short_description',
  resourceContributeReturnToEdit = 'i18n.resource.contribute.Return_to_edit',
  resourceContributePublishRequirements = 'i18n.resource.contribute.publish_requirements_note',
  resourceContributeDescriptionPlaceholder = 'i18n.resource.contribute.default_description',
  resourceContributeStudentStepsRequirements = 'i18n.resource.contribute.student_steps_requirements',
  resourceContributeStudentStepsPlaceholder = 'i18n.resource.contribute.student_step_placeholder',
  resourceHeaderSaveForLater = 'i18n.resource.header.saveForLater',
  resourceHeaderDownload = 'i18n.resource.header.Download',
  resourceHeaderNativeShareText = 'i18n.resource.header.nativeShareText',
  dropzoneDragAndDropFile = 'i18n.resource.contribute.drop_file',
  dropzoneDragAndDropFiles = 'i18n.materials.add_files',
  dropzoneSelectFile = 'i18n.resource.contribute.select_file',
  dropzoneSelectFiles = 'i18n.resource.contribute.Select_Files_from_computer',
  dropzoneInvalidExtension = 'i18n.dropzone.error_filetype',
  dropzoneFileTooLarge = 'i18n.dropzone.error_filesize',
  dropzoneTooManyFiles = 'i18n.dropzone.error_maxfiles',
  dropzoneMaxSize = 'i18n.resource.restricted_filesize',
  nothingAddedYet = 'i18n.resource.nothingAddedYet',
  resourceBuilderContentSectionFilesHeader = 'i18n.resource.contribute.Files_web_links_videos',
  resourceBuilderContentSectionAttachContent = 'i18n.resource.contribute.Attach_resource_content',
  resourceBuilderContentSectionAddLink = 'i18n.Add_Weblink',
  resourceBuilderContentSectionAddLinkDescription = 'i18n.Weblink_Description',
  resourceBuilderContentSectionAddCCXLink = 'i18n.Add_CCX_Template_link',
  resourceBuilderAddLink = 'i18n.Add_Web_Link',
  resourceBuilderAddCCXTemplateLink = 'i18n.Add_CCX_Template',
  resourceBuilderAddCCXLinkSubtitle = 'i18n.Add_CCX_Template.subtitle',
  resourceBuilderAddLinkModalUrnLinkError = 'i18n.weblink_modal.ccxUrnFormatLinkError',
  resourceBuilderAddedFiles = 'Added files',
  resourceBuilderLicensing = 'i18n.resource.contribute.Licensing',
  resourceBuilderCommonLicense = 'i18n.Common_License',
  resourceBuilderLicenseInstructions = 'i18n.resource.contribute.license_instructions',
  resourceBuilderKeywordAndStandards = 'i18n.resource.contribute.Keyword_and_Standards',
  resourceBuilderResourceKeywords = 'i18n.resource.contribute.Resource_Keywords',
  resourceBuilderSearchKeywordsResource = 'i18n.Search_Keywords_Resource',
  resourceBuilderFileDialogHeaderAdd = 'i18n.resource.contribute.Add_files',
  resourceBuilderFileDialogHeaderEdit = 'i18n.resource.contribute.Edit_file',
  resourceBuilderFileDialogFileName = 'i18n.resource.contribute.File_name',
  resourceBuilderDialogPublishedTitle = 'i18n.resource.contribute.Published_title',
  resourceBuilderDialogType = 'i18n.resource.contribute.Type',
  resourceBuilderLinkDialogUrl = 'i18n.resource.contribute.URL',
  resourceBuilderLinkDialogBranchUrl = 'i18n.resource.contribute.Branch.URL',
  resourceBuilderLinkDialogBranchUrlPlaceholder = 'i18n.resource.contribute.BranchURL.placeholder',
  resourceBuilderNoInfoEntered = 'i18n.resource.contribute.No_Information_Entered',
  resourceBuilderCoverImageGuideTitle = 'i18n.resource.contribute.cover_image_guidance',
  resourceBuilderCoverImageGuideDescription = 'i18n.resource.contribute.cover_image_guidance_description',
  resourceBuilderCoverImageDefaultText = 'i18n.resource.contribute.cover_image_location',
  resourceBuilderTitleGuideTitle = 'i18n.resource.contribute.title_guidance',
  resourceBuilderTitleGuideDescription = 'i18n.resource.contribute.title_guidance_description',
  resourceBuilderTitleDefaultText = 'i18n.resource.contribute.default_title_text',
  resourceBuilderShortDescriptionGuideTitle = 'i18n.resource.contribute.short_description_guidance',
  resourceBuilderShortDescriptionGuideDescription = 'i18n.resource.contribute.short_description_guidance_description',
  resourceBuilderShortDescriptionDefaultText = 'i18n.resource.contribute.default_short_description',
  resourceBuilderSubjectAndAgeGuideTitle = 'i18n.resource.contribute.Age_and_Subject',
  resourceBuilderSubjectAndAgeGuideDescription = 'i18n.resource.contribute.age_level_guidance',
  resourceBuilderContentGuideTitle = 'i18n.resource.contribute.Add_clear_adaptable',
  resourceBuilderContentGuideDescription = 'i18n.resource.contribute.content_guidance',
  resourceBuilderDescriptionGuideTitle = 'i18n.resource.contribute.Articulate_how_to_use_resource',
  resourceBuilderDescriptionGuideDescription = 'i18n.resource.contribute.long_description_guidance',
  resourceBuilderToolsGuideTitle = 'i18n.resource.contribute.tools_instructions',
  resourceBuilderToolsGuideDescription = 'i18n.resource.contribute.tools_guidance',
  resourceBuilderTimeGuideTitle = 'i18n.resource.contribute.Time_is_of_the_essence',
  resourceBuilderTimeGuideDescription = 'i18n.resource.contribute.time_guidance',
  resourceBuilderKeywordsGuideTitle = 'i18n.resource.contribute.Help_educators_find_your_resource',
  resourceBuilderKeywordsGuideDescription = 'i18n.resource.contribute.keyword_guidance',
  resourceBuilderStandardsGuideTitle = 'i18n.resource.contribute.Aligning_to_standards',
  resourceBuilderStandardsGuideDescription = 'i18n.resource.contribute.standards_guidance',
  resourceBuilderLicensingGuideTitle = 'i18n.resource.contribute.What_is_CC_license',
  resourceBuilderLicensingGuideDescription = 'i18n.resource.contribute.creative_commons_guidance',
  resourceBuilderAgeLevelDefaultText = 'i18n.resource.contribute.default_age_level_text',
  resourceBuilderSubjectDefaultText = 'i18n.resource.contribute.default_subject_text',
  publishedDialogTitle = 'i18n.resource.contribute.Your_resource_has_been_published',
  publishedDialogLabel = 'i18n.resource.contribute.Share_it_with_your_network',
  publishedDialogHeading = 'i18n.resource.contribute.Thanks_for_contributing_your_resource',
  publishedDialogYouCanFindResource = 'i18n.resource.contribute.You_can_find',
  publishedDialogYourResources = 'i18n.resource.contribute.Your_resources',
  publishedDialogReadyTo = 'i18n.resource.contribute.Ready_to',
  publishedDialogUploadAnotherResource = 'i18n.resource.contribute.Upload_another_resource',
  publishedDialogCopyResourceLink = 'i18n.resource.contribute.Copy_resource_link',
  publishedDialogCopiedToClipboard = 'i18n.resource.contribute.Copied_to_clipboard',
  publishedDialogFailedToCopyToClipboard = 'i18n.resource.contribute.Failed_to_copy_to_clipboard',
  resourceBuilderContinueToPreview = 'i18n.resource.contribute.Continue_To_Preview',
  resourceBuilderDiscardNotice = 'i18n.resource.contribute.Discard_Notice',
  resourceBuilderSaveAndPreview = 'i18n.resource.contribute.Save_and_Preview',
  resourceBuilderContinueEditing = 'i18n.resource.contribute.Continue_Editing',
  resourceBuilderSecondaryAuthorNameLabel = 'i18n.resourceBuilder.secondaryAuthor.nameLabel',
  resourceBuilderSecondaryAuthorNamePlaceholder = 'i18n.resourceBuilder.secondaryAuthor.namePlaceholder',
  resourceBuilderSecondaryAuthorProfileURLLabel = 'i18n.resourceBuilder.secondaryAuthor.profileURLLabel',
  resourceBuilderSecondaryAuthorProfileURLPlaceholder = 'i18n.resourceBuilder.secondaryAuthor.profileURLPlaceholder',
  resourceBuilderSecondaryAuthorNameDescription = 'i18n.resource.secondaryAuthor.nameDescription',
  resourceBuilderInvalidSecondaryAuthorProfileUrl = 'i18n.resource.secondaryProfileUrl.invalid',
  contentStandardsPlaceholder = 'i18n.resource.contribute.Type_standards',
  adobeAccount = 'i18n.adobe_account',
  settings = 'i18n.settings',
  typeIsRequired = 'i18n.Type_is_required',
  instructorLedLabel = 'i18n.professionalLearning.contentType.instructorLed',
  selfPacedLabel = 'i18n.professionalLearning.contentType.selfPaced',
  instructorLedDescription = 'i18n.professionalLearning.contentType.instructorLed.description',
  selfPacedDescription = 'i18n.professionalLearning.contentType.selfPaced.description',
  toolkitLabel = 'i18n.professionalLearning.contentType.toolkit',
  toolkitDecription = 'i18n.professionalLearning.contentType.toolkit.description',
  typeKey = 'i18n.courseCreation.Type',
  professionalLearningSelfPacedHeading = 'i18n.professionalLearning.selfPaced.heading',
  professionalLearningToolkitsHeading = 'i18n.professionalLearning.toolkits.heading',
  professionalLearningViewAll = 'i18n.professionalLearning.viewAll',
  professionalLearningToolkits = 'i18n.professionalLearning.toolkits',
  searchBannerHeader = 'i18n.resource.search.banner.Header',
  searchBannerContent = 'i18n.resource.search.banner.Content',
  featuredResourcesHeading = 'i18n.teachingResources.featured.heading',
  curatedCollectionsHeading = 'i18n.teachingResources.curatedCollections.heading',
  searchResultsSeeAllRelevantEntitiesWithCount = 'i18n.search.searchResults.seeAllRelevantEntitiesWithCount',
  searchResultsSeeAllRelevantEntitiesWithoutCount = 'i18n.search.searchResults.seeAllRelevantEntitiesWithoutCount',
  searchTeachingResourcesEntityTypePlural = 'i18n.search.teachingResources.entityTypePlural',
  searchProfessionalLearningEntityTypePlural = 'i18n.search.professionalLearning.entityTypePlural',
  searchDiscussionsEntityTypePlural = 'i18n.search.discussions.entityTypePlural',
  searchCollectionsEntityTypePlural = 'i18n.search.collections.entityTypePlural',
  searchResultsShowingAll = 'i18n.search.searchResults.showingAll',
  searchResultsNoResults = 'i18n.search.searchResults.noResultsInCategoryOrCategories',
  searchResultsEitherOfTwoCategories = 'i18n.search.searchResults.eitherOfTwoCategories',
  searchResultsForRelevantQuery = 'i18n.search.searchResults.forRelevantQuery',
  searchResultsTeachingResourcesHeader = 'i18n.search.searchResults.teachingResources.header',
  searchResultsNoResultsTeachingResourcesHeader = 'i18n.search.searchResults.noResults.teachingResources.header',
  searchResultsProfessionalLearningHeader = 'i18n.search.searchResults.professionalLearning.header',
  searchResultsDiscussionsHeader = 'i18n.search.searchResults.discussions.header',
  searchResultsCollectionsHeader = 'i18n.search.searchResults.collections.header',
  searchBarPlaceholder = 'i18n.search.searchBar.placeholder',
  searchFiltersHeading = 'i18n.search.searchFilters.heading',
  searchFiltersClear = 'i18n.search.searchFilters.clear',
  searchFiltersSortByHeading = 'i18n.search.searchFilters.sortBy.heading',
  searchFiltersSortByRelevance = 'i18n.search.searchFilters.sortBy.relevance',
  searchFiltersSortByDate = 'i18n.search.searchFilters.sortBy.date',
  searchFiltersSortByViews = 'i18n.search.searchFilters.sortBy.views',
  searchFiltersTypeHeading = 'i18n.search.searchFilters.type.heading',
  searchFiltersTypeAll = 'i18n.search.searchFilters.type.all',
  searchFiltersDurationAll = 'i18n.search.searchFilters.duration.all',
  searchFiltersSubjectsHeading = 'i18n.search.searchFilters.subjects.heading',
  searchFiltersProductsHeading = 'i18n.search.searchFilters.products.heading',
  searchFiltersProductsAll = 'i18n.search.searchFilters.products.all',
  searchFiltersAgeLevelHeading = 'i18n.search.searchFilters.ageLevel.heading',
  searchFiltersActiveStudentTimeHeading = 'i18n.search.searchFilters.activeStudentTime.heading',
  searchFiltersActiveStudentTimeAll = 'i18n.search.searchFilters.activeStudentTime.all',
  searchResultsHaveNotLearnedQuery = 'i18n.search.noResults.haveNotLearnedQuery',
  searchResultsEntitySearchTypeSuggestion = 'i18n.search.noResults.entitySearchTypeSuggestion',
  searchResultsSearchByProduct = 'i18n.search.searchType.searchByProduct',
  searchResultsSearchBySubject = 'i18n.search.searchType.searchBySubject',
  searchResultsSorryNoResults = 'i18n.search.searchResults.sorryNoResults',
  paginationSinglePage = 'i18n.pagination.total.single.page',
  paginationMultiPages = 'i18n.pagination.total.mutiple.pages',
  resourceDownloadLocal = 'i18n.resource.view.Download_Local',
  copyMobileModalBody = 'i18n.Copy.Mobile.Modal.Body',
  copyMobileModalTitle = 'i18n.Copy.Mobile.Modal.Title',
  resourceDownloadGoogleDrive = 'i18n.resource.view.Download_GD',
  resourceDownloadOneDrive = 'i18n.resource.view.Download_OD',
  viewGoogleDrive = 'i18n.resource.view.View_GD',
  viewOneDrive = 'i18n.resource.view.View_OD',
  gotIt = 'i18n.resource.view.Got_It',
  exportLocalDialogText = 'i18n.resource.view.Export_Local_Dialog_Text',
  exportGoogleDriveDialogText = 'i18n.resource.view.Export_GD_Dialog_Text',
  exportOneDriveDialogText = 'i18n.resource.view.Export_OD_Dialog_Text',
  enrolled = 'i18n.enrolled',
  courseClosed = 'i18n.closed',
  inProgress = 'i18n.in_progress',
  pendingReview = 'i18n.pending_review',
  complete = 'i18n.complete',
  preferencesTitlePart1 = 'i18n.preferences.title.part1',
  preferencesTitlePart2 = 'i18n.preferences.title.part2',
  preferencesTitleHomepageUserPart1 = 'i18n.preferences.title.homepageuser.part1',
  preferencesTitleHomepageUserPart2 = 'i18n.preferences.title.homepageuser.part2',
  preferencesSubHeading = 'i18n.preferences.subheading',
  preferencesBtnText = 'i18n.preferences.buttonText',
  seoSettingsIndexHeading = 'i18n.resource.settings.seo.indexHeading',
  seoSettingsIndexLabel = 'i18n.resource.settings.seo.indexLabel',
  createNewProject = 'i18n.tools.ccx.new_project',
  signInCheckAccess = 'i18n.tools.ccx.signin',
  getAccess = 'i18n.tools.ccx.get_access',
  seeAllResources = 'i18n.See_all_resources',
  seeAllCollections = 'i18n.See_all_collections',
  browseEntireLibrary = 'i18n.browse.entire.library',
  personalizedResourceHeading = 'i18n.personalized.resource.heading',
  personalizedSeeMoreResources = 'i18n.personalized.resource.seemoreresources',
  resourceCardIncludesCCXTemplate = 'i18n.resource.card.includes.ccx.template',
  thirtyMinutes = 'i18n.searchFilters.duration.30_Min',
  oneHour = 'i18n.searchFilters.duration.1_Hour',
  twoHours = 'i18n.searchFilters.duration.2_Hours',
  threeHours = 'i18n.searchFilters.duration.3_Hours',
  searchFiltersCourseDurationHeading = 'i18n.searchFilters.course.duration.Heading',
  copyProjectDialogHeading = 'i18n.filePreview.copyProjectDialog.Heading',
  copyProjectDialogSubHeading = 'i18n.filePreview.copyProjectDialog.SubHeading',
  filePreviewCCXEntitlementErrorBtnText = 'i18n.filePreview.ccxEntitlementError.BtnText',
  filePreviewCCXEntitlementErrorText = 'i18n.filePreview.ccxEntitlementError.Text',
  attachmentPreviewCCXEntitlementErrorText = 'i18n.attachmentPreview.ccxEntitlementError.Text',
  professionalLearningNavLinkLabel = 'i18n.courses.nav.link',
  courseAttemptMobileViewTitle = 'i18n.courses.attempt.mobile.view.title',
  courseAttemptMobileViewDescription = 'i18n.courses.attempt.mobile.view.description',
  allAuthor = 'i18n.author.all',
  expressTemplateComponentLabel = 'i18n.resourceComponent.ExpressTemplate',
  favouritedResourcesTab = 'i18n.myEdex.tab.mySaved',
  createdByMeTab = 'i18n.myEdex.tab.createdByMe',
  resourcesHeading = 'i18n.Resources',
  createNewResourcebtn = 'i18n.dashboard.teaching.Create_New_Resource',
  all = 'i18n.All',
  drafts = 'i18n.dashboard.Drafts',
  live = 'i18n.dashboard.Live',
  recentlySaved = 'i18n.myEdex.filter.recentlySaved',
  recentlyModified = 'i18n.myEdex.filter.recentlyModified',
  lastModified = 'i18n.myEdex.filter.lastModified',
  lastCreated = 'i18n.myEdex.filter.lastCreated',
  recentlyCreated = 'i18n.myEdex.filter.recentlyCreated',
  title = 'i18n.Title',
  professionalLearning = 'i18n.dashboard.learning.Professional_Learning',
  completed = 'i18n.Course_Card.Completed',
  pendingReviewTxt = 'i18n.myEdex.filter.pendingReview',
  browseCourses = 'i18n.myEdex.emptyState.btntext1',
  emptyStateCourseDescription = 'i18n.myEdex.emptyState.description1',
  emptyStateExternalCourseCreatedTabDesc = 'i18n.myEdex.externalCourse.emptyState.createdTabDesc',
  emptyStateExternalCourseStaffTabDesc = 'i18n.myEdex.externalCourse.emptyState.staffTabDesc',
  emptyStateExternalCourseActiveTabDesc = 'i18n.myEdex.externalCourse.emptyState.activeTabDesc',
  emptyStateExternalCourseArchiveTabDesc = 'i18n.myEdex.externalCourse.emptyState.archiveTabDesc',
  emptyStateCourseCompletedDescription = 'i18n.myEdex.emptyState.description2',
  emptyStatePlaylistListDescription = 'i18n.myEdex.emptyState.playlist.description',
  closed = 'i18n.filter.closed',
  templateOtherLabel = 'i18n.resourceComponent.TemplateOther',
  externalCourse = 'i18n.myEdex.menuItem.externalCourse',
  playlists = 'i18n.myEdex.menuItem.playlists',
  playlistCreateHeading = 'i18n.playlists.createHeading',
  playlistCreateDescription = 'i18n.playlists.createDescription',
  studentUnsubscribePageHeader = 'i18n.student.unsubscribe.header',
  studentUnsubscribeFormEmailLabel = 'i18n.student.unsubscribe.form.email-label',
  studentUnsubscribeFormBtnText = 'i18n.student.unsubscribe.form.btn-text',
  studentUnsubscribePrivacyPolicy = 'i18n.student.unsubscribe.privacy-description',
  studentUnsubscribeSuccessHeader = 'i18n.student.unsubscribe.success.header',
  k12EmailsSubscriptionPageHeader = 'i18n.K12Emails.subscribe.header',
  k12EmailsSubscribeFormEmailLabel = 'i18n.K12Emails.subscribe.form.email-label',
  k12EmailsSubscribeFormBtnText = 'i18n.K12Emails.subscribe.form.btn-text',
  k12EmailsSubscribePrivacyPolicy = 'i18n.K12Emails.subscribe.privacy-policy',
  k12EmailsSubscribePrivacyDesc = 'i18n.K12Emails.subscribe.privacy-description',
  k12EmailsSubscribeSuccessHeader = 'i18n.K12Emails.subscribe.success.header',
  k12EmailsSubscribeSuccesseDesc1 = 'i18n.K12Emails.subscribe.success.description-1',
  k12EmailsSubscribeSuccesseDesc2 = 'i18n.K12Emails.subscribe.success.description-2',
  k12EmailsSubscribeSuccesseDesc3 = 'i18n.K12Emails.subscribe.success.description-3',
  termsAOfUseLink = 'i18n.footer.links.Terms_of_Use',
  termsOfUse = 'i18n.footer.Terms_of_Use',
  privacyPolicyLink = 'i18n.footer.links.Privacy',
  k12EmailSubscribeDescription = 'i18n.K12Emails.subscribe.description',
  k12EmailSubscribeSuccessDescription = 'i18n.K12Emails.subscribe.success-description',
  playlistAddDialogHeader = 'i18n.playlists.addDialog.header',
  playlistNotificationManageButton = 'i18n.playlists.notification.manageButton',
  playlistName = 'i18n.playlists.playlistName',
  playlistDescription = 'i18n.playlists.playlistDescription',
  charactersRemaining = 'i18n.playlists.charactersRemaining',
  addPlaylistShortDescription = 'i18n.playlists.addPlaylistShortDescription',
  playlistCreateNewHeading = 'i18n.playlists.createPlaylist.createNew',
  playlistShareableLink = 'i18n.playlist.shareableLink',
  playlistCreateCancelBtn = 'i18n.playlist.createNew.cancel',
  playlistAddDialogMainHeader = 'i18n.playlist.addDialog.mainHeader',
  playlistAddDialogDescription = 'i18n.playlist.addDialog.description',
  playlistAddDialogAddBtn = 'i18n.playlist.addDialog.addBtn',
  playlistAddDialogCancelBtn = 'i18n.playlist.addDialog.cancelBtn',
  playlistAddDialogMySaved = 'i18n.playlist.addDialog.mySaved',
  leadershipBadgeCategory = 'i18n.badge.leadership',
  creativeEducatorLevel2Badge = 'i18n.badge.Creative_Educator_Level_2',
  adobeEducationLeaderbadge = 'i18n.badge.AEL_Badge',
  createResourceRestrictionHeading = 'i18n.badge.createResourceRestrictionHeading',
  createResourceRestrictionParagraph = 'i18n.badge.createResourceRestrictionParagraph',
  createResourceRestrictionLearnMore = 'i18n.badge.createResourceRestrictionLearnMore',
  playlistsResourceTab = 'i18n.playlist.tab.Resource',
  playlistsReOrderTab = 'i18n.playlist.tab.Reorder',
  playlistsPreviewTab = 'i18n.playlist.tab.Preview',
  playlistsEditDetailsTab = 'i18n.Edit_Details',
  playlistsShareTab = 'i18n.playlist.tab.Share',
  playlistReorderDragToReorder = 'i18n.playlist.reorder.dargToShare',
  createPlaylist = 'i18n.playlist.create',
  continue = 'i18n.Continue',
  playlistDeletedMessage = 'i18n.playlist.message.playlistDeleted',
  playlistCreatedMessage = 'i18n.playlist.message.playlistCreated',
  playlistUpdatedMessage = 'i18n.playlist.message.playlistUpdated',
  playlistItemsDeleted = 'i18n.playlist.message.playlistItemsDeleted',
  playlistTitleRequired = 'i18n.playlist.message.playlistTitleRequired',
  playlisDescriptionNotContainURL = 'i18n.playlist.message.playlisDescriptionNotContainURL',
  playlisTitleNotContainURL = 'i18n.playlist.message.playlisTitleNotContainURL',
  signUpDialogHeading = 'i18n.signUpDialog.heading',
  signUpDialogDescription = 'i18.signUpDialog.description',
  signUpDialogPersonalBtn = 'i18n.signUpDialog.personalBtn',
  signUpDialogSchoolBtn = 'i18n.signUpDialog.schoolBtn',
  signUpDialogContentLine1 = 'i18n.signUpDialog.content.line1',
  signUpDialogContentLine2 = 'i18n.signUpDialog.content.line2',
  signUpDialogContentLine3 = 'i18n.signUpDialog.content.line3',
  consumerViewResourceEmptyDescription = 'i18n.playlists.consumerViewResourceEmptyDescription',
  resourcePreviewWebpageCTA = 'i18n.resource.preview.cta.webpage',
  resourcePreviewEditCTA = 'i18n.resource.preview.cta.edit',
  resourceSidebarTabLearningObjectives = 'i18n.resource.sidebar.tab.learningObjectives',
  resourceSidebarTabTools = 'i18n.resource.sidebar.tab.tools',
  resourceSidebarStudentSteps = 'i18n.resource.sidebar.studentSteps',
  homePageGuestHeroBannerFree = 'i18n.homepage.guest.herobanner.free',
  homePageGuestHeroBannerTeachingResources = 'i18n.homepage.guest.herobanner.teachingResources',
  homePageGuestHeroBannerProfessionalLearning = 'i18n.homepage.guest.herobanner.professionalLearning',
  homePageGuestHeroBannerHeading = 'i18n.homepage.guest.herobanner.heading',
  homePageGuestHeroBannerSubHeading = 'i18n.homepage.guest.herobanner.subheading',
  homePageGuestStudentSampleHeading_1 = 'i18.Find',
  homePageGuestStudentSampleHeading_2 = 'i18n.Lessons',
  homePageGuestStudentSampleHeading_3 = 'i18n.homepage.guest.studentSample.heading_3',
  homePageGuestStudentSampleViewAllLessons = 'i18n.homepage.guest.studentSample.viewAllLessons',
  homePageGuestTemplateSampleViewFullLesson = 'i18n.homepage.guest.studentSample.ViewFullLesson',
  homePageGuestTemplateSampleLessonPlanIncludes = 'i18n.homepage.guest.studentSample.LessonPlanIncludes',
  homePageGuestTemplateSampleView = 'i18n.View',
  homePageGuestTemplateSampleLessons = 'i18n.Lessons',
  homePageGuestTemplateSampleBrowseMore = 'i18.BrowseMore',
  homePageGuestTemplateSamplePrimary = 'i18n.academicLevel.category.Primary',
  homePageGuestTemplateSampleSecondary = 'i18n.academicLevel.category.Secondary',
  homePageGuestTemplateSampleHED = 'i18n.academicLevel.category.Higher_Education',
  homePageGuestTemplateBringCreativity = 'i18n.homepage.guest.bringCreativity',
  homePageGuestTemplateStartBrowsingBySubject = 'i18n.homepage.guest.startBrowsingBySubject',
  homePageGuestCollectionHeading = 'i18n.homepage.guest.collectionHeading',
  homePageGuestCollectionHeading_2 = 'i18n.homepage.guest.collectionHeading_2',
  homePageGuestCollectionHeading_3 = 'i18n.homepage.guest.collectionHeading_3',
  homepageGuestBrowseLibraryBtn = 'i18n.homepage.guest.browseLibraryBtn',
  homepageLoggedInBrowseLibraryBtn = 'i18n.homepage.loggedIn.browseLibraryBtn',
  resourcePreviewOpenCTA = 'i18n.resource.preview.cta.open',
  homePageGuestPLHeading = 'i18n.homepage.guest.pl.heading',
  homePageGuestPLContentHeading = 'i18n.homepage.guest.pl.content.heading',
  homePageGuestPLContent = 'i18n.homepage.guest.pl.content',
  homePageGuestPLContentButtonText = 'i18n.homepage.guest.pl.content.button.text',
  homePageUserFeaturedCollectionHeading = 'i18n.homepage.user.featuredcollectionHeading',
  homePageUserFeaturedCollectionHeading_2 = 'i18n.homepage.user.featuredcollectionHeading_2',
  homePageTools = 'i18n.homepage.guest.pl.tools',
  adobe = 'i18n.Adobe',
  alreadyHaveAnAccount = 'i18n.signUpDialog.alreadyHaveAnAccount',
  btsBannerText = 'i18n.btsbanner.text',
  studentStepsTabName = 'i18n.resource.studentSteps.studentStepsTabName',
  homePageUserHeroBannerHeading1 = 'i18n.homepage.user.herobanner.heading1',
  homePageUserHeroBannerHeading2 = 'i18n.homepage.user.herobanner.heading2',
  homePageUserHeroBannerHeading3 = 'i18n.homepage.user.herobanner.heading3',
  homePageUserHeroBannerSubheading = 'i18n.homepage.user.herobanner.subheading',
  btsBannerTextV2 = 'i18n.btsbanner.text.v2',
  emptyStateCoursestaffAssignedCoursesDescription = 'emptyStateCoursestaffAssignedCoursesDescription',
  staffAssignedCourses = 'i18n.myEdex.tab.staffAssignedCourses',
  schoolDistrictDialogHeader = 'i18n.schoolDistrictDialog.header',
  schoolDistrictDialogDescription = 'i18n.schoolDistrictDialog.description',
  schoolDistrictDialogDoItLater = 'i18n.schoolDistrictDialog.doItLater',
  schoolDistrictDialogInputLabel = 'i18n.schoolDistrictDialog.input.schoolDistrict',
  schoolDistrictDialogInputPlaceHolder = 'i18n.schoolDistrictDialog.input.placeholder',
  liveSessionHeader = 'i18n.liveSessionDialog.header',
  liveSessionHeaderProgress = 'i18n.liveSessionDialog.header.progress',
  liveSessionSubHeader = 'i18n.liveSessionDialog.subheader',
  liveSessionCopyHeader = 'i18n.liveSessionCopyDialog.header',
  liveSessionCopySubHeader = 'i18n.liveSessionCopyDialog.subheader',
  liveSessionCreate = 'i18n.button.Create',
  liveSessionDone = 'i18n.button.Done',
  sessionId = 'i18n.liveSession.sessionId',
  ncesId = 'i18n.liveSession.ncesId',
}
