import { MetadataModelKey } from '@adobe/edex/ui/shared/types';
import { SearchEntityType, SearchType } from '@adobe/edex/ui/shared/types/search';

export const SEARCH_FILTER_METADATA_TYPES = [
  MetadataModelKey.subjects,
  MetadataModelKey.products,
  MetadataModelKey.academicLevels,
  MetadataModelKey.timing,
  MetadataModelKey.productUsedCount,
  MetadataModelKey.duration,
];

export const SEARCH_TYPE_2_PARENT: Record<SearchType, SearchType> = {
  [SearchType.teachingResources]: SearchType.teachingResources,
  [SearchType.projects]: SearchType.teachingResources,
  [SearchType.supplemental]: SearchType.teachingResources,
  [SearchType.professionalLearning]: SearchType.professionalLearning,
  [SearchType.selfPacedCourses]: SearchType.professionalLearning,
  [SearchType.toolkits]: SearchType.professionalLearning,
  [SearchType.discussions]: SearchType.discussions,
  [SearchType.collections]: SearchType.collections,
};

export const SEARCH_TYPE_2_ENTITY: Record<SearchType, SearchEntityType> = {
  [SearchType.teachingResources]: SearchEntityType.resources,
  [SearchType.projects]: SearchEntityType.resources,
  [SearchType.supplemental]: SearchEntityType.resources,
  [SearchType.professionalLearning]: SearchEntityType.courses,
  [SearchType.selfPacedCourses]: SearchEntityType.courses,
  [SearchType.toolkits]: SearchEntityType.courses,
  [SearchType.discussions]: SearchEntityType.discussions,
  [SearchType.collections]: SearchEntityType.collections,
};

export const SEARCH_PARENT_TYPES = [
  SearchType.teachingResources,
  SearchType.professionalLearning,
  SearchType.discussions,
];

export const SEARCH_SUB_TYPES = [
  SearchType.projects,
  SearchType.supplemental,
  SearchType.selfPacedCourses,
  SearchType.toolkits,
];

export const SEARCH_TYPES = [
  SearchType.projects,
  SearchType.supplemental,
  SearchType.selfPacedCourses,
  SearchType.toolkits,
  SearchType.teachingResources,
  SearchType.discussions,
  SearchType.professionalLearning,
];

export const SEARCH_ENTITY_2_TYPE = new Map([
  [SearchEntityType.resources, SearchType.teachingResources],
  [SearchEntityType.courses, SearchType.professionalLearning],
  [SearchEntityType.discussions, SearchType.discussions],
  [SearchEntityType.collections, SearchType.collections],
]);

export const SEARCH_ENTITY_TYPE_2_SEARCH_TYPES = new Map([
  [SearchEntityType.resources, [SearchType.projects, SearchType.supplemental]],
  [SearchEntityType.courses, [SearchType.selfPacedCourses, SearchType.toolkits]],
  [SearchEntityType.discussions, [SearchType.discussions]],
  [SearchEntityType.collections, [SearchType.collections]],
]);

export enum SearchFilterAlias {
  humanities = 'humanities',
  stem = 'stem',
  socialSciences = 'social-sciences',
  digitalMediaArt = 'digital-media-art',
  higherEd = 'higher-education',
}

export const SEARCH_COLLECTIONS_SUCCESS = 'SEARCH_COLLECTIONS/SUCCESS';

export enum BLUE_HEADER_IMAGES {
  blueCircle = 'blue-circle',
  blueTriangle = 'blue-triangle',
  blueBlob = 'blue-blob',
  blueArrow = 'blue-arrow',
}

export enum PINK_HEADER_IMAGES {
  pinkCircle = 'pink-circle',
  pinkTriangle = 'pink-triangle',
  pinkBlob = 'pink-blob',
  pinkArrow = 'pink-arrow',
}

export enum GREEN_HEADER_IMAGES {
  greenCircle = 'green-circle',
  greenTriangle = 'green-triangle',
  greenBlob = 'green-blob',
  greenArrow = 'green-arrow',
}

export enum YELLOW_HEADER_IMAGES {
  yellowCircle = 'yellow-circle',
  yellowTriangle = 'yellow-triangle',
  yellowBlob = 'yellow-blob',
  yellowArrow = 'yellow-arrow',
}
export enum SALMON_HEADER_IMAGES {
  salmonCircle = 'salmon-circle',
  salmonTriangle = 'salmon-triangle',
  salmonBlob = 'salmon-blob',
  salmonArrow = 'salmon-arrow',
}

export enum COLOR {
  blue = 'blue',
  pink = 'pink',
  green = 'green',
  yellow = 'yellow',
  salmon = 'salmon',
}

export const DEBOUNCE_TIME = 300;

export const LESSON_PLANS = 'lesson-plans';

export const ADOBE_EXPRESS = 'Adobe Express';
