import { getApiInstance, localeRequestInterceptor, newrelicResponseInterceptor } from '@adobe/edex/ui/shared/api/utils';
import { IMS } from '@adobe/edex/ui/shared/config/ims';

export const BOOTCAMP = 'bootcamp';

export const v3Auth = getApiInstance({
  config: {
    validateStatus: null,
  },
  interceptors: [localeRequestInterceptor, newrelicResponseInterceptor],
});

export const authenticate = async () => {
  const imsToken = await IMS.getAccessToken();
  const isBootcamp = !!localStorage.getItem(BOOTCAMP);
  const Authorization = `Bearer ${imsToken}`;
  return v3Auth.post('/authenticate', isBootcamp ? { isBootcamp } : null, {
    headers: { Authorization },
  });
};
