import { PlaylistSchema } from '@adobe/edex/ui/types';
import { EdexAction } from '@adobe/edex/ui/store/reducers/helpers';
import {
  createPlaylist,
  deletePlaylist,
  deletePlaylistItems,
  fetchAllPlaylists,
  fetchPlaylist,
  updatePlaylist,
} from '../../routines';
import { fetchPlaylistLists } from '../../routines/index';
import { errorToast } from '@adobe/edex/ui/utils/notification';

export interface PlaylistState {
  loading: {
    all: boolean;
    playlist: boolean;
  };
  all: PlaylistSchema[];
  playlist: PlaylistSchema;
}

const initialState = Object.freeze({
  loading: { all: true, playlist: true, notFound: false, addData: false, isCreating: false },
  all: [],
  playlist: {
    id: '',
    author: {},
    items: { data: [], meta: { limit: 20, offset: 0, hasMore: false } },
    title: '',
    vanityURL: '',
    description: '',
  },
  playlistLists: {
    itemExistedPlaylist: [],
    itemNotExistedPlaylist: [],
  },
});

export function playlist(state = initialState, action: EdexAction<any>): typeof initialState {
  switch (action.type) {
    case fetchPlaylist.TRIGGER:
      return {
        ...state,
        loading: { all: false, playlist: true, notFound: false, addData: false, isCreating: false },
      };
    case fetchPlaylist.SUCCESS:
      return {
        ...state,
        playlist: action.payload,
        loading: { all: false, playlist: true, notFound: false, addData: true, isCreating: false },
      };
    case fetchPlaylist.FULFILL:
      return {
        ...state,
        loading: { ...state.loading, all: false, playlist: false, addData: false },
      };
    case fetchPlaylist.FAILURE:
      return { ...state, loading: { all: false, playlist: false, notFound: true, addData: false, isCreating: false } };
    case fetchAllPlaylists.TRIGGER:
      return {
        ...state,
        loading: { all: true, playlist: false, notFound: false, addData: false, isCreating: false },
      };
    case fetchAllPlaylists.SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: { all: true, playlist: false, notFound: false, addData: false, isCreating: false },
      };
    case fetchAllPlaylists.FULFILL:
      return {
        ...state,
        loading: { all: false, playlist: false, notFound: false, addData: false, isCreating: false },
      };
    case updatePlaylist.TRIGGER:
      return {
        ...state,
        loading: { all: false, playlist: true, notFound: false, addData: false, isCreating: false },
      };
    case updatePlaylist.FULFILL:
      return {
        ...state,
        loading: { all: false, playlist: false, notFound: false, addData: false, isCreating: false },
      };

    case deletePlaylistItems.TRIGGER:
      return {
        ...state,
        loading: { all: false, playlist: true, notFound: false, addData: false, isCreating: false },
      };
    case deletePlaylistItems.FULFILL:
      return {
        ...state,
        loading: { all: false, playlist: true, notFound: false, addData: false, isCreating: false },
      };

    case fetchPlaylistLists.TRIGGER:
      return {
        ...state,
        loading: { all: true, playlist: false, notFound: false, addData: false, isCreating: false },
      };

    case fetchPlaylistLists.SUCCESS:
      return {
        ...state,
        playlistLists: action.payload,
        loading: { all: true, playlist: false, notFound: false, addData: false, isCreating: false },
      };

    case fetchPlaylistLists.FULFILL:
      return {
        ...state,
        loading: { all: false, playlist: false, notFound: false, addData: false, isCreating: false },
      };

    case fetchPlaylistLists.FAILURE:
      return state;

    case createPlaylist.TRIGGER:
      return { ...state, loading: { ...state.loading, isCreating: false } };

    case createPlaylist.SUCCESS: {
      return { ...state, playlist: action.payload, loading: { ...state.loading, isCreating: true } };
    }
    case createPlaylist.FAILURE: {
      errorToast({
        message: action.payload.response?.data?.message,
      });
      return state;
    }
    case createPlaylist.FULFILL: {
      return { ...state, playlist: initialState.playlist, loading: { ...state.loading, isCreating: false } };
    }
    // eslint-disable-next-line no-fallthrough
    case deletePlaylist.SUCCESS:
    default:
      return state;
  }
}
