import { authRequestInterceptor } from '@adobe/edex/ui/shared/api/utils';
import { environment } from '@env/environment';

import { getV3ApiInstance } from './v3';

const baseURL = (path: string) => `${environment.api}/${path}`;

export const resourceAPI = getV3ApiInstance({
  baseURL: baseURL('resources'),
});

export const searchAPI = getV3ApiInstance({
  baseURL: baseURL('search'),
});

export const commentAPI = getV3ApiInstance({
  baseURL: baseURL('comments'),
});

export const abuseAPI = getV3ApiInstance({
  baseURL: baseURL('abuse'),
});

export const favoriteAPI = getV3ApiInstance({
  baseURL: baseURL('favorite'),
});

export const ratingAPI = getV3ApiInstance({
  baseURL: baseURL('ratings'),
});

export const fileAPI = getV3ApiInstance({
  baseURL: baseURL('files'),
});

export const countAllAPI = getV3ApiInstance({
  baseURL: baseURL('count-all'),
});

export const selfAPI = getV3ApiInstance({ baseURL: baseURL('self') });

export const metadataAPI = getV3ApiInstance({ baseURL: baseURL('metadata') });

export const analyticsAPI = getV3ApiInstance({
  baseURL: baseURL('analytics'),
});

export const tagsAPI = getV3ApiInstance({
  baseURL: baseURL('tags'),
});

export const coursesAPI = getV3ApiInstance({
  baseURL: baseURL('courses'),
});

export const entitlementsAPI = getV3ApiInstance({ baseURL: baseURL('entitlements') }, [authRequestInterceptor]);

export async function fetchAceReportUrl(filename: string) {
  const response = await coursesAPI.get(`/aceReport/${filename}`);
  return response.data?.url;
}
export const curationAPI = getV3ApiInstance({
  baseURL: baseURL('curation'),
});

export const staticPageAPI = getV3ApiInstance({
  baseURL: baseURL('staticpage'),
});

export const playlistsAPI = getV3ApiInstance({
  baseURL: baseURL('playlist'),
});

export const newsletterAPI = getV3ApiInstance({
  baseURL: baseURL('newsletter'),
});

export const studentAPI = getV3ApiInstance({
  baseURL: baseURL('student'),
});

export const externalCourseAPI = getV3ApiInstance({
  baseURL: baseURL('api/v1/courses'),
});
