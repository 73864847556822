import querystring from 'querystring';
import { isEmpty, memoize, omitBy } from 'lodash-es';
import {
  CourseTypeID,
  CourseTypeRoutePath,
  Locale,
  QueryParam,
  ResourceBuilderPath,
  RoutePath,
} from '@adobe/edex/ui/shared/constants';
import { getCurrentLocale, getUrlLocale } from '@adobe/edex/ui/shared/config/i18n';
import { buildPath } from '@adobe/edex/ui/shared/utils/index';
import { SearchEntityType, SearchKey, SearchParams, SearchType } from '@adobe/edex/ui/shared/types/search';
import { SEARCH_ENTITY_2_TYPE, SearchFilterAlias } from '@adobe/edex/ui/components/search-page/constants';
import { AcademicLevelSchema, CourseTypeSchema, ProductSchema, SubjectSchema } from '@adobe/edex/ui/shared/types';
import { getBaseURL, getNavSource } from '@adobe/edex/ui/utils';

export const getQueryParams = memoize((search: string) => (search ? querystring.parse(search.replace(/^\?/, '')) : {}));

export const getQueryParam = (qp: string) => getQueryParams(location.search)[qp] as string;

export const getSearchString = (queryParams: Partial<Record<string, any>>, mergeQueryParams = false) => {
  if (isEmpty(queryParams)) {
    return '';
  }
  const params = omitBy(
    mergeQueryParams ? { ...getQueryParams(location.search), ...queryParams } : queryParams,
    (value, key) => !(key && value)
  );
  return querystring.stringify(params);
};

export const historyReplace = (path: string) => history.replaceState('', null, path);

export const buildLink = (path: string, queryParams?: Record<string, string>) => {
  if (path?.startsWith('http')) {
    return path;
  }
  const currentLocale = getCurrentLocale();
  const locale = currentLocale === Locale.en ? '' : `/${currentLocale}`;
  return buildPath({
    pathname: locale && !getUrlLocale(path) ? `${locale}${path}` : path,
    search: queryParams ? getSearchString(queryParams) : '',
  });
};

export const getResourceDetailsUrl = (vanityURL: string, queryParams?: Record<string, string>) => {
  const src = getNavSource();
  return buildLink(`${RoutePath.teachingResources}/${vanityURL}`, { ...queryParams, ...(src.length > 0 && { src }) });
};

export const getCollectionDetailsUrl = (vanityURL: string) => getBaseURL() + '/' + vanityURL;

export const getResourcePreviewUrl = (vanityURL: string, queryParams?: Record<string, string>) =>
  buildLink(`${RoutePath.teachingResources}/${vanityURL}/preview`, queryParams);

export const getResourceEditUrl = (vanityURL: string) =>
  buildLink(ResourceBuilderPath.edit, {
    [QueryParam.resourceEditID]: vanityURL,
  });

export const getSearchUrl = (queryParams?: SearchParams) => buildLink(RoutePath.search, queryParams);

export const getCourseSearchCanonicalUrl = () => buildLink(`${RoutePath.search}/${SearchType.professionalLearning}`);

export const getToolkitsSearchCanonicalUrl = () =>
  buildLink(`${RoutePath.search}/${SearchType.professionalLearning}/${SearchType.toolkits}`);

export const getSelfPacedCoursesSearchCanonicalUrl = () =>
  buildLink(`${RoutePath.search}/${SearchType.professionalLearning}/${SearchType.selfPacedCourses}`);

export const getResourceSearchCanonicalUrl = () => buildLink(`${RoutePath.search}/${SearchType.teachingResources}`);

export const getHigherEdResourcesSearchCanonicalUrl = () =>
  buildLink(`${RoutePath.search}/${SearchType.teachingResources}/${SearchFilterAlias.higherEd}`);

export const getDiscussionSearchCanonicalUrl = () => buildLink(`${RoutePath.search}/${SearchType.discussions}`);

export const getEntitySearchUrl = (entityType: SearchEntityType, queryParams: SearchParams = {}) =>
  getSearchUrl({
    [SearchKey.type]: SEARCH_ENTITY_2_TYPE.get(entityType || SearchEntityType.resources),
    ...queryParams,
  });

export const getSearchByProductUrl = (product: ProductSchema, entityType?: SearchEntityType) =>
  getEntitySearchUrl(entityType, {
    [SearchKey.product]: product.urlLabel,
  });

export const getSearchBySubjectUrl = (subject: SubjectSchema, entityType?: SearchEntityType) =>
  getEntitySearchUrl(entityType, {
    [SearchKey.subject]: subject.urlLabel,
  });

export const getSearchByAcademicLevelUrl = (academicLevel: AcademicLevelSchema, entityType?: SearchEntityType) =>
  getEntitySearchUrl(entityType, {
    [SearchKey.ageLevel]: academicLevel.urlLabel,
  });

export const getSearchByTagUrl = (tag: string, entityType?: SearchEntityType) =>
  getEntitySearchUrl(entityType, {
    [SearchKey.query]: tag,
  });

export const getCourseURL = (course: { vanityURL: string; courseType: CourseTypeSchema }) => {
  const courseTypeRoutePath = {
    [CourseTypeID.selfPaced]: CourseTypeRoutePath.selfPacedCourse,
    [CourseTypeID.instructorLed]: CourseTypeRoutePath.instructorLedCourse,
    [CourseTypeID.toolkit]: CourseTypeRoutePath.toolkit,
    [CourseTypeID.nonSearchableInstructorLed]: CourseTypeRoutePath.nonSearchableInstructorLed,
  }[course.courseType?.id];
  const src = getNavSource();
  return buildLink(`${RoutePath.professionalLearning}/${courseTypeRoutePath}/${course.vanityURL}`, {
    ...(src.length > 0 && { src }),
  });
};

export const getCommunityUrl = () => buildLink(RoutePath.community);

export const getDiscussionUrl = (vanityURL: string) => buildLink(`${RoutePath.discussion}/${vanityURL}`);

export const getMemberUrl = (vanityURL: string) => buildLink(`${RoutePath.member}/${vanityURL}`);

export const getMyEdexUrl = () => buildLink(`${RoutePath.dashboard}/`);

export const getHelpCentreUrl = () => buildLink(`${RoutePath.helpCenter}/`);

export const getAccountSettingsUrl = () => buildLink(`${RoutePath.accountSettings}/`);

export const getCCXTemplateURL = () =>
  getBaseURL() + '/search?type=teaching-resources&product=Adobe%20Express&template=includesTemplate';

export const getBTSURL = () => getBaseURL() + RoutePath.bts;
